<script>
  import { onMount } from "svelte";
  import QCard from "./QCard.svelte";
  // import ClusterControl from "./ClusterControl.svelte";
  export let assay;
  export let assay_id;

  let summary = [];
  let columns = [
      // {"id": "description", "value": ["description"]},
      {"id": "wells", "value": "Wells"},
      {"id": "Input Celltype", "value": "Input Celltype"},
      {"id": "Algorithm", "value": "Algorithm"}
     // {"id": "edit", "value": [""], "style": "width:25px;", "click": add_edit_cluster},
  ]
  let table = {
    columns: columns,
    rows: summary
  }

  function open_cluster(row){
    var url = "/workflow/"+assay_id+"/gating/cluster/create"
    if(row && row.hasOwnProperty("spec_id")){
      url += "/"+row.spec_id;
    }
    window.location.href = url;
  }

  onMount(async function() {
    const response = await fetch("/workflow/"+assay_id+"/clusters");
    summary = await response.json();
    table = {
        columns: columns,
        rows: summary
    }
  });

</script>

<div class="summary-page">
  <div class="columns is-desktop is-multiline">
    {#each summary as row}
      <div class="column is-narrow">
        <QCard {columns} title={row.description} data={row}>
          <span class="actions" let:row>
            <span on:click={() => open_cluster(row)}>
              <i class="fas fa-edit">
            </span>
          </span>
        </QCard>
      </div>
    {/each}
    <div class="column is-narrow">
      <QCard on:click={open_cluster} new_card={true} />
    </div>
  </div>
</div>


<style>
  .cluster-modal{
    height: 800px;
    width: 1100px;
    background: white;
  }
  .summary-page{
    margin-left: 40px;
    margin-right: 40px;
  }
  .title {
      margin-bottom: 20px;
  }

  .actions{
    width: 100%;
    margin-right: 5px;
    float: right;
    text-align: right;
  }

  .actions i{
    cursor: pointer;
    padding: 0.25em;
  }
</style>
