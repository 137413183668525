<script>
  import AJAXFetch from "./AJAXFetch.svelte";
  import InputField from "./InputField.svelte";
  import Modal from "./Modal.svelte";
  import QPlot from "./QPlot.svelte";
  import QTable from "./QTable.svelte";
  import SelectField from "./SelectField.svelte";

  export let qcrun_id;
  let plots_url;
  let plots_data = {"plot": {}, "table": {}};
  let plots;
  let plot;
  let tables;
  let table_data = {
    "columns": [],
    "rows": []
  }
  let selected_detector;
  $: if(selected_detector) {
    plot = plots[selected_detector];
    table_data = tables[selected_detector]
  }

  $: plots_url = "/lab-ops/instrument-qc/get-regression-plots/" + qcrun_id;
</script>

<style>
  .container {
    background: white;
    display: flex;
    flex-direction: column;
  }

  .plot-container{
    width: 95%;
    border: 1px solid grey;
    margin-left: 1em;
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .horizontal{
    display: flex;
    align-items: center;
    margin-left: 0.75em;
  }

  .qplot {
    height: 400px;
    width: 600px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

</style>

<div class="container">
  <div style="margin-left: 1em; margin-top: 1em; font-weight: 600">Regression Plots for QCRun</div>
  {#if qcrun_id}
    <AJAXFetch url={plots_url} method="GET" on:fetched={(e) => {plots = e.detail.plot; tables = e.detail.table; selected_detector=Object.keys(plots)[0]}}>
      <span slot="success">
        <div class="plot-container">
          <div class="horizontal">
            <SelectField label="Detector:" opts={Object.keys(plots)} bind:value={selected_detector} />
          </div>
          <div class="horizontal">
          {#if plot}
            <div class="qplot">
              <QPlot
                bind:data={plot.points}
                xDomain={plot.xDomain}
                yDomain={plot.yDomain}
                xticks={plot.xticks}
                yticks={plot.yticks}
                xlabel={plot.xlabel}
                ylabel={plot.ylabel}
                annotations={plot.annotations}
                xformatTick={(d)=>{return plot.xticklabels[plot.xticks.indexOf(d)]}}
                yformatTick={(d)=>{return plot.yticklabels[plot.yticks.indexOf(d)]}}
                lines={plot.lines}
                xgridlines={true}
                ygridlines={true}
                padding={{ top: 0, right: 0, bottom: 40, left: 25 }}
                plot_type="svg"
                />
            </div>
          {/if}
            <div class="table-container" style="width:550px;height:400px;overflow:scroll;">
              <QTable
                columns={table_data.columns}
                rows={table_data.rows}
              />
            </div>
          </div>
        </div>
      </span>
    </AJAXFetch>
  {/if}
</div>
