<script>
  import { LayerCake, Svg, Html } from 'layercake';

  import PolygonGate from "./PolygonGate.svelte";
  import RangeGate from "./RangeGate.svelte";
  import RectGate from "./RectGate.svelte";
  import EllipseGate from "./EllipseGate.svelte";
  import QuadGate from "./QuadGate.svelte";
  import RectQuadGate from "./RectQuadGate.svelte";
  import GateLabel from "./GateLabel.svelte";
  import ScatterSVG from "./Scatter.svg.svelte";
  import Zoom from "./Zoom.svelte";
  import Controls from "./Controls.svelte";

  export let plot;

  export let x = "x";
  export let y = "y";
  export let width = 400;
  export let height = 400;
  // let data = [];
  let data = [{x:0, y:0}, {x:1, y:0}, {x:1, y:1}, {x:0, y:1}];
  // export let xDomain = null;
  // export let yDomain = null;
  export let padding = { top: 0, right: 0, bottom: height * 0.1, left: width * 0.1 };

  let control_action = null;



</script>
<div class="axis-label">{plot.inputcelltype.name}</div>
<div style="height:{height}px;width:{width}px;" class="plot-container">
  <div class="image">
    <img src="data:image/png;base64, {plot.image}" />
  </div>
  <div class="gating-container">
    <div class="chart-container">
      <LayerCake {padding} {x} {y} bind:data xDomain={plot.xDomain} yDomain={plot.yDomain}>
        <Svg>
          <g>
            <clipPath id="gating_clip_{plot.id}">
              <rect x="0" y="0" width="{width}" height="{height-padding.bottom}"></rect>
            </clipPath>
            <g clip-path="url(#gating_clip_{plot.id})">  
              <Controls bind:control_action on:reset />
              {#each plot.gates as gate}
                {#if gate.type == "Polygon"}
                  <PolygonGate bind:active={gate.active} bind:selected={gate.selected} bind:gate={gate.points} />
                {:else if gate.type == "Range"}
                  <RangeGate bind:active={gate.active} bind:selected={gate.selected} bind:gate={gate.points} />
                {:else if gate.type == "Rectangle"}
                  <RectGate bind:active={gate.active} bind:selected={gate.selected} bind:gate={gate.points} />
                {:else if gate.type == "Ellipse"}
                  <EllipseGate bind:active={gate.active} bind:rotation={gate.rotation} bind:selected={gate.selected} bind:gate={gate.points} />
                {:else if gate.type == "Quad"}
                  <QuadGate bind:active={gate.active} bind:selected={gate.selected} bind:gate={gate.points} />
                {:else if gate.type == "RectQuad"}
                  <RectQuadGate bind:active={gate.active} bind:selected={gate.selected} bind:gate={gate.points} />
                {/if}
                {#each gate.region as region}
                  <GateLabel bind:active={gate.active} bind:selected={gate.selected} bind:region={region} />
                {/each}
              {/each}
              {#if control_action == 'zoom'}
                <Zoom bind:control_action on:zoom-end />
              {/if}
            </g>
          </g>
        </Svg>
      </LayerCake>
    </div>
  </div>
</div>
{#if plot.yaxis}
  <div class="axis-label rotate">{plot.yaxis.name}</div>
{/if}
<div class="axis-label">{plot.xaxis.name}</div>

<style>
  .gating-container,
  .chart-container{
    width: 100%;
    height: 100%;
  }

  .plot-container{
    position: relative;
  }

  .gating-container{
    position: absolute;
    top: 0;
  }

  .axis-label{
    text-align: center;
    color: black;
  }

  .rotate{
    transform: rotate(-90deg);
    position: absolute;
    top:  250px;
    left:  0;
  }

  img {
     user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
  }
</style>
