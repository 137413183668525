<script>
  export let image;
  export let title = "";
  export let data = [];
  export let columns = [];
  export let new_card = false;
  export let subdued = false; // Set to true to display a subdued version of the card

  const SLOTS = $$props.$$slots;
  let show_footer = SLOTS;
  $: show_content = title != "" || columns.length > 0;
</script>

{#if new_card}
  <div class="card">
    <div class="card-content">
      <div class="content">
        <div on:click class="new-card">
          <i class="fas fa-plus" />
        </div>
      </div>
    </div>
  </div>
{:else}
  <div class="card" class:subdued>
    {#if image}
      <div on:click class="card-image">
        <figure class="image is-4by4">
          <img src="data:image/png;base64, {image}" alt="Placeholder image">
        </figure>
      </div>
    {/if}
    {#if show_content}
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4">{title}</p>
          </div>
        </div>
        {#if columns.length}
          <div class="content">
            {#each columns as c}
              <div class="col-group">
                <div class="col-label">{c.value}</div>
                <div class="col-content">{data[c.id]}</div>
              </div>
            {/each}
          </div>
        {/if}
      </div>
    {/if}
    {#if show_footer}
      <footer class="card-footer">
        <slot></slot>
      </footer>
    {/if}
  </div>
{/if}


<style>
  .card{
    min-width: 400px;
    max-width: 400px;
    min-height: 100%;
  }

  .col-group{
    display: flex;
    justify-content: space-between;
  }

  .col-label{
    font-weight: bold;
  }

  .col-content{
    text-align: right;
    float: right;
  }

  .new-card{
    cursor: pointer;
    min-height: 5em;
  }

  .new-card i{
    text-align: center;
    margin: auto;
    font-size: 5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card-footer {
    height: 2em;
    align-items: center;
  }

  .subdued {
    box-shadow: 0 0.1em 0.1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)
  }

</style>
