<script>
  // Import the getContext function from svelte
  import { getContext, onMount, createEventDispatcher } from 'svelte';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { data, xGet, yGet, xScale, yScale, padding } = getContext('LayerCake');

  export let region;
  // Customizable defaults
  let g;
  let dispatch = createEventDispatcher();
  let fill;
  export let active = false;
  export let selected = false;
  let live = false;


  function oMousePosSVG(e) {
    let svg = g.closest("svg");
    var p = svg.createSVGPoint();
    p.x = e.x;
    p.y = e.y;
    var ctm = svg.getScreenCTM().inverse();
    var p =  p.matrixTransform(ctm);
    return p;
  }

  function move_region_name(e){
    if(live){
      var p = oMousePosSVG({x: e.clientX-$padding.left, y: e.clientY-$padding.top});
      region.position[0] = $xScale.invert(p.x);
      region.position[1] = $yScale.invert(p.y);
      region = region;
    }
  }


  function mousedown(e){
    if(e.target.nodeName === "text"){
      live = true;
    }
  }

  $: fill = active || selected? "#b15928": "#ff7f00";

  onMount(()=>{
    let svg = g.closest("svg");
    // svg.addEventListener("mousedown", start_draw);
    svg.addEventListener("mousemove", move_region_name);
    svg.addEventListener("mouseup", e=>{live=false;});
  })

</script>

<g on:mousedown={mousedown} on:mouseover={()=>active=true} on:mouseout={()=>active=false} bind:this={g}>
  <text stroke={fill} x={$xScale(region.position[0])} y={$yScale(region.position[1])}>{region.display_name}</text>
</g>

<style>
  .gate{
    fill: transparent;
    stroke-width: 5
  }

  text {
     user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
  }

</style>
