<script>
  import { onMount } from "svelte";

  export let height = "46px";
  export let url_path_start = 3;
  export let num_crumbs = 3;  // Not including the leading assay crumb

  let breadcrumbs_list = [];
  onMount(async ()=>{
    let crumbs = window.location.pathname.split("/").splice(url_path_start, num_crumbs);
    const assay_name = await fetch('/api/assays?id=' + assay_id).then(resp => resp.json()).then(data => data[0].name);
    breadcrumbs_list = [assay_name].concat(crumbs); 
  })
</script>

<div class="qog_banner_toolbar webix_toolbar webix_layout_toolbar" style="height: {height};">
  <div class="webix_list-x crumbs">
    {#each breadcrumbs_list as crumb, i}
      <div class="webix_list_item"><a href='{window.location.pathname.split("/").splice(0, i+url_path_start).join("/")}'>{crumb}</a></div>
    {/each}
  </div>
</div>

<style>
</style>
