<script>
  // Import the getContext function from svelte
  import { getContext, onMount, createEventDispatcher } from 'svelte';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { data, xGet, yGet, xScale, yScale } = getContext('LayerCake');
  // Customizable defaults

  export let labels;
  export let size = 6;
  let diameter = size*2;
  // export let background = "white";
  // export let stroke = "black";
  // export let width = 100;
  // export let height = 200;
  export let position = {
    x: 0,
    y: 0
  }
  let g;
  let fill = [0, 0, 0, 1];
  let dispatch = createEventDispatcher();


  function componentToHex(c) {
    c = c*255;
    var hex = c.toString(16).split(".")[0];
    return hex.length == 1 ? "0" + hex : hex;
  }


  function get_fill(d){
    var fill = d.hasOwnProperty("fill")? d.fill: fill;
    if(typeof fill === "string") return fill;
    return "#" + componentToHex(d.fill[0]) + componentToHex(d.fill[1]) + componentToHex(d.fill[2]) + (d.hasOwnProperty("opacity")? componentToHex(d.opacity): "FF");
  }



  function get_points(d, i, axis){
    var index = 2*i+1;
    if(d.type === 'v'){
      return [
        [diameter/2, (index-0.5)*diameter].join(","),
        [1.5*diameter, (index-0.5)*diameter].join(","),
        [diameter, (index+0.5)*diameter].join(",")
      ].join(" ");
    }
    else if(d.type === '^'){
      return [
        [diameter/2, (index+0.5)*diameter].join(","),
        [1.5*diameter, (index+0.5)*diameter].join(","),
        [diameter, (index-0.5)*diameter].join(",")
      ].join(" ");
    }
    else if(d.type === 's' || d.type === 'D' || d.type === 'd'){
      if(axis === 'x'){
        return diameter/2;
      }
      else{
        return (index-0.5)*diameter;
      }
    }
    else if(d.type === '+' || d.type === 'X' || d.type === 'x'){
      return [
        [diameter, index*diameter].join(","),
        [diameter/2, index*diameter].join(","),
        [1.5*diameter, index*diameter].join(","),
        [diameter, index*diameter].join(","),
        [diameter, (index-0.5)*diameter].join(","),
        [diameter, (index+0.5)*diameter].join(",")
      ].join(" ");
    }
    else{
      if(axis === 'x'){
        return diameter;
      }
      else{
        return index*diameter;
      }
    }
  }


</script>

<g bind:this={g}>
  <g transform="translate({position.x}, {position.y})">
    {#each labels as d, i}
      {#if !d.hasOwnProperty("type") || d.type == 'o'}
        <circle cx='{ get_points(d, i, "x") }' cy='{ get_points(d, i, "y") }' fill={get_fill(d)} r={diameter/2} />
      {:else if d.type == 'v'}
        <polygon points='{ get_points(d, i) }'  fill={get_fill(d)} />
      {:else if d.type == '^'}
        <polygon points='{ get_points(d, i) }'  fill={get_fill(d)} />
      {:else if d.type == 's'}
        <rect x='{ get_points(d, i, "x") }' y='{ get_points(d, i, "y") }' fill={get_fill(d)} width={diameter} height={diameter} />
      {:else if d.type == 'D' || d.type == 'd'}
        <rect x='{ get_points(d, i, "x") }' y='{ get_points(d, i, "y") }' fill={get_fill(d)} width={diameter} height={diameter} transform="rotate(45,{  get_points({}, i, 'x') }, {  get_points({}, i, 'y') })" />
      {:else if d.type == '+'}
        <polygon stroke-width='{diameter/2}' stroke={get_fill(d)} points='{ get_points(d, i) }'  fill={get_fill(d)} />
      {:else if d.type == 'X' || d.type == 'x'}
        <polygon transform="rotate(45,{ get_points({}, i, 'x') }, { get_points({}, i, 'y') })" stroke-width='{diameter/2}' stroke={get_fill(d)} points='{ get_points(d, i) }'  fill={get_fill(d)} />
      {/if}
      <text x={get_points({}, i, "x")+diameter} y={get_points({}, i, "y")} dy="0.4em">{d.label}</text>
    {/each}
  </g>
</g>
<style>
  text{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
</style>
