<script>
  import { getContext, onMount, createEventDispatcher } from 'svelte';
  const { data, xGet, yGet, xScale, yScale } = getContext('LayerCake');
  export let gate = null;
  let rect = null;
  let draw = false;
  let g;

  let dispatch = createEventDispatcher();

  function oMousePosSVG(e) {
    let svg = g.closest("svg");
    var p = svg.createSVGPoint();
    p.x = e.clientX;
    p.y = e.clientY;
    var ctm = svg.getScreenCTM().inverse();
    var p =  p.matrixTransform(ctm);
    return p;
  }

  function start_draw(e){
    if (e.target.nodeName !== "text"){
      var p = oMousePosSVG(e);
      gate = {
        min_x: $xScale.invert(p.x),
        min_y: $yScale.invert(p.y),
        max_x: $xScale.invert(p.x),
        max_y: $yScale.invert(p.y),
        hidden: false
      }
      draw = true;
    }

  }

  function drag(e){
    if(draw){
      var p = oMousePosSVG(e);
      var x = Math.min(gate.min_x, $xScale.invert(p.x));
      var y = Math.min(gate.min_y, $yScale.invert(p.y));
      var max_x = Math.max(gate.max_x, $xScale.invert(p.x));
      var max_y = Math.max(gate.max_y, $yScale.invert(p.y));
      gate = {
        min_x: x,
        min_y: y,
        max_x: max_x,
        max_y: max_y
      }
      dispatch("selection", gate)
    }
    else{
      dispatch("selectionMousemove", [e])
    }
  }

  function end_draw(e){
    if(draw){
      if(gate && gate.min_x == gate.max_x && gate.min_y == gate.max_y){
        gate = null;
      }
      draw = false;
      // dispatch("selection", gate)

    }
    dispatch("endSelection", gate)
  }


  onMount(()=>{
    let svg = g.closest("svg");
    svg.addEventListener("mousedown", start_draw);
    svg.addEventListener("mousemove", drag);
    svg.addEventListener("mouseup", end_draw);
  })

  $:{
    if(gate && !gate.hidden){
      rect = {
        x: $xScale(gate.min_x),
        y: $yScale(gate.max_y),
        width: $xScale(gate.max_x) - $xScale(gate.min_x),
        height: $yScale(gate.min_y) - $yScale(gate.max_y),
      }
    }
    else{
      rect = null;
    }
  }




</script>
<g bind:this={g}>
  {#if rect}
    <rect class="draw" x='{rect.x}' y='{rect.y}' width='{rect.width}' height='{rect.height}'></rect>
  {/if}
</g>

<style>

  .draw{
    fill: transparent;
    stroke: black;
    stroke-width: 3;
    stroke-dasharray: 3,3;
  }

</style>
