<script>
  export let label;
  export let value;
  export let opts;
  export let display_key;
  export let value_key;
  export let orientation;
  export let labeless;
</script>

<style>
.horizontal-field {
  display: flex;
  flex-direction: row;
}

.horizontal-label {
  text-align: right;
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  margin: auto;
  margin-right: 5px;
}
</style>

<div class="field">
  {#if labeless == "true"}
    <div class="select control">
      <select bind:value>
        {#each opts as opt}
          <option value={value_key? opt[value_key] : opt}>{display_key? opt[display_key] : opt}</option>
        {/each}
      </select>                                                                                                                 
    </div>
  {:else}
    {#if orientation == "horizontal"}
      <div class="horizontal-field">
        <label class="horizontal-label">{label}</label>
        <div class="select control">
          <select bind:value>
            {#each opts as opt}
              <option value={value_key? opt[value_key] : opt}>{display_key? opt[display_key] : opt}</option>
            {/each}
          </select>                                                                                                                 
        </div>
      </div>
    {:else}
      <label class="label">{label}</label>
      <div class="select control">
        <select bind:value>
          {#each opts as opt}
            <option value={value_key? opt[value_key] : opt}>{display_key? opt[display_key] : opt}</option>
          {/each}
        </select>
      </div>
    {/if}
  {/if}
</div>
