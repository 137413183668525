<script>
  import {HsvPicker} from 'svelte-color-picker';
  import CellTypeTreeWithSearch from "./CellTypeTreeWithSearch.svelte";
  import SelectField from "./SelectField.svelte";
  import QTable from "./QTable.svelte";
  import QPlot from "./QPlot.svelte";
  import MenuItem from "./MenuItem.svelte";
  import DropDown from "./DropDown.svelte";
  import Loading from "./Loading.svelte";
  import MultiPlot from "./MultiPlot.svelte";
  import AJAXFetch from "./AJAXFetch.svelte";
  import Accordion from "./Accordion.svelte";
  import Modal from "./Modal.svelte";
  import { onMount } from "svelte";
  import {default as robustPointInPolygon} from "robust-point-in-polygon";
  export let analysis_id;
  export let analysis_name;
  export let analysis_type;
  let error;
  let status_message;
  let loading = false;
  let sample_table;
  let searchable_columns;
  let sortable_columns;
  let dep_variable_options = [];
  let pairing_variable_options = [];
  let dep_var;
  let pairing;
  let grouping_info;
  let grouping_table = {
    columns: [],
    rows: []
  };
  let celltypes_info;
  let celltypes_table = {
    columns: [],
    rows: []
  };
  let paramlabels_info;
  let paramlabels_table = {
    columns: [],
    rows: []
  };
  let stats_plot;
  let metric_options;
  let selected_metric;
  let celltype_table;
  let show_menu = true;
  let plot;
  let annotations;
  let height = 780;
  let width = 650;
  let stats_table;
  let selected_row;
  let active_plots;
  let selected_celltypes = new Set();
  let saved_plots;

  let filtered_rows;
  let table_filters = {};

  let stats_plot_url;
  let stats_plot_post_data;
  let show_plot = true;
  let heatmap;
  let set_up_label = "";
  let stats_table_search_columns = ["label"];
  let color_list;
  let edit_color_table = {
    columns: [
      {id: "celltype",  value: "Celltype"},
      {id: "color", value: ""}
    ],
    rows: []
  };
  let active_color;
  let current_color;
  let edit_colors = false;
  let params;

  $: sample_table, dep_var, grouping_info, pairing, selected_metric, celltypes_info, paramlabels_info, update_settings_label();


  let open_copy = false;
  $: copy_name = analysis_name + "_copy";
  let copy_error = null;
  async function save_copy() {
    console.log("launching");
    var formData = new FormData();
    formData.append("destination_analysis_name", copy_name);
    var response = await fetch("/analysis/clone/" + analysis_type + "/" + analysis_id, {
      method: "POST",
      body: formData
    });
    console.log("launched");
    var json = await response.json();
    console.log("response", json);
    if(json.status === "success") {
      window.location = "/analysis/new-analysis/" + json.analysis_type + "/" + json.analysis_id;
    } else {
      copy_error = json.message;
    }
  }

  function export_analysis(){
    status_message = "Download started. May take a few minutes.";
    window.location.href = "/analysis/umap-export/"+analysis_type+"/"+analysis_id;
    setTimeout(()=>{
      status_message = null;
    }, 5000);
  }

  function update_settings_label(){
    if(sample_table && grouping_table ){
      var label = "Samples: " + sample_table.rows.length +
                  " | Dep. Var: " + dep_var + " | Pairing: " + (pairing? pairing: "None") +
                  " | Grouping: " + grouping_table.rows.filter(r=>r.selected).length +
                  " | Metric: " + selected_metric;
     if(params){
       label +=  " | Celltypes: " + params.celltypes[selected_metric].default.length;
       if(params.paramlabels[selected_metric].options.length > 0){
         label += " | Parameters: " + params.paramlabels[selected_metric].default.length;
       }
     }


     label = label.length > 400? label.slice(0, 200) + "..." + label.slice(label.length-200): label
     set_up_label = label;
     plot = null;
     stats_table = null;
     active_plots = null;
     celltype_table = null;
    }

  }

  function set_active_row(e){
    selected_points = new Set();
    active_plots = null;
    selected_row = e.detail[0];
    var formData = new FormData();
    formData.append("well_ids", filtered_rows.map(r=>r.well_id).join(","))
    formData.append("dependent_variable", dep_var)
    formData.append("pairing", pairing)
    formData.append("grouping", grouping_table.rows.filter(r=>r.selected).map(r=>r.value).join(","))
    formData.append("metric", selected_metric)
    formData.append("stats_row", JSON.stringify(selected_row))
    var celltypenameids = {};
    var celltypename_keys = Object.keys(params.celltypes);
    for (var i = 0; i < celltypename_keys.length; i++){
      celltypenameids[celltypename_keys[i]] = params.celltypes[celltypename_keys[i]].default;
    }
    var paramlabelids = {};
    var paramlabel_keys = Object.keys(params.paramlabels);
    for (var i = 0; i < paramlabel_keys.length; i++){
      paramlabelids[paramlabel_keys[i]] = params.paramlabels[paramlabel_keys[i]].default;
    }
    formData.append("celltypes", JSON.stringify(celltypenameids))
    formData.append("paramlabels", JSON.stringify(paramlabelids))
    stats_plot_post_data = formData
    stats_plot_url = "/analysis/param-vs-depvar-plot/"+analysis_id;
    // var response = await fetch("/analysis/param-vs-depvar-plot/"+analysis_id,{
    //   method: "POST",
    //   body: formData
    // });
    // const json = await response.json();
    // var data = json;
    // stats_plot = data;
  }

  function set_heatmap_row(e){
    if(["label", "parameter"].indexOf(e.detail[1].id)  === -1){
      set_active_row({
        detail: [stats_table.rows.find((r)=>{
          return r.label === e.detail[0].label.title &&
                 r.parameter === e.detail[0].parameter.title;
        })]
      })
    }
  }

  function set_volcano_row(e){
    set_active_row({
      detail: [stats_table.rows.find((r)=>{
        return r.row_index === e.detail[0].row_index;
      })]
    })
  }


  function update_grouping_info(){
    grouping_info = grouping_table.rows.filter(r => r.selected).map(r => r.value).slice(0,2).join(", ") +
      (grouping_table.rows.filter(r => r.selected).length > 2? "...": "");
  }

  function update_celltypes_info(){
    celltypes_info = celltypes_table.rows.filter(r => r.selected).map(r => r.value).slice(0,2).join(", ") +
      (celltypes_table.rows.filter(r => r.selected).length > 2? "...": "");
    params.celltypes[selected_metric].default = celltypes_table.rows.filter(r => r.selected).map(r=>r.id);
  }

  function update_paramlabels_info(){
    paramlabels_info = paramlabels_table.rows.filter(r => r.selected).map(r => r.value).slice(0,2).join(", ") +
      (paramlabels_table.rows.filter(r => r.selected).length > 2? "...": "");
    params.paramlabels[selected_metric].default = paramlabels_table.rows.filter(r => r.selected).map(r=>r.id);
  }


  async function run_analysis(){
    if(celltypes_table.rows.filter(r=>r.selected).length == 0){
      error = "Must select at least one celltype.";
      setTimeout(()=>{
        error = null;
      }, 3000);
      return ;
    }
    selected_celltypes = new Set();
    var formData = new FormData();
    formData.append("well_ids", filtered_rows.map(r=>r.well_id).join(","))
    formData.append("dependent_variable", dep_var)
    formData.append("pairing", pairing)
    formData.append("grouping", grouping_table.rows.filter(r=>r.selected).map(r=>r.value).join(","))
    formData.append("metric", selected_metric)
    formData.append("filters", JSON.stringify(table_filters));
    var celltypenameids = {};
    var celltypename_keys = Object.keys(params.celltypes);
    for (var i = 0; i < celltypename_keys.length; i++){
      celltypenameids[celltypename_keys[i]] = params.celltypes[celltypename_keys[i]].default;
    }
    var paramlabelids = {};
    var paramlabel_keys = Object.keys(params.paramlabels);
    for (var i = 0; i < paramlabel_keys.length; i++){
      paramlabelids[paramlabel_keys[i]] = params.paramlabels[paramlabel_keys[i]].default;
    }
    formData.append("celltypes", JSON.stringify(celltypenameids))
    formData.append("paramlabels", JSON.stringify(paramlabelids))
    stats_plot_url = null;
    stats_plot_post_data = null;
    loading = true
    stats_plot = null;
    selected_row = null;
    plot = null;
    celltype_table = null;
    stats_table = null;
    var response = await fetch("/analysis/crosssample-plot-stats/"+analysis_id,{
      method: "POST",
      body: formData
    });
    const json = await response.json();
    var data = json;
    data.plot.points = data.plot.points.map((p)=>{
      p.orignal_r = p.r;
      return p;
    })
    plot = data.plot;
    celltype_table = data.celltype_table;
    data.stats_table.rows = data.stats_table.rows.map((r, i)=>{
      r.id = i+1;
      return r;
    })
    heatmap = data.heatmap;
    stats_table = data.stats_table;
    loading = false;
  }

  async function get_sample_table(){
    loading = true;
    var response = await fetch("/analysis/csanalysis-sample-table/"+analysis_id);
    const json = await response.json();
    var data = json;
    sample_table = data;
    searchable_columns = sample_table.columns.map((c)=>{
      var filter_val = "";
      if(sample_table.filters.hasOwnProperty(c.id)){
        filter_val = sample_table.filters[c.id];
      }
      return [c.id, filter_val];
    });
    sortable_columns = sample_table.columns.map((c)=>{
      return c.id;
    });
    // searchable_columns = sample_table.columns.map((c)=>{
    //   return c.id;
    // });
    filtered_rows = sample_table.rows;
    loading = false;
    get_analysis_params();
  }


  async function get_analysis_params(){
    var response = await fetch("/analysis/"+analysis_id+"/csanalysis-params");
    const json = await response.json();
    var data = json;
    dep_variable_options = data.dependent_variable.options;
    dep_var = data.dependent_variable.default;
    pairing_variable_options = data.pairing.options;
    pairing = data.pairing.default;
    data.grouping_variables.options = data.grouping_variables.options.filter((o)=>{
      return o != "";
    })
    grouping_table = {
      columns: [{id: "value", value: "Value"}],
      rows: data.grouping_variables.options.map((o)=>{
        return {value: o, selected: data.grouping_variables.default.lastIndexOf(o) != -1};
      })
    }

    metric_options = data.metric.options;
    selected_metric = data.metric.default;
    color_list = data.colors;
    celltypes_table = {
      columns: [{id: "value", value: "Value"}],
      rows: data.celltypes[selected_metric].options.map((o)=>{
        o.selected = data.celltypes[selected_metric].default.indexOf(o.id) != -1;
        return o;
      })
    }
    paramlabels_table = {
      columns: [{id: "value", value: "Value"}],
      rows: data.paramlabels[selected_metric].options.map((o)=>{
        o.selected = data.paramlabels[selected_metric].default.indexOf(o.id) != -1;
        return o;
      })
    }
    params = data;
    update_celltypes_info();
    update_paramlabels_info();
    update_settings_label();
  }

  function celltype_toggle(e){
    var toggle_celltype = e.detail[0].celltype.text;
    if(selected_celltypes.has(toggle_celltype)) {
      selected_celltypes.delete(toggle_celltype);
    }
    else {
      selected_celltypes.add(toggle_celltype);
    }
    // show_menu = selected_celltypes.length > 0;
    if (plot){
      plot.points = plot.points.map((p)=>{
        if(selected_celltypes.size === 0){
          p.r = p.orignal_r;
          p.opacity = 1.0;
        }
        else if (selected_celltypes.has(p.celltype)){
          p.r = 1.5 * p.orignal_r;
          p.opacity = 1.0;
        }
        else{
          p.r = p.orignal_r;
          if(selected_celltypes.size){
            p.opacity = 0.3;
          }
        }
        return p;
      });
      plot = plot;
    }
    if (stats_table){
      stats_table.rows.sort((a,b)=>{
        var has_a = selected_celltypes.has(a.celltype);
        var has_b = selected_celltypes.has(b.celltype);
        if(has_a && has_b) return 0;
        if(has_a) return -1;
        if(has_b) return 1;
        return 0;
      });
      stats_table.rows = stats_table.rows;
    }


  }

  let selected_points = new Set();

  async function get_single_cell_plots(e){
    if(selected_points.has(e.detail[0])){
      selected_points.delete(e.detail[0]);
    }
    else{
      selected_points.add(e.detail[0])
    }
    stats_plot.points = stats_plot.points.map((p)=>{
      if(selected_points.has(p)){
        p.r = 8;
      }
      else{
        p.r = 4;
      }
      return p;
    });
    if (selected_points.size > 0){
      var formData = new FormData();
      formData.append("selected_points", JSON.stringify(Array.from(selected_points)));
      var response = await fetch("/analysis/"+analysis_id+"/single-cell-plots", {
        method: "POST",
        body: formData
      });
      const json = await response.json();
      active_plots = json;
    }
    else{
      active_plots = null;
    }

  }

  function get_colors(){
    return color_list.reduce((acc, v)=>{
      acc[v.id] = [v.celltype, v.value];
      return acc;
    }, {});
  }

  function open_color_picker(){
    var color_picker_dict = get_colors();
    edit_color_table.rows = Object.keys(color_picker_dict).map((k)=>{
      return {id: k, celltype: color_picker_dict[k][0], color: {style: "background-color:"+color_picker_dict[k][1], text: ""}, value:color_picker_dict[k][1]}
    });
    edit_colors = true;
    update_set_all_colors();
  }


  async function save_colors(){
    loading = true;
    edit_colors = false;
    var formData = new FormData();
    var pop2color = edit_color_table.rows.reduce((acc, r) => {
      acc[r.celltype] = r.value;
      return acc;
    }, {});
    formData.append("ctype2color", JSON.stringify(pop2color));
    var response = await fetch("/analysis/"+analysis_id+"/csanalysis-params", {
      method: "POST",
      body: formData
    });
    const json = await response.json();
    run_analysis();
    loading = false;
  }

  function set_edit_color(e){
    if (e){
      current_color = e.detail[0].value;
      active_color = [e.detail[0]];
    }
    else{
      var selected_rows = edit_color_table.rows.filter(r=>r.selected);
      if(selected_rows.length){
        current_color = selected_rows[0].value;
        active_color = selected_rows;
      }

    }

  }

  function perform_color_update(){
    current_color = "#" + ((1 << 24) + (current_color.r << 16) + (current_color.g << 8) + current_color.b).toString(16).slice(1);
    for(var j = 0; j < active_color.length; j++){
      for(var i = 0; i < edit_color_table.rows.length; i++){
        if(edit_color_table.rows[i].celltype == active_color[j].celltype){
          edit_color_table.rows[i].value = current_color;
          edit_color_table.rows[i].color.style = "background:"+current_color;
        }
      }
    }

    edit_color_table = edit_color_table;
    active_color = null;

  }

  let set_all_colors = false;
  let set_all_title = "Select at least two celltypes to set color for all.";

  function update_set_all_colors(){
    set_all_colors = edit_color_table.rows.filter(r=>r.selected).length >= 2;
    set_all_title = set_all_colors? "Set color for all selected rows." : "Select at least two celltypes to set color for all.";
  }


  function update_filter(e){
    filtered_rows = e.detail[0];
    table_filters = e.detail[1];
  }

  function open_help(e, help_type){
    if(help_type){
      var help = document.getElementById(help_type+"-help-text");
    }
    else{
      var help = document.getElementById("help-text");
    }


    help.style.left = e.layerX + 15 +"px";
    help.style.top = e.layerY + 50 +"px";
    help.style.display = "block"
  }

  function hide_help(e){

    var container = window.$(".help-text");
    var help_icon = window.$(".help-info");
    if (!container.is(e.target) && container.has(e.target).length === 0 && !help_icon.is(e.target) && help_icon.has(e.target).length === 0){
      var help_windows = document.getElementsByClassName("help-text");
      for (var i = 0; i < help_windows.length; i++){
        help_windows[i].style.display = "none";
      }
    }
  }

  $: grouping_table.rows, update_grouping_info();

  $: if(params){ celltypes_table.rows = params.celltypes[selected_metric].options.map((o)=>{
    o.selected = params.celltypes[selected_metric].default.indexOf(o.id) != -1;
    return o;
  })};

  $: if(params){ paramlabels_table.rows = params.paramlabels[selected_metric].options.map((o)=>{
    o.selected = params.paramlabels[selected_metric].default.indexOf(o.id) != -1;
    return o;
  })};

  onMount(()=>{
    get_sample_table();
    // get_analysis_params();
  })

</script>

<svelte:window on:click={hide_help} />
<div class="summary-page">
  <div class="qog_banner_toolbar webix_toolbar webix_layout_toolbar" style="height: 46px;">
    <div class="webix_list-x crumbs">
      <div class="webix_list_item"><a href='{window.location.pathname.split("/").splice(0, 2).join("/")}'>Analysis</a></div>
      <div class="webix_list_item"><a href='{window.location.pathname.split("/").splice(0, 2).join("/")+"/analysis-table"}'>Analysis Table</a></div>
    </div>
    <div class="analysis-title">{analysis_name}</div>
    <div title="Copy Analysis" class="dropdown-trigger" style="cursor: pointer">
      <i class="fas fa-copy" on:click={() => {open_copy = true; copy_error=null;}}/>
    </div>
    <div title="Change Celltype Colors" class="dropdown-trigger" style="cursor: pointer">
      <i class="fas fa-paint-brush" on:click={open_color_picker} />
    </div>
    <div title="Export Analysis" class="dropdown-trigger">
      <i class="fa fa-download" on:click={export_analysis} />
    </div>
  </div>
  {#if error}
    <div class="notification is-danger">
      <button on:click={(e)=>{error=null;}} class="delete"></button>
      {error}
    </div>
  {/if}
  {#if status_message}
    <div class="notification is-success">
      <button on:click={(e)=>{status_message=null;}} class="delete"></button>
      {status_message}
    </div>
  {/if}
  <br />
  <div style="width:1550px;">
    <Accordion label={set_up_label}>
      <div class="columns">
        {#if sample_table}
          <div class="column sample-column">
            <div class="scroll-div">
              <QTable
                draggable_rows={true}
                rows={sample_table.rows}
                columns={sample_table.columns}
                searchable_columns={searchable_columns}
                sortable_columns={sortable_columns}
                on:rows-filtered={update_filter}
                />
            </div>
          </div>
        {/if}
      </div>
      {#if dep_variable_options && pairing_variable_options && grouping_table && metric_options}
        <div class="columns options-columns">
          <div class="column">
            <span on:click={(e)=>{open_help(e, "dep-var")}} class="help-info help-define"><i class="fas fa-question-circle"></i></span>
            <div id="dep-var-help-text" class="help-text">
              Biological dimensions (i.e. unique combinations of celltype/antibody-fluor) will be compared to this variable. If the dependent variable is continuous, linear regression will be applied. Otherwise, Wilcoxon Rank-sum (if not paired) or Signed Rank Test (if paired) will be applied.
            </div>
            <SelectField label="Dependent Variable" opts={dep_variable_options} bind:value={dep_var} />
          </div>
          <div class="column">
            <span on:click={(e)=>{open_help(e, "pairing")}} class="help-info help-define"><i class="fas fa-question-circle"></i></span>
            <div id="pairing-help-text" class="help-text">
              Variable that should be used to pair the samples and then study association between biological data. (ex. Donor, batch, etc)
            </div>
            <SelectField label="Pairing" opts={pairing_variable_options} bind:value={pairing} />
          </div>
          <div class="column">
            <div>
              <span on:click={(e)=>{open_help(e, "grouping")}} class="help-info help-define"><i class="fas fa-question-circle"></i></span>
              <div id="grouping-help-text" class="help-text">
                Select one of more variables to defined sample sub-group. Statistical analysis will be conducted against the dependent variable within sample sub-groups defined by the selected variable. For example, if 'gender' is selected as grouping, analysis will be conducted separately withing males and female subsets.
              </div>
              <label class="label">Grouping:</label>
              <DropDown label={grouping_info}>
                <QTable rows={grouping_table.rows} columns={grouping_table.columns} on:selection-changed={update_grouping_info}
                        show_checkboxes={true} />
              </DropDown>
            </div>
          </div>
        </div>
        <div class="columns options-columns">
          <div class="column">
            <SelectField label="Metric" opts={metric_options} bind:value={selected_metric} />
          </div>
          <div class="column">
            <label class="label">Celltypes:</label>
            <DropDown label={celltypes_info}>
              <QTable rows={celltypes_table.rows} columns={celltypes_table.columns} on:selection-changed={update_celltypes_info}
                      show_checkboxes={true} />
            </DropDown>
          </div>
          <div class="column">
            {#if paramlabels_table.rows.length}
              <label class="label">Parameters:</label>
              <DropDown label={paramlabels_info}>
                <QTable rows={paramlabels_table.rows} columns={paramlabels_table.columns} on:selection-changed={update_paramlabels_info}
                        show_checkboxes={true} />
              </DropDown>
            {/if}
          </div>
        </div>
        <div class="columns options-columns">
          <div class="column">
            <div class="run-button">
              <button title="Run" on:click={run_analysis} class="button qog-button">Run</button>
            </div>
          </div>
          <div class="column"></div>
          <div class="column"></div>
        </div>
      {/if}
    </Accordion>
  </div>

  <hr class="hr" />
  <div class="columns second-row">
    {#if celltype_table}
    <div class="column table-column">
      <div class="celltype-container">
          <QTable title={celltype_table.title} columns={celltype_table.columns} rows={celltype_table.rows} on:cell-clicked={celltype_toggle} />
      </div>
    </div>
    {/if}
    {#if plot}
      {#if heatmap}
        <div>
          <i on:click={()=>{show_plot = true;}} class="fas fa-chart-area clickable {show_plot? 'selected': ''}"></i>
          <i on:click={()=>{show_plot = false;}} class="fas fa-table clickable {!show_plot? 'selected': ''}"></i>
        </div>
      {/if}
      <div class="column plot-column large-column">
        {#if show_plot}
        <div>{@html plot.title}</div>
        <div class="plot-with-options">
          <div style="height: {height}px;width:{width}px;" class="chart-container">
            <QPlot
              bind:data={plot.points}
              lines={plot.lines}
              legend={plot.legend}
              annotations={plot.annotations}
              xticks={plot.xticks}
              yticks={plot.yticks}
              xDomain={plot.xDomain}
              yDomain={plot.yDomain}
              xlabel={plot.xlabel}
              ylabel={plot.ylabel}
              xgridlines={true}
              ygridlines={true}
              xbaseline={true}
              ybaseline={true}
              plot_type="svg"
              on:click={set_volcano_row}
              padding={{ top: 0, right: 0, bottom: 40, left: 25 }}
              />
          </div>
        </div>
        {:else}
          <div style="overflow:auto; height:100%">
            <QTable title={heatmap.title} draggable_rows={true} on:cell-clicked={set_heatmap_row} rotate_header_angle="180deg" sortable_columns={heatmap.columns.map(c=>c.id)} rows={heatmap.rows} columns={heatmap.columns} />
          </div>
        {/if}
      </div>
    {/if}
    {#if stats_table}
    <div class="column stats-column">
        <div style="overflow:auto; height:50%">
          <QTable searchable_columns={stats_table_search_columns} title={stats_table.title} rows={stats_table.rows} columns={stats_table.columns} highlight_row={selected_row} on:cell-clicked={set_active_row} />
        </div>
        <div style="height:50%;margin-top:20px;">
          {#if stats_plot_url && stats_plot_post_data}
            <AJAXFetch url={stats_plot_url} post_data={stats_plot_post_data} method="POST" on:fetched={(e) => stats_plot=e.detail}>
              <span slot="success">
                  <div>{@html stats_plot.title}</div>
                  <div style="height:380px;width:450px;">
                    <QPlot
                      bind:data={stats_plot.points}
                      lines={stats_plot.lines}
                      annotations={stats_plot.annotations}
                      xticks={stats_plot.xticks}
                      yticks={stats_plot.yticks}
                      xlabel={stats_plot.xlabel}
                      ylabel={stats_plot.ylabel}
                      xgridlines={true}
                      ygridlines={true}
                      xDomain={stats_plot.xDomain}
                      yDomain={stats_plot.yDomain}
                      xformatTick={d=>stats_plot.xticklabels[stats_plot.xticks.indexOf(d)]}
                      yformatTick={d=>stats_plot.yticklabels[stats_plot.yticks.indexOf(d)]}
                      plot_type="svg"
                      on:click={get_single_cell_plots}
                      padding={{ top: 0, right: 0, bottom: 40, left: 25 }}
                      />
                  </div>
              </span>
            </AJAXFetch>
          {/if}
        </div>
    </div>
    {/if}
  </div>
  <hr class="hr" />
  <div class="stats-plot-container">
    {#if active_plots}
      <div class="columns is-multiline">
      {#each active_plots as p}
        <div class="column is-narrow">
          <div class="image">
            <img src="data:image/png;base64, {p.image}" />
          </div>
        </div>
      {/each}
      </div>
    {/if}
  </div>
  {#if saved_plots}
    <hr class="hr" />
    {#each saved_plots as p}
      <div class="subtitle is-6">p.description</div>
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <div class="image">
            <img src="data:image/png;base64, {p.image}" />
          </div>
        </div>
      </div>
    {/each}
  {/if}
</div>

{#if open_copy}
  <Modal show_modal={open_copy} bind:error_message={copy_error}>
    <span>Clone current analysis to</span>
    <div>
      <input class="input" type="text" bind:value={copy_name}/>
    </div>
    <br />
    <div>
      <button class="button qog-button" on:click={save_copy}>Clone</button>
    </div>
  </Modal>
{/if}

{#if edit_colors}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <button on:click="{()=>edit_colors=false}" class="modal-close is-large" aria-label="close"></button>
    <span><button on:click={()=>{set_edit_color()}} title={set_all_title}  disabled={!set_all_colors} class="button qog-button">Set For All Selected</button></span>
    <div style="width:800px;" class="modal-content">
      <div style="overflow:auto; max-height:800px;">
        <QTable widths={{'celltype': '750px', 'color': '50px'}} on:selection-changed={update_set_all_colors} show_checkboxes={true} rows={edit_color_table.rows} columns={edit_color_table.columns} on:cell-clicked={set_edit_color} />
      </div>
      <span><button on:click={save_colors} class="button qog-button">Save</button></span>
    </div>
  </div>
{/if}

{#if active_color}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="color-modal modal-content">
      <div class="title is-4">Setting Color for {active_color.length > 1? active_color.length + " celltypes": active_color[0].celltype}</div>
      <button on:click="{()=>{active_color=null}}" class="modal-close is-large" aria-label="close"></button>
      <HsvPicker on:colorChange={rgba=>{current_color=rgba.detail;}} startColor={current_color}/>
      <br />
      <button on:click={perform_color_update} class="button qog-button">Update</button>
    </div>
  </div>
{/if}


{#if loading}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <Loading></Loading>
    </div>
  </div>
{/if}


<style>
  .image{
    height: 400px;
    width: 400px;
  }

  .summary-page{
    margin-left: 40px;
    margin-right: 40px;
    width: 100%;
  }

  .export-options{
    flex: 1 0 auto;
    display: flex;
  }

  .export-table{
    width: 600px;
    overflow: scroll;
  }


  .plot-with-options{
    flex: 1 0 auto;
    display: flex;
  }

  .analysis-title {
    size: 1.5rem;
    margin-left:10em;
    margin-right: 10em;
    font-weight: 600;
  }

  .title-container{
    margin-top: 20px;
    margin-right: 200px;
    flex: 1 0 auto;
    display: flex;
  }

  .celltype-container{
    margin-right: auto;
    padding-left: 15px;
    /* height: 22em; */
    max-height: 750px;
    overflow: auto;
  }

  .color-by-div {
    height: 350px;
    max-height: 350px;
    overflow: auto;
  }

  .plot-column{
    width: 650px;
    max-width: 650px;
    min-width: 650px;
    height: 780px;
    max-height: 780px;
    min-height: 780px;
    padding-right: 5px;
    border-right: solid whitesmoke;
  }

  .table-column{
    width: 400px;
    max-width: 400px;
    min-width: 400px;
    overflow: auto;
    padding-right: 5px;
    border-right: solid whitesmoke;
  }

  .stats-column{
    width: 500px;
    max-width: 500px;
    min-width: 500px;
    padding-left: 20px;
    font-size: 0.75em;
  }

  .second-row{
    height: 800px;
    min-height: 800px;
    max-height: 800px;
  }

  .scroll-div{
    overflow: scroll;
    max-height: 650px;
  }



  .dropdown-content{
    padding: 10px;
  }

  .sample-column{
    max-width: 1550px;
    padding-right: 96px;
  }


  .threshold-modal{
    /* width: 1300px; */
    width: fit-content;
  }

  .color-modal > .title{
    color: white;
  }

  .options-columns .column{
    max-width: 480px;
  }

  .run-button{
    margin-top: 32px;
  }

  .stats-plot-container{
    max-width: 1550px;
  }

  .selected{
    color: var(--ryvett-light-grey-blue-highlighter);
  }

  .dropdown-trigger {
    margin-top: 0.5em;
    margin-left: 0.75em;
  }

  .qog_banner_toolbar {
    height: 46px;
    display: flex;
  }

  .analysis-title,
  .dropdown-trigger{
    color: var(--ryvett-submenu-color);
  }

  .help-info{
    float: right;
    cursor: pointer;
  }

  .help-define{
    float: left;
  }

  .help-text{
    position: absolute;
    display: none;
    z-index: 9999;
    border: 1px solid black;
    width: 350px;
    background-color: white;
    padding: 5px;
  }

</style>
