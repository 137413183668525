    <script>
    export let opts;
    export let value;
    export let label;
  </script>
  
  <style>

  </style>
  
  <div class="field">
    <label class="label">{label}</label>
    {#each opts as opt}
    <label>
      <input type=radio bind:group={value} name="scoops" value={opt}>
      {opt}
    </label>
    {/each}
  </div>
  