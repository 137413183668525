<script>
  import AJAXFetch from "./AJAXFetch.svelte";
  import GatingController from "./GatingController.svelte";
  import Modal from "./Modal.svelte";
  import QPlot from "./QPlot.svelte";
  import QTable from "./QTable.svelte";
  import SelectField from "./SelectField.svelte";

  let current_state = "upload_fcs";
  let assay_id;
  let well_ids = [];
  let new_well_ids = [];
  let well_names = [];
  let fcs_files = [];
  let review_indx;
  $: review_well_id = well_ids && well_ids.length > 0 ? well_ids[review_indx]: null;

  let params_objs;
  let params;
  let instruments;
  let bead_targets;
  let bead_target;
  let beadtarget_id;
  let qc_type;
  let gating_param;
  let fsc_param;
  let ssc_param;
  let gating_schema;
  let schema_options;
  let wells_instrument;
  let wells_instruments;

  let plates = [];
  let selected_plate;
  let plate_name;
  let well_table = {
    columns: [
      {"id": "well", "value": "Well"},
      {"id": "fcs_filename", "value": "FCS Filename"}
    ],
    rows: []
  };
  $: well_names = well_table.rows.map(r => r.well);

  let qc_run_table = {
    columns: [
      {"id": "plate", "value": "Plate"},
      {"id": "well", "value": "Well"},
      {"id": "fcs_filename", "value": "FCS Filename"}
    ],
    rows: []
  };

  function get_wids(){
    well_ids = well_table.rows.filter(r => r.selected == true).map(r => r.well_id);
    well_names = well_table.rows.map(r => r.well);
    fcs_files = well_table.rows.filter(r => r.selected == true).map(r => r.fcs_filename);
    plate_name = plates.find(p => p.id == selected_plate).name;
  }

  function submit_wells(){
    let rows = well_table.rows.filter(r => r.selected == true);
    qc_run_table.rows = [...rows];
    progress = 100;
  }

  let running = false;
  let num_wells = 1;
  let wells_ok = 0;
  let wells_failed = 0;
  $: progress = 100 * (wells_ok + wells_failed) / num_wells;
  $: if(progress == 100) {
    running = false;
    if(wells_failed == 0) {
      switch (current_state) {
        case "upload_fcs":
          current_state = "setup_gating";
          break;
        case "setup_gating":
          review_indx = 0;
          current_state = "review_gating";
          break;
        case "review_gating":
          wells_instruments = [];
          current_state = "save";
          break;
        case "save":
          window.location = "/lab-ops/instrument-qc-8peak";
          break;
      }
    }
  }

  let params_url = '/lab-ops/instrument-qc/comp-gating-params';
  function params_url_data() {
    var data = new FormData();
    data.set('well_ids', well_ids.join(","));
    return data;
  }
  function parse_params(e) {
    var res = e.detail;
    assay_id = res.assay_id;
    qc_type = res.qc_type;
    instruments = res.instrument_spec;
    bead_targets = res.beadtarget
    beadtarget_id = res.beadtarget[0].id;
    schema_options = res.compgating_spec_name;
    params = res.parameters;
    fsc_param = params.find(p => p.value === 'FSC-A').id || params.find(p => p.value.startsWith('FSC')).id || params[0].id;
    ssc_param = params.find(p => p.value === 'SSC-A').id || params.find(p => p.value.startsWith('SSC')).id || params[1].id;
    gating_param = params.find(p => !(p.value.startsWith('SSC') || p.value.startsWith('FSC'))).id || params[2].id;
  }

  $: gate_url = '/workflow/' + assay_id + '/create-comp-gating/';
  function gate_url_data() {
    var data = new FormData();
    data.set('compgating_spec_name', gating_schema);
    data.set('parameter_map', JSON.stringify({param1: fsc_param, param2: ssc_param, param3: gating_param}));
    return data;
  }
  function run_gating() {
    running = true;
    wells_ok = 0;
    wells_failed = 0;
    num_wells = well_ids.length;
    well_ids.forEach(async (w) => {
      const res = await fetch(gate_url + w, {
        method: 'POST',
        body: gate_url_data(),
      });
      if(res.ok) {
        const json = await res.json();
        wells_ok += 1;
      } else {
        wells_failed += 1;
      }
    });
  }

  let save_url = '/lab-ops/instrument-qc/add-qc-run';
  function save_url_data(indx) {
    var data = new FormData();
    data.set('beadtarget_id', bead_target);
    data.set('qc_type', qc_type);
    data.set('parameter_ids', params.filter(p => !(p.value.startsWith('FSC') || p.value.startsWith('SSC')|| p.value.startsWith('Tim'))).map(p => p.id).join(","));
    data.set('gatingparam_name', params.filter(p => p.id == gating_param)[0].value);
    data.set('fsc_name', params.filter(p => p.id == fsc_param)[0].value);
    data.set('ssc_name', params.filter(p => p.id == ssc_param)[0].value);
    data.set('instrument_id', wells_instrument);
    data.set('well_ids', well_ids[indx]);
    return data;
  }
  function save_qc_runs() {
    running = true;
    wells_ok = 0;
    wells_failed = 0;
    num_wells = well_ids.length;
    well_ids.forEach(async (w, i) => {
      const res = await fetch(save_url, {
        method: 'POST',
        body: save_url_data(i),
      });
      if(res.ok) {
        wells_ok += 1;
      } else {
        wells_failed += 1;
      }
    });
  }

  let plates_url = "/api/plates";

  $: gatingreview_url = "/api/gatingreview?plate_id="+selected_plate;

</script>

<style>
  .container {
    background: white;
    padding: 1em;
    width: 740px;
    /* height: 640px; */
  }

  .wide {
    width: 1000px;
  }

  .layout {
    margin: 1em;
  }

  .step-title {
    font-weight: 900;
    font-size: 1.5em;
    margin: 1em;
  }

  .horizontal {
    display: flex;
    justify-content: space-between;
  }

  .qog-button {
    height: 2em;
    margin: 1em;
  }

  .file-name {
    max-width: 50em;
  }

  .cycle-gating{
    text-align: center;
    padding-bottom: 20px;
  }
</style>

<div class="container" class:wide={current_state === "review_gating"}>
  {#if current_state === "upload_fcs"}
    <div class="layout">
      <div class="step-title">Select a Plate</div>
      <AJAXFetch bind:url={plates_url} method="GET" on:fetched={(e) => plates = e.detail}>
        <span slot="success">
          <SelectField label="Plate:" opts={plates} display_key="name" value_key="id" bind:value={selected_plate} />
          {#if selected_plate}
            <AJAXFetch bind:url={gatingreview_url} method="GET" on:fetched={(e) => well_table.rows = e.detail}>
              <span slot="success">
                <div class="table-container" style="width:690px;max-height:500px;overflow:scroll">
                  <QTable
                    columns={well_table.columns}
                    rows={well_table.rows}
                    show_checkboxes={well_table.rows.length > 0}
                    on:selection-changed={(e) => {get_wids()}}
                    />
                </div>
              {#if well_ids.length > 0}
                <button class="qog-button" on:click={submit_wells}>Select {well_ids.length} wells</button>
              {/if}
              </span>
            </AJAXFetch>
          {/if}
        </span>
      </AJAXFetch>
    </div>
  {/if}

  {#if current_state === "setup_gating"}
    {#if well_ids.length > 0}
      <div class="layout">
        <AJAXFetch url={params_url} method="POST" post_data={params_url_data()} on:fetched={(e) => parse_params(e)}>
          <span slot="success">
            <div class="step-title">Auto Gating {well_ids.length} Wells</div>
            <div class="horizontal">
              <SelectField label="Forward Scatter:" opts={params} display_key="value" value_key="id" bind:value={fsc_param} />
              <SelectField label="Side Scatter:" opts={params} display_key="value" value_key="id" bind:value={ssc_param} />
              <SelectField label="Gating Parameter:" opts={params} display_key="value" value_key="id" bind:value={gating_param} />
              <SelectField label="Gating Schema:" opts={schema_options} display_key="value" value_key="value" bind:value={gating_schema} />
            </div>
            <button class="qog-button" on:click={run_gating} >Run Gating</button>
            <br />
            {#if running}
              <progress class="progress" value={progress} max="100">{progress}%</progress>
            {/if}
            {#if wells_failed > 0}
              <div>Gating failed on {wells_failed} wells</div>
            {/if}
          </span>
        </AJAXFetch>
      </div>
    {/if}
  {/if}

  {#if current_state === "review_gating"}
      <div class="layout">
        <div class="step-title">Review Gating</div>
        <div class="cycle-gating">
          <button title="Previous" disabled={review_indx == 0} on:click={()=> review_indx -= 1} class="button qog-button"><i class="fas fa-chevron-left"></i></button>
          <button title="Next" disabled={review_indx == num_wells-1} on:click={()=> review_indx += 1} class="button qog-button"><i class="fas fa-chevron-right"></i></button>
        </div>
        <GatingController well_id={review_well_id} well_ids={well_ids} page={"instrument_qc"}/>
        <button class="qog-button" on:click={() => {wells_failed=0; progress=0;  progress=100}}>Review Complete</button>
      </div>
  {/if}

  {#if current_state === "save"}
      <div class="layout">
        <div class="step-title">Save QC Run</div>
        <div class="horizontal">
          <SelectField label="Instrument:" opts={instruments} display_key="value" value_key="id" bind:value={wells_instrument} />
          <SelectField label="Bead Target:" opts={bead_targets} display_key="value" value_key="id" bind:value={bead_target} />
        </div>
        <div class="table-container" style="width:690px;max-height:500px;overflow:scroll">
          <QTable
            columns={qc_run_table.columns}
            rows={qc_run_table.rows}
            />
        </div>
        <button class="qog-button" on:click={save_qc_runs} >Save</button>
        <br />
        {#if running}
          <progress class="progress" value={progress} max="100">{progress}%</progress>
        {/if}
        {#if wells_failed > 0}
          <div>Save QC Run failed on {wells_failed} wells</div>
        {/if}
      </div>
  {/if}
</div>
