<script>
  // Import the getContext function from svelte
  import { getContext, onMount, createEventDispatcher } from 'svelte';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { data, xGet, yGet, xScale, yScale, padding } = getContext('LayerCake');

  export let gate;
  export let rotation;
  // Customizable defaults
  let g;
  let width;
  let dispatch = createEventDispatcher();
  let ellipse;
  let fill;
  export let active = false;
  export let selected = false;
  let live = null;


  function oMousePosSVG(e) {
    let svg = g.closest("svg");
    var p = svg.createSVGPoint();
    p.x = e.x;
    p.y = e.y;
    var ctm = svg.getScreenCTM().inverse();
    var p =  p.matrixTransform(ctm);
    return p;
  }

  function move_gate(e){
    if(live !== null){
      if(live === "all"){
        var x = e.movementX;
        var y = e.movementY;
        for(var i = 0; i < gate.length; i++){
          gate[i].x = $xScale.invert($xScale(gate[i].x) + x);
          gate[i].y = $yScale.invert($yScale(gate[i].y) + y);
        }

      }
      else if (live === "rotate"){
        var p = oMousePosSVG({x: e.clientX-$padding.left, y: e.clientY-$padding.top});
        // p = {x: $xScale.invert(p.x), y: $yScale.invert(p.y)};
        var points = JSON.parse(JSON.stringify(rotated_points))
        var angle =  Math.atan2(p.y-$yScale(center[1]), p.x-$xScale(center[0])) * 180 / Math.PI
        angle += 90
        points = points.map((g)=>{
          return rotate_points(center, g, angle);
        });
        gate = gate.map((g, i)=>{
          g.x = points[i].x;
          g.y = points[i].y;
          return g
        });
        rotation = angle;
      }
      else{
        var p = oMousePosSVG({x: e.clientX-$padding.left, y: e.clientY-$padding.top});
        var min_x = rotated_points.map(g=>g.x).indexOf(Math.min(...rotated_points.map(g=>g.x)));
        var max_x = rotated_points.map(g=>g.x).indexOf(Math.max(...rotated_points.map(g=>g.x)));
        var min_y = rotated_points.map(g=>g.y).indexOf(Math.min(...rotated_points.map(g=>g.y)));
        var max_y = rotated_points.map(g=>g.y).indexOf(Math.max(...rotated_points.map(g=>g.y)));

        var points = JSON.parse(JSON.stringify(rotated_points))
        p = {x: $xScale.invert(p.x), y: $yScale.invert(p.y)};
        p = rotate_points(center, p, -rotation);
        // var diff_x = rotated_points[live].x - p.x
        // var diff_y = rotated_points[live].y - p.y
        if (live === 0){
          var diff_x = points[min_x].x - p.x;
          var diff_y = points[min_y].y - p.y
          points[min_x].x = p.x;
          points[max_x].x += diff_x;
          points[min_y].y = p.y;
          points[max_y].y += diff_y;
        }
        else if (live === 1){
          var diff_x = points[min_x].x - p.x;
          var diff_y = points[max_y].y - p.y
          points[min_x].x = p.x;
          points[max_x].x += diff_x;
          points[max_y].y = p.y;
          points[min_y].y += diff_y;
        }
        else if (live === 2){
          var diff_x = points[max_x].x - p.x;
          var diff_y = points[max_y].y - p.y
          points[max_x].x = p.x;
          points[min_x].x += diff_x;
          points[max_y].y = p.y;
          points[min_y].y += diff_y;
        }
        else{
          var diff_x = points[max_x].x - p.x;
          var diff_y = points[min_y].y - p.y
          points[max_x].x = p.x;
          points[min_x].x += diff_x;
          points[min_y].y = p.y;
          points[max_y].y += diff_y;
        }
        points = points.map((g)=>{
          return rotate_points(center, g, rotation);
        });
        gate = gate.map((g, i)=>{
          g.x = points[i].x;
          g.y = points[i].y;
          return g
        });
        // gate[live].x = $xScale.invert(p.x);
        // gate[live].y = $yScale.invert(p.y);

      }

      gate = gate;
    }
  }


  function mousedown(e){
    if (!g.contains(e.target)) return;
    if(e.target.nodeName === "ellipse"){
      live = "all";
    }
    else if(e.target.nodeName === "circle"){
      if (e.target.hasAttribute("index")){
        live = parseInt(e.target.getAttribute("index"));
      }
      else{
        live = "rotate";
      }
    }
  }

  function mouseup(e){
    live=null;
    if (!g.contains(e.target)) return;
    if(e.target.nodeName === "circle" || e.target.nodeName === "ellipse"){
      selected = !selected;
    }
  }

  const dist = (p1, p2) => Math.sqrt( Math.pow(p1.x-p2.x, 2) + Math.pow(p1.y-p2.y, 2) );
  const arrSum = (arr, axis) => arr.reduce((a,b) => a + b[axis], 0)

  let center;
  let rotated_points;

  $: center = [arrSum(gate, 'x') / gate.length, arrSum(gate, 'y') / gate.length];
  $: rotated_points = gate.map((g)=>{
    return rotate_points(center, g, -rotation)
  })

  function rotate_points(center, g, angle){
    var rad_angle = angle * Math.PI / 180
    return {
            x: center[0] + Math.cos(rad_angle) * (g.x - center[0]) - Math.sin(rad_angle) * (g.y - center[1]),
            y: center[1] + Math.sin(rad_angle) * (g.x - center[0]) + Math.cos(rad_angle) * (g.y - center[1])
           }
  }

  // function update_ellipse(){
  //
  //   center = [arrSum(gate, 'x') / gate.length, arrSum(gate, 'y') / gate.length];
  //   rotated_points = gate.map((g)=>{
  //     return rotate_points(center, g, -rotation)
  //   })
  // }


  function keypress(e){
    if(selected){
      switch(e.which){
        case 37:
          if(e.ctrlKey){
            rotation -= 5;
            var points = JSON.parse(JSON.stringify(rotated_points))
            points = points.map((g)=>{
              return rotate_points(center, g, rotation);
            });
            gate = gate.map((g, i)=>{
              g.x = points[i].x;
              g.y = points[i].y;
              return g
            });

          }
          else{
            gate = gate.map((g)=>{
              g.x = g.x - 0.01;
              return g;
            })
          }

          break;
        case 38:
          gate = gate.map((g)=>{
            g.y = g.y + 0.01;
            return g;
          })
          break;
        case 39:
          if(e.ctrlKey){
            rotation += 5;
            var points = JSON.parse(JSON.stringify(rotated_points))
            points = points.map((g)=>{
              return rotate_points(center, g, rotation);
            });
            gate = gate.map((g, i)=>{
              g.x = points[i].x;
              g.y = points[i].y;
              return g
            });

          }
          else{
            gate = gate.map((g)=>{
              g.x = g.x + 0.01;
              return g;
            })
          }

          break;
        case 40:
          gate = gate.map((g)=>{
            g.y = g.y - 0.01;
            return g;
          })
          break;
        case 107:
        case 187:
          var min_x = rotated_points.map(g=>g.x).indexOf(Math.min(...rotated_points.map(g=>g.x)));
          var max_x = rotated_points.map(g=>g.x).indexOf(Math.max(...rotated_points.map(g=>g.x)));
          var min_y = rotated_points.map(g=>g.y).indexOf(Math.min(...rotated_points.map(g=>g.y)));
          var max_y = rotated_points.map(g=>g.y).indexOf(Math.max(...rotated_points.map(g=>g.y)));
          var points = JSON.parse(JSON.stringify(rotated_points))
          points[min_x].x -= 0.01;
          points[max_x].x += 0.01;
          points[min_y].y -= 0.01;
          points[max_y].y += 0.01;
          points = points.map((g)=>{
            return rotate_points(center, g, rotation);
          });
          gate = gate.map((g, i)=>{
            g.x = points[i].x;
            g.y = points[i].y;
            return g
          });
          break;
        case 109:
        case 189:
          var min_x = rotated_points.map(g=>g.x).indexOf(Math.min(...rotated_points.map(g=>g.x)));
          var max_x = rotated_points.map(g=>g.x).indexOf(Math.max(...rotated_points.map(g=>g.x)));
          var min_y = rotated_points.map(g=>g.y).indexOf(Math.min(...rotated_points.map(g=>g.y)));
          var max_y = rotated_points.map(g=>g.y).indexOf(Math.max(...rotated_points.map(g=>g.y)));
          var points = JSON.parse(JSON.stringify(rotated_points))
          points[min_x].x += 0.01;
          points[max_x].x -= 0.01;
          points[min_y].y += 0.01;
          points[max_y].y -= 0.01;
          points = points.map((g)=>{
            return rotate_points(center, g, rotation);
          });
          gate = gate.map((g, i)=>{
            g.x = points[i].x;
            g.y = points[i].y;
            return g
          });
          break;
      }
    }
  }

  $: ellipse = {
    x: $xScale(arrSum(gate, 'x') / gate.length),
    y: $yScale(arrSum(gate, 'y') / gate.length),
    rx: ($xScale(Math.max(...rotated_points.map(g=>g.x))) - $xScale(Math.min(...rotated_points.map(g=>g.x)))) / 2,
    ry: ($yScale(Math.min(...rotated_points.map(g=>g.y))) - $yScale(Math.max(...rotated_points.map(g=>g.y)))) / 2,
    rotation: rotation
  };


  // $: gate, rotation, update_ellipse();

  let bounds_points;
  let bounds_rect;

  // $: bounds_points = [
  //   {x: $xScale(gate[0].x), y: $yScale(gate[2].y)},
  //   {x: $xScale(gate[0].x), y: $yScale(gate[3].y)},
  //   {x: $xScale(gate[1].x), y: $yScale(gate[3].y)},
  //   {x: $xScale(gate[1].x), y: $yScale(gate[2].y)},
  // ]
  // $: bounds_points = [
  //   {x: $xScale(Math.min(...gate.map(g=>g.x))), y: $yScale(Math.min(...gate.map(g=>g.y)))},
  //   {x: $xScale(Math.min(...gate.map(g=>g.x))), y: $yScale(Math.max(...gate.map(g=>g.y)))},
  //   {x: $xScale(Math.max(...gate.map(g=>g.x))), y: $yScale(Math.max(...gate.map(g=>g.y)))},
  //   {x: $xScale(Math.max(...gate.map(g=>g.x))), y: $yScale(Math.min(...gate.map(g=>g.y)))},
  // ]
  $: bounds_points = [
    {x: ellipse.x-ellipse.rx, y: ellipse.y+ellipse.ry},
    {x: ellipse.x-ellipse.rx, y: ellipse.y-ellipse.ry},
    {x: ellipse.x+ellipse.rx, y: ellipse.y-ellipse.ry},
    {x: ellipse.x+ellipse.rx, y: ellipse.y+ellipse.ry},
  ]

  // $: bounds_points = gate.map((g)=>{
  //   return {x: $xScale(g.x), y: $yScale(g.y)}
  // })

  $: bounds_rect = bounds_points.map((p)=>{
    return [p.x, p.y].join(",");
  }).join(" ");

  $: width = active? 3: 1;
  $: fill = active || selected? "#b15928": "#ff7f00";

  onMount(()=>{
    let svg = g.closest("svg");
    // svg.addEventListener("mousedown", start_draw);
    svg.addEventListener("mousemove", move_gate);
    svg.addEventListener("mouseup", mouseup);
    window.addEventListener("keydown", keypress)
  })

</script>

<g transform="rotate({ellipse.rotation}, {ellipse.x}, {ellipse.y})" on:mousedown={mousedown} on:mouseover={()=>active=true} on:mouseout={()=>active=false} bind:this={g}>
  {#if selected || active || live}
    <polygon points="{bounds_rect}" stroke={fill} class="bounds"></polygon>
    {#each bounds_points as p, i}
      <circle index={i} cx='{ p.x }' cy='{ p.y }' fill={fill} r={width} ></circle>
      <circle class="handle" index={i} cx='{ p.x }' cy='{ p.y }' r={8} ></circle>
    {/each}
    <line x1='{ ellipse.x }' x2='{ ellipse.x }' y1='{ ellipse.y-ellipse.ry }' y2='{ ellipse.y-ellipse.ry-5 }' fill={fill} stroke={fill} stroke-width={width}></line>
    <circle cx='{ ellipse.x }' cy='{ ellipse.y-ellipse.ry-11 }' stroke={fill} fill="transparent" r={6} ></circle>
  {/if}
  <ellipse cx={ellipse.x} cy={ellipse.y} rx={ellipse.rx} ry={ellipse.ry} stroke={fill} class="gate"></ellipse>
</g>

<style>
  .gate{
    fill: transparent;
    stroke-width: 2;
  }

  .bounds{
    stroke-dasharray: 3,3;
    fill: transparent;
  }

  .handle{
    stroke: transparent;
    fill: transparent;
    cursor: move;
  }

</style>
