<script>
    export let label;
    export let value;
    export let type = "text";
    export let step;
    export let dropdown;
    export let options;
    export let filter = "false";
    let updated_options = [];
    let style = $$props.style;
    let isFocused = false;

    function onInput(e){
      value=e.target.value;
      if(filter=="true"){
        if(e.target.value == ""){
          updated_options = options;
        } else {
          updated_options = options.filter(function(o){
            return o.toLowerCase().search(value.toLowerCase()) !== -1
          });
        }
      }
      else {
        updated_options = options;
      }
    }

    function onFocus(e){
      if(filter=="true"){
        if(e.target.value == ""){
          updated_options = options;
        } else {
          updated_options = options.filter(function(o){
            return o.toLowerCase().search(value.toLowerCase()) !== -1
          });
        }
      }
      else {
        updated_options = options;
      }
      isFocused=true;
      document.getElementById('dropdown-content').style.display = "block";
    }

    function onBlur(){
      isFocused=false;
      document.getElementById('dropdown-content').style.display = "none";
    }

    function on_option_click(e){
      value = e.target.getAttribute("value");
    }
</script>

<div class="field" style={style}>
    <label class="label">{label}</label>
    {#if dropdown == "true"}
        <div class="dropdown">
          <input class="input" on:input={onInput} on:focus={onFocus} on:blur={onBlur} {value}/>
        </div>
        <div class="dropdown-content" id="dropdown-content">
          {#each updated_options as opt}
            <p value={opt} on:mousedown={on_option_click}>{opt}</p>
          {/each}
      </div>
    {:else}
      <div class="control">
        {#if type == "text"}
          <input class="input" type="text" bind:value />
        {:else if type == "number"}
          <input class="input" type="number" {step} bind:value />
        {:else if type == "date"}
          <input class="input" type="date" bind:value />
        {/if}
      </div>
    {/if}
</div>
<style>

  .input{
    width: 300px;
  }

  .dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  overflow: scroll;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  max-height: 300px;
  width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content p:hover {background-color: #f1f1f1}

</style>
