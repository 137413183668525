<script>
  // Import the getContext function from svelte
  import { getContext, onMount, createEventDispatcher } from 'svelte';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { data, xGet, yGet, xScale, yScale, padding } = getContext('LayerCake');

  export let gate;
  // Customizable defaults
  let g;
  let width;
  let dispatch = createEventDispatcher();
  let points;
  let line;
  let fill;
  export let active = false;
  export let selected = false;
  let live = null;


  function oMousePosSVG(e) {
    let svg = g.closest("svg");
    var p = svg.createSVGPoint();
    p.x = e.x;
    p.y = e.y;
    var ctm = svg.getScreenCTM().inverse();
    var p =  p.matrixTransform(ctm);
    return p;
  }

  function move_gate(e){
    if(live !== null){
      var p = oMousePosSVG({x: e.clientX-$padding.left, y: e.clientY-$padding.top});
      if([0,2,4].indexOf(live) != -1){
        gate[live].y = $yScale.invert(p.y);
      }
      if([1,3,4].indexOf(live) != -1){
        gate[live].x = $xScale.invert(p.x);
      }



      gate = gate;
    }
  }

  function keypress(e){
    if(selected){
      switch(e.which){
        case 37:
          gate[4].x -= 0.01;
          break;
        case 38:
          gate[4].y += 0.01;
          break;
        case 39:
          gate[4].x += 0.01;
          break;
        case 40:
          gate[4].y -= 0.01;
          break;
      }
    }
  }

  function mouseup(e){
    live=null;
    if (!g.contains(e.target)) return;
    if(e.target.nodeName === "circle" || e.target.nodeName === "polygon"){
      selected = !selected;
    }
  }


  function mousedown(e){
    if (!g.contains(e.target)) return;
    if(e.target.nodeName === "circle"){
      live = parseInt(e.target.getAttribute("index"))
    }
  }

  $: points = gate.map((g)=>{
    return {"x": $xScale(g.x), "y": $yScale(g.y)}
  });

  $: line = [points[0], points[4], points[1], points[4], points[2], points[4], points[3], points[4]].map((p)=>{
    return [p.x, p.y].join(",");
  }).join(" ");

  $: width = active? 3: 1;
  $: fill = active || selected? "#b15928": "#ff7f00";

  onMount(()=>{
    let svg = g.closest("svg");
    // svg.addEventListener("mousedown", start_draw);
    svg.addEventListener("mousemove", move_gate);
    svg.addEventListener("mouseup", mouseup);
    window.addEventListener("keydown", keypress)
  })

</script>

<g on:mousedown={mousedown} on:mouseover={()=>active=true} on:mouseout={()=>active=false} bind:this={g}>
  <polygon  points="{line}" stroke={fill} class="gate"></polygon>
  {#each points as p, i}
    <circle index={i} cx='{ p.x }' cy='{ p.y }' fill={fill} r={width} ></circle>
    <circle class="handle" index={i} cx='{ p.x }' cy='{ p.y }' r={8} ></circle>
  {/each}
</g>

<style>
  .gate{
    fill: transparent;
    stroke-width: 2;
  }

  .handle{
    stroke: transparent;
    fill: transparent;
    cursor: move;
  }

</style>
