<script>
  import { onMount } from "svelte";
  export let table;

  function click_column(col, c){
    if (!c.click) return ;
    c.click(col, c);
  }

  function sort_table(col){
    if (!col.sort) return ;
    table.columns.forEach(
      c => {
        if(c.hasOwnProperty("sort_direction") && c.id != col.id){
          delete c.sort_direction
        }
    });
    if (typeof col.sort == "function"){
      table.rows.sort(col.sort)
    }
    else{
      table.rows.sort((a,b) => ''+a[col.id].localeCompare(b[col.id]))
    }
    if (col.sort_direction == 'asc'){
      col.sort_direction = 'desc';
      table.rows.reverse()
    }
    else{
      col.sort_direction = 'asc';
    }
    table.rows = table.rows;
  }

  function filter_table(col, c){
    var value = document.getElementById("filter_"+col.id).value;
    if (c.hasOwnProperty("filter")){
      table.rows = table.rows.filter((r) => {return c.filter(r, col.id, value)});
    }
    else{
      table.rows = table.rows.filter((r) => {
        if (!r.hasOwnProperty("hidden")){
          r.hidden = {};
        }
        r.hidden[col.id] = (r[col.id].toLowerCase().indexOf(value.toLowerCase()) == -1);
        return true;
      })
    }
  }

  function row_click(e, col, row){
    if(col.edit){
      e.currentTarget.querySelector("div").style.display = "none";
      e.currentTarget.querySelector("input").style.display = "block";
      e.currentTarget.querySelector("input").select();
    }
    else if(col.hasOwnProperty("click") && col.click == "delete"){
      delete_row(e, col, row);
    }
    else if(col.hasOwnProperty("click") && typeof col.click == "function"){
      col.click(e, col, row);
    }
  }

  function edit_row(e, col, row){
    var value = e.currentTarget.value;
    row[col.id] = value;
    e.currentTarget.parentElement.parentElement.querySelector("div").style.display = "block";
    e.currentTarget.parentElement.parentElement.querySelector("input").style.display = "none";
    table.rows = table.rows;
  }

  function delete_row(e, col, row){
    table.rows = table.rows.filter((r) => {return r != row});
  }

</script>

<table
  class="table is-fullwidth"
  style="{table.style? table.style: ''}">
  <thead>
    {#each table.columns as col}
      <th
        style="{col.style? col.style: ''}"
        class:clickable={col.sort}
        class:asc={col.sort_direction=='asc'}
        class:desc={col.sort_direction=='desc'}
      >
        <div on:click={sort_table(col)}>
          {#each col.value as c}
            {#if typeof c == "string"}
              <div>{c}</div>
            {:else if c.type == "string"}
              <div style="{c.style? c.style: ''}" class:launchable={c.hasOwnProperty('click')} on:click={click_column(col,c)}>{c.value}</div>
            {:else if c.type == "select"}
              <div class="select">
                <select id="filter_{col.id}" on:change={filter_table(col, c)} value="">
                    <option value=""></option>
                  {#each c.options as opt}
                    <option value={opt.id}>{opt.value}</option>
                  {/each}
                </select>
              </div>
            {:else if c.type == "checkbox"}
              <div class="check-container">
                <div class="check">
                  <label class="checkbox">
                    <input type="checkbox" on:click={click_column(col, c)} checked={c.checked}>
                    {c.label}
                </div>
              </div>
            {:else}
              <div>
                <input id="filter_{col.id}" on:keyup={filter_table(col, c)} class="input" type="text" placeholder="{c.value? c.value: ''}">
              </div>
            {/if}
          {/each}
        </div>
      </th>
    {/each}
  </thead>
  <tbody>
    {#each table.rows as row}
      {#if !row.hidden || Object.values(row.hidden).every((h)=> {return !h})}
        <tr>
          {#each table.columns as col}
            {#if row.hasOwnProperty(col.id)}
              <td style="{row[col.id].style? row[col.id].style: ''}" on:click={(e) => row_click(e, col, row)}>
                {#if typeof row[col.id] == "string" || typeof row[col.id] == "number"}
                  <div>{row[col.id]}</div>
                {:else if row[col.id].hasOwnProperty("text")}
                  <div>{row[col.id].text}</div>
                {:else if row[col.id].hasOwnProperty("icon")}
                  <div>{row[col.id].hasOwnProperty("content") ? row[col.id].content + " " : ""}<i class="fas {row[col.id].icon}"></i></div>
                {:else if row[col.id].hasOwnProperty("checkbox")}
                  <div><input type="checkbox" checked={row[col.id].value}></div>
                {:else}
                  <div><button class="button">{row[col.id].button}</button></div>
                {/if}
                {#if col.edit}
                  <div>
                    <input
                      on:blur={(e) => edit_row(e, col, row)}
                      on:keyup={(e)=> {if(e.keyCode == 13)edit_row(e, col, row)}}
                      type="text"
                      class="input edit-input"
                      value={row[col.id]}>
                  </div>
                {/if}
              </td>
            {/if}
          {/each}
        </tr>
      {/if}
    {/each}
  </tbody>
</table>

<style>
  .clickable{
    cursor: pointer;
  }

  th{
    position: relative;
    background-color: white;
    color: black;
  }

  .check-container{
    padding: 10px;
  }

  .launchable {
      color: #42099e;
  }

  .check{
    position: absolute;
    bottom: 0;
  }

  th.asc div > div:first-of-type::after{
    content: "\f077";
    font-family: "Font Awesome 5 Free";
  }

  th.desc div > div:first-of-type::after{
    content: "\f078";
    font-family: "Font Awesome 5 Free";
  }

  .edit-input{
    display: none;
  }
</style>
