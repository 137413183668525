<script>

  export let columns;
  export let rows;
  export let coloptions = {};
  export let caption = "";

  let selected_value = "";
  let edit_cell = "";
  let selection_start = "";
  let selection_end = "";
  let extend_selection = false;
  let history = [JSON.stringify(rows)];   //To implement undo & redo
  let hindx = 0;
  // $: history, hindx, console.log(history, hindx);

  function hpush() {
    const old_rows = JSON.parse(history[hindx]);
    var modified = false;
    for(var i=0; i<old_rows.length; i++) {
      if(Object.entries(old_rows[i]).sort().toString() !== Object.entries(rows[i]).sort().toString())
        modified = true;
    }
    if(modified) {
      hindx++;
      history = history.slice(0, hindx);
      history[hindx] = JSON.stringify(rows);
      // console.log('Pushed', hindx, history);
    }
  }

  function hundo() {
    if(hindx === 0)
      return;
    hindx--;
    rows = JSON.parse(history[hindx]);
    // console.log('Undo', hindx, history.length);
  }

  function hredo() {
    if(hindx === history.length - 1)
      return;
    hindx++;
    rows = JSON.parse(history[hindx]);
    // console.log('Redo', hindx, history.length);
  }
  
  function get_cell(x, y) {
    return document.getElementById("cell" + x + "," + y);
  }

  function on_mousedown(e) {
    if(is_cell_editable(edit_cell, e.target.getAttribute('x'), e.target.getAttribute('y'))) {
      return;
    }
    edit_cell = "";
    selected_value = "";
    e.preventDefault();
    e.stopPropagation();
    document.activeElement.blur();
    window.getSelection().removeAllRanges();
    if(e.target.hasAttribute('x')) {
      selection_start = {x: e.target.getAttribute('x'), y: e.target.getAttribute('y')};
      selection_end = {x: e.target.getAttribute('x'), y: e.target.getAttribute('y')};
      extend_selection = true;
    }
    // console.log("Mouse down", selection_start);
  }
  function on_mouseover(e) {
    if(extend_selection && e.target.hasAttribute('x')) {
        selection_end = {x: e.target.getAttribute('x'), y: e.target.getAttribute('y')};
        // console.log("Mouse over", selection_end);
    }
  }
  function on_mouseup(e) {
    const selection = window.getSelection();
    if(selection && selection.focusNode && selection.focusNode.lastChild && selection.focusNode.lastChild.id.includes('cell'))
      selection.removeAllRanges();

    extend_selection = false;
  }

  function is_cell_in_selection(tl, br, x, y) {
    const result = x >= tl.x && x <= br.x && y >= tl.y && y <= br.y;
    return result;
  }

  function is_cell_editable(edit_cell, x, y) {
    var result = false;
    if(edit_cell !== "" && edit_cell.getAttribute('x') == x && edit_cell.getAttribute('y') == y) {
      result = true;
    }
    return result;
  }

  function from_selection(action) {
    var data = []; 
    const tl = edit_cell === "" ? top_left : {'x': edit_cell.getAttribute('x'), 'y': edit_cell.getAttribute('y')};
    const br = edit_cell === "" ? bottom_right : {'x': edit_cell.getAttribute('x'), 'y': edit_cell.getAttribute('y')};
    for(var i = tl.x; i <= br.x; i++) {
      const ii = i - tl.x;
      data[ii] = [];
      for(var j = tl.y; j <= br.y; j++) {
        data[ii][j - tl.y] = rows[i][columns[j].id];
        if(action === "cut")
          rows[i][columns[j].id] = "";
      }
    }
    hpush();
    return data;
  }

  function paste_to_selection(paste_data) {
    const tl = edit_cell === "" ? top_left : {'x': edit_cell.getAttribute('x'), 'y': edit_cell.getAttribute('y')};
    const br = edit_cell === "" ? bottom_right : {'x': edit_cell.getAttribute('x'), 'y': edit_cell.getAttribute('y')};
    for(var i = tl.x; i <= br.x; i++) {
      const ii = (i - tl.x) % paste_data.length;
      const row_from = [].concat(paste_data[ii]);  // Make sure it always is an array
      for(var j = tl.y; j <= br.y; j++) {
        const val = row_from[(j - tl.y) % row_from.length];
        const valNum = Number(val);
        if(!coloptions.hasOwnProperty(columns[j].id) || coloptions[columns[j].id].includes(val))
          rows[i][columns[j].id] = val;
        else if(coloptions[columns[j].id].includes(valNum))
          rows[i][columns[j].id] = valNum;
      }
    }
    hpush();
    // console.log("history is now", history);
  }

  function move_focus(direction) {
    if(edit_cell !== "") {
      var x = Number(edit_cell.getAttribute('x'));
      var y = Number(edit_cell.getAttribute('y'));
      switch(direction) {
        case 'up':
          x = x > 0 ? x-1: x;
          break;
        case 'down':
          x = x < rows.length - 1 ? x+1: x;
          break;
        case 'left':
          y = y > 0 ? y-1: y;
          break;
        case 'right':
          y = y < columns.length - 1 ? y+1: y;
          break;
      }
      click_cell(get_cell(x, y));
    }
  }

  async function keyboard_cmds(e) {
    switch(e.key) {
      case 'Escape':
        document.activeElement.blur();
        break;

      case 'Backspace':
      case 'Delete':
        from_selection("cut");
        break;

      case 'Tab':
        if(e.shiftKey)
          move_focus('left');
        else
          move_focus('right');
        break;

      case 'Enter':
      case 'ArrowDown':
        move_focus('down');
        break;

      case 'ArrowUp':
        move_focus('up');
        break;
      case 'c':
      case 'x':
        // console.log("Ctrl-" + e.key, top_left, bottom_right);
        if(e.ctrlKey || e.metaKey) {
          const copied_data = from_selection(e.key === 'x' ? "cut": "copy");
          const txt = copied_data.map(l => l.join("\t")).join("\n");
          navigator.clipboard.writeText(txt);
          // console.log('Ctrl-' + e.key + ' pressed', copied_data, txt);
        }
        break;
      case 'v':
        if(e.ctrlKey || e.metaKey) {
          // console.log('Ctrl-v pressed', copied_data);
          // e.preventDefault();
          const txt = await navigator.clipboard.readText();  // needs browser permissions
          const paste_data = txt.split("\n").map(l => l.split("\t"));
          // console.log(txt, paste_data);
          document.activeElement.blur();
          paste_to_selection(paste_data);
          rows = rows;
        }
        break;
      case 'z':
        if(e.ctrlKey || e.metaKey) {
          // console.log('Ctrl-z pressed', history);
          e.preventDefault();
          // console.log(history.length);
          // document.activeElement.blur();
          clear_selection();
          // console.log(history.length);
          hundo();
        }
        break;
      case 'y':
        if(e.ctrlKey || e.metaKey) {
          // console.log('Ctrl-y pressed', history);
          e.preventDefault();
          // console.log(history.length);
          clear_selection();
          // document.activeElement.blur();
          // console.log(history.length);
          hredo();
        }
        break;
    }
  }

  function click_cell(cell) {
    // console.log("called click cell", cell, edit_cell);
    clear_selection();
    edit_cell = cell;
    selected_value = rows[edit_cell.getAttribute("x")][columns[edit_cell.getAttribute("y")].id];
  }

  function selective_preventdefault(e) {
    // console.log("key pressed", e.key, e.ctrlKey);
    if(e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Tab')
      e.preventDefault();
    if((e.ctrlKey || e.metaKey) && ['Control', 'c', 'v', 'x', 'y', 'z'].includes(e.key))
      e.preventDefault();
  }

  function clear_selection() {
    // console.log("Clear selection called");
    edit_cell = "";
    selected_value = "";
    selection_start = "";
    selection_end = "";
    document.activeElement.blur();
  }

  function edit_complete(e) {
    const cell = e.target;
    const value = cell.tagName === "SELECT" ? selected_value : cell.value;
    rows[cell.getAttribute("x")][columns[cell.getAttribute("y")].id] = value;
    hpush();
    // clear_selection();
  }

  $: top_left = selection_start === "" ? "" : {x: Math.min(selection_start.x, selection_end.x), y:Math.min(selection_start.y, selection_end.y)};
  $: bottom_right = selection_start === "" ? "" : {x: Math.max(selection_start.x, selection_end.x), y:Math.max(selection_start.y, selection_end.y)};


</script>

<svelte:window on:keydown={keyboard_cmds} on:mousedown={clear_selection} on:mouseup={on_mouseup} />
<main>
  <table>
    {#if caption.length > 0}
      <caption>
        {caption}
      </caption>
    {/if}
    <caption>
      <b>Double-click</b> to edit; To select cells, <b>click and drag</b> with mouse. Use Excel keybindings like <b>Ctrl-c</b> (copy), <b>Ctrl-x</b> (cut), <b>Ctrl-v</b> (paste), <b>Ctrl-z</b> (undo) & <b>Ctrl-y</b> (redo)
    </caption>
    <thead>
      <tr>
        <th class="narrow" />
        {#each columns as h}
          <th>{h.value}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each rows as r, x}
        <tr>
          <td class="narrow">{x+1}</td>
          {#each columns as h, y}
            <td title={r[h.id]} style="max-width:50px" class:selected={is_cell_in_selection(top_left, bottom_right, x, y)}
              on:mousedown={on_mousedown}
              on:mouseover={on_mouseover} on:dblclick={(e) => click_cell(e.target)}
              id={"cell" + x + "," + y}
              {x} {y}
              >
                {#if is_cell_editable(edit_cell, x, y)}
                  <div
                    on:mousedown|stopPropagation
                    on:mouseup|stopPropagation
                  >
                    {#if coloptions.hasOwnProperty(h.id)}
                      <select bind:value={selected_value} 
                        on:change={(e) => edit_complete(e)}
                        on:keydown={selective_preventdefault}
                        {x} {y} autofocus
                      >
                        {#each coloptions[h.id] as coloption}
                          <option>{coloption}</option>
                        {/each}
                      </select>
                    {:else}
                      <input {x} {y} autofocus on:blur={(e) => edit_complete(e)} value={r[h.id]}
                        on:keydown={selective_preventdefault}
                      />
                    {/if}
                  </div>
                {:else}
                  {r[h.id]}
                {/if}
              </td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
</main>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }
  
  table {
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

  table td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  caption {
    font-size: 0.75em;
    font-style: italic;
  }

  th, td {
    height: 2em;
    width: 10em;
  }

  th.narrow, td.narrow {
    width: 3em;
    background-color: #ccc;
  }

  td.selected {
    background-color: #ccc;
  }

  table, th, td {
    border: 1px solid;
  }

  th {
    background-color: #ccc;
  }

  input, input:focus, select, select.focus {
    font-family: inherit;
    font-size: inherit;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    text-align: center;
    margin: 0;
    border: 0px;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
