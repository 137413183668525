<script>
  import { getContext, onMount, createEventDispatcher } from 'svelte';
  const { data, xGet, yGet, xScale, yScale } = getContext('LayerCake');
  export let gates = [];
  export let num_gates = 1;
  let draw = false;
  let g;
  let index = null;
  let selected_gates = [];
  let active_gate = null;

  let dispatch = createEventDispatcher();

  function oMousePosSVG(e) {
    let svg = g.closest("svg");
    var p = svg.createSVGPoint();
    p.x = e.clientX;
    p.y = e.clientY;
    var ctm = svg.getScreenCTM().inverse();
    var p =  p.matrixTransform(ctm);
    return p;
  }

  function start_draw(e){
    if (e.target.nodeName === "svg" && (num_gates === -1 || gates.length < num_gates)){
      var p = oMousePosSVG(e);
      gates.push([[$xScale.invert(p.x), $yScale.invert(p.y)]]);
      draw = true;
    }
    else if (e.target.nodeName === "polygon"){
      var i = parseInt(e.target.getAttribute("index"));
      if (selected_gates.indexOf(i) === -1) selected_gates.push(i);
      else selected_gates.splice(selected_gates.indexOf(i), 1);
      selected_gates = selected_gates;
      active_gate = i;
    }
    else if(num_gates === 1 && gates.length === 1){
      gates.pop();
      active_gate = null;
      selected_gates = [];
      dispatch("selection", gates)
      dispatch("endSelection", gates)
    }

  }

  function dist(p1, p2){
    return Math.sqrt(Math.pow(p1[0]-p2[0], 2)+Math.pow(p1[1] - p2[1], 2))
  }

  function drag(e){
    if(draw){
      var p = oMousePosSVG(e);
      var x = $xScale.invert(p.x);
      var y = $yScale.invert(p.y);
      var i = gates.length - 1;
      // console.log(dist(gate[gate.length-1], [x,y]))
      if (dist(gates[i][gates[i].length-1], [x,y]) > 0.01){
        gates[i].push([x,y]);
        gates = gates;
      }
      dispatch("selection", gates)
    }
    else if(active_gate != null){
      var x = e.movementX;
      var y = e.movementY;
      for(var i = 0; i < gates[active_gate].length; i++){
        gates[active_gate][i] = [
          $xScale.invert($xScale(gates[active_gate][i][0]) + x),
          $yScale.invert($yScale(gates[active_gate][i][1]) + y)
        ]
      }
      gates = gates;
      selected_gates = [];
      dispatch("selection", gates)
    }
    else{
      dispatch("selectionMousemove", [e])
    }
  }

  function end_draw(e){
    if(draw){
      var i = gates.length - 1;
      if(gates && gates[i].length < 3){
        gates.pop()
      }
      draw = false;
      // dispatch("selection", gate)
      dispatch("endSelection", gates)
    }
    if(active_gate != null){
      active_gate = null;
      dispatch("endSelection", gates)
    }

  }

  function get_coords(points){
    return points.map((p)=>{
      return [$xScale(p[0]), $yScale(p[1])].join(",");
    }).join(" ")
  }

  function delete_gates(e){
    if(selected_gates.length && (e.which === 8 || e.which === 46)){
      gates = gates.filter((g, i)=>{
        return selected_gates.indexOf(i) === -1;
      });
      selected_gates = [];
      dispatch("selection", gates)
      dispatch("endSelection", gates)
    }
  }


  onMount(()=>{
    let svg = g.closest("svg");
    svg.addEventListener("mousedown", start_draw);
    svg.addEventListener("mousemove", drag);
    svg.addEventListener("mouseup", end_draw);
    document.addEventListener("keydown", delete_gates)
  })

  function get_center(g, axis){
    var ag = g.map((a)=>{
      return a[axis];
    });
    return (ag.reduce((a,b)=>{
      return Math.min(a,b);
    })+ag.reduce((a,b)=>{
      return Math.max(a,b);
    }) ) / 2
  }





</script>
<g bind:this={g}>
  {#each gates as gate, i}
    {#if num_gates !== 1}
      <text class:selected={selected_gates.indexOf(i) !== -1} x='{$xScale(get_center(gate,0))}' y='{$yScale(get_center(gate,1))}' text-anchor="middle">Lasso {i+1}</text>
    {/if}
    {#if selected_gates.indexOf(i) !== -1 }
      <polygon class="draw selected" points={get_coords(gate)} index={i} />
    {:else}
      <polygon class="draw" points={get_coords(gate)} index={i} />
    {/if}
  {/each}
</g>

<style>

  .draw{
    fill: transparent;
    stroke: black;
    stroke-width: 3;
    /* stroke-dasharray: 1,1; */
  }

  .selected{
    stroke: var(--ryvett-grey-blue);

  }


  text{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

</style>
