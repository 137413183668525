<script>
  // Import the getContext function from svelte
  import { getContext, onMount, createEventDispatcher } from 'svelte';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { box, xGet, yGet, xScale, yScale, padding } = getContext('LayerCake');
  let g;
  // console.log(box)
  // Customizable defaults
  // export let id;
  export let annotations;

  let dispatch = createEventDispatcher();
  let selected_text = null;

  function oMousePosSVG(e) {
    let svg = g.closest("svg");
    var p = svg.createSVGPoint();
    p.x = e.clientX;
    p.y = e.clientY;
    var ctm = svg.getScreenCTM().inverse();
    var p =  p.matrixTransform(ctm);
    return p;
  }

  function move_text(e){
    if(selected_text){
      var p = oMousePosSVG(e);
      selected_text.x = $xScale.invert(Math.min(Math.max(p.x, $xScale.range()[0]), $xScale.range()[1]));
      selected_text.y = $yScale.invert(Math.min(Math.max(p.y, $yScale.range()[1]+$padding.bottom), $yScale.range()[0] + $padding.bottom));
      annotations = annotations;
    }
  }

  function end_move(e){
    if(selected_text){
      selected_text = null;
      dispatch("movetext");
    }
  }


</script>
<svelte:window on:mousemove={move_text} on:mouseup={end_move} />
<g bind:this={g}>
  {#each annotations as d, i}
    {#if !d.hidden}
      <defs>
        <filter x="0" y="0" width="1" height="1" id="filter{i}">
          <feFlood flood-color="white" result="bg" />
          <feMerge>
            <feMergeNode in="bg"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <text filter="url(#filter{i})" on:mousedown={()=>{selected_text=d}} x='{ $xGet(d) }' y='{ $yGet(d) }' text-anchor="middle">{d.text}</text>
    {/if}
  {/each}
</g>

<style>

  text{
    cursor: pointer;
    font-size: 12px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

</style>
