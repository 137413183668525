<script>
  import AJAXFetch from "./AJAXFetch.svelte";
  import QTable from "./QTable.svelte";

  let instrument_tbl_url = "/lab-ops/instrument-qc/get-instrument-table";
  let table;
</script>

<style>
  .table-container{
    max-height: 500px;
    overflow: scroll;
    margin-right: 5em;
    flex: 50%;
  }

  .container{
    display: flex;
    margin-top: 2em;
    margin-left: 10em;
  }
</style>

<div class="container">
  <AJAXFetch bind:url={instrument_tbl_url} method="GET" on:fetched={(e) => table = e.detail}>
    <span slot="success">
      <div class="table-container">
        <QTable
          rows={table.rows}
          columns={table.columns}
          title={table.title}
          span_rows={["Vendor", "Name", "Ser.Num.", "Baseline"]}
          highlight_on_hover
          />
      </div>
    </span>
  </AJAXFetch>
</div>

