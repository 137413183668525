<script>
	import { getContext, onMount, createEventDispatcher } from 'svelte';
	const { padding, width, height, xScale, yScale } = getContext('LayerCake');
	let dispatch = createEventDispatcher();
	export let active_zoom = false;
	export let control_action;
	export let show_pan = false;
</script>

<g>
	{#if show_pan}
		<text class:selected="{!control_action}" on:click='{(e)=>{control_action=null}}' x={$width-70} y={10}>
	    	&#xf245
	    	<title>Select</title>
	    </text>
		<text class:selected="{control_action == 'pan'}" on:click='{(e)=>{control_action="pan"}}' x={$width-50} y={10}>
	    	&#xf0b2
	    	<title>Pan</title>
	    </text>
	{/if}
    <text class:selected="{control_action == 'zoom'}" on:click='{(e)=>{control_action="zoom"}}' x={$width-30} y={10}>
    	&#xf00e
    	<title>Zoom</title>
    </text>
    <text on:click='{(e)=>{dispatch("reset")}}' x={$width-10} y={10}>
    	&#xf065
    	<title>Reset Zoom</title>
    </text>
</g>

<style>
	text{
	    font-family: "Font Awesome 5 Free";
	    font-size: 1em;
	    text-anchor: middle;
	    dominant-baseline: central;
	    font-weight: bold;
	    cursor: pointer;
	    opacity: 0.5;
	    -webkit-touch-callout: none; /* iOS Safari */
	    -webkit-user-select: none; /* Safari */
	     -khtml-user-select: none; /* Konqueror HTML */
	       -moz-user-select: none; /* Old versions of Firefox */
	        -ms-user-select: none; /* Internet Explorer/Edge */
	            user-select: none;
	  }

	  text:hover,
	  text.selected{
	  	opacity: 1.0;
	  }
</style>