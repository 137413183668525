<script>
  import AJAXFetch from "./AJAXFetch.svelte";
  import GatingController from "./GatingController.svelte";
  import InputField from "./InputField.svelte";
  import Loading from "./Loading.svelte";
  import Modal from "./Modal.svelte";
  import QPlot from "./QPlot.svelte";
  import QTable from "./QTable.svelte";
  import SelectField from "./SelectField.svelte";
  import SelectDataList from "./SelectDataList.svelte";
  import GatingPlotContainer from "./GatingPlotContainer.svelte";
  import MenuItem from "./MenuItem.svelte";

  export let abtiter_id;
  export let well_table;
  export let well_ids;
  export let select_str2var;
  export let selected_rows;
  export let edit_button;
  export let edit_titer_params;
  export let selected_fcs_files = null;
  export let selected_fcs_col = [{"id": "files", "value": "Selected FCS Files"}];
  export let celltypes;
  export let pos_celltype;
  export let neg_celltype;
  export let antibodies;
  export let antibody;
  export let fluorophores;
  export let fluorophore;
  export let paramlabels;
  export let paramlabel;
  export let vendors;
  export let vendor;
  export let lots;
  export let lotnum;
  export let max_conc;
  export let units;
  export let max_conc_unit;
  export let dilution;
  export let well_order;
  export let updated_well_order;
  let loading = false;

  function save_titer_params(){
    var well_columns = well_table.columns.map(r => r.id);
    well_columns.shift();
    var col_map = {};
    well_columns.map(function(r){
      if(r == "paramlabel"){
        col_map[r] = select_str2var[r].parameter_name;
      } else if(r == "max_conc_formatted"){
        col_map["max_conc"] = select_str2var[r];
        col_map["max_conc_unit"] = max_conc_unit;
        col_map[r] = select_str2var[r]+" "+max_conc_unit;
      } else if(r == "pos_celltype" || r == "neg_celltype"){
        col_map[r] = celltypes.find(v => v.id == select_str2var[r]).value;
      } else {
        col_map[r] = select_str2var[r];
      }
    });
    var new_rows = [];
    selected_rows.map(function(r){
      col_map["well_id"] = r.well_id;
      col_map["fcs_file_name"] = r.fcs_file_name;
      new_rows.push(JSON.parse(JSON.stringify(col_map)));
    });
    well_table.rows = well_table.rows.map(obj => new_rows.find(o => o.fcs_file_name === obj.fcs_file_name) || obj);
    if(updated_well_order == undefined){
      updated_well_order = well_order.map(function(r){return r.files});
    }
    edit_button = false;
    edit_titer_params = false;
  }

  async function apply_edit_titration() {
    loading = true;
    if(updated_well_order == undefined){
      updated_well_order = well_order.map(function(r){return r.files});
    }
    const save_url = '/lab-ops/ab-titration/add-or-update-titer/'+abtiter_id;
    var data = new FormData();
    data.set('well_ids', well_ids);
    data.set('parameterlabel_id', paramlabel.id);
    data.set('max_conc', max_conc);
    data.set('max_conc_unit', max_conc_unit)
    data.set('dilution', dilution);
    data.set('pos_celltype_id', pos_celltype);
    data.set('neg_celltype_id', neg_celltype);
    data.set('lotnum', lotnum);
    data.set('vendor', vendor);
    data.set('antibody', antibody);
    data.set('fluorophore', fluorophore);
    // max_well_first
    // data.set('compgating_spec_name', compgating_spec.value);
    data.set('n_rep', 1);
    data.set('well_order', JSON.stringify(updated_well_order));
    const res = await fetch(save_url, {
      method: 'POST',
      body: data,
    });
    if (res.ok){
      window.location.reload();
    }
  }

  function update_well_order(e){
    updated_well_order = e.detail;
    updated_well_order = updated_well_order[0].map(function(r){return r.files});
  }

</script>

<style>
  .container {
    background: white;
    padding: 1em;
    min-height: 700px;
    /* height: 640px; */
  }
  .hr{
    border-bottom: 1px solid gray;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .qcontainer {
    display: flex;
  }
  .left-column {
    padding-right: 10px;
  }

  .middle-column {
    display: flex;
    flex-direction: column;
  }

  .right-column {
    display: flex;
    flex-direction: column;
  }

  .image-row::after {
    content: "";
    clear: both;
    display: table;
  }
  .image-column {
    float: left;
    /* width: 33.33%; */
    /* padding-right: 5px; */
  }

  .layout {
    margin: 1em;
  }

  .step-title {
    font-weight: 900;
    font-size: 1.5em;
  }

  .horizontal {
    display: flex;
    justify-content: space-between;
  }

  .unit-column{
    display: flex;
  }

  .qog-button {
    height: 2em;
    margin-top: 10px;
    margin-left: 0;
  }

  .file-name {
    max-width: 50em;
  }

  .cycle-gating{
    text-align: center;
    padding-bottom: 20px;
  }

  .popup-modal {
    background: white;
    border-radius: 6px;
  }
  .inner-modal {
    margin:20px;
  }
</style>

<div class="modal is-active">
  <div class="modal-background"></div>
  <button on:click="{()=>{edit_titer_params = false;}}" class="modal-close is-large" aria-label="close"></button>
  <div class="modal-content">
    <div class="popup-modal">
      <div class="inner-modal">
        {#if selected_fcs_files !== null}
          <QTable bind:rows={selected_fcs_files} bind:columns={selected_fcs_col} />
        {:else}
          <QTable draggable_rows bind:rows={well_order} on:rowdragend={update_well_order} bind:columns={selected_fcs_col} />
        {/if}
        <div class="columns">
          <div class="column">
            <div class="horizontal">
              <SelectField label="Positive Celltype:" opts={celltypes} display_key="value" value_key="id" bind:value={pos_celltype} />
              <SelectField label="Negative Celltype:" opts={celltypes} display_key="value" value_key="id" bind:value={neg_celltype} />
            </div>
            <div class="horizontal">
              <SelectDataList style="width:150px" label="Target:" opts={antibodies} bind:value={antibody} />
              <SelectDataList style="width:150px" label="Fluorophore:" opts={fluorophores} bind:value={fluorophore} />
              <SelectField label="Detector" opts={paramlabels} display_key="parameter_name" bind:value={paramlabel} />
            </div>
            <div class="horizontal">
              <SelectDataList label="Vendor:" style="width:200px" opts={vendors} bind:value={vendor}/>
              <SelectDataList label="Lot Number" style="width:200px" opts={lots} bind:value={lotnum}/>
            </div>
            <div class="horizontal">
              <div class="unit-column">
                <InputField label="Max Concentration:" style="width:200px" bind:value={max_conc}/>
                <SelectField label="Unit" opts={units} bind:value={max_conc_unit} />  
              </div>
              <InputField label="Dilution" style="width:200px" bind:value={dilution}/>
            </div>
            <div class="horizontal">
              {#if selected_fcs_files !== null}
                <button on:click={(e)=>{save_titer_params()}} class="button is-black">Save</button>
              {:else}
                <button on:click={(e)=>{apply_edit_titration()}} class="button is-black">Save</button>
              {/if}
            </div>
          </div>
        </div>    
      </div>
    </div>
  </div>
</div>

{#if loading}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <Loading></Loading>
    </div>
  </div>
{/if}