<script context="module">
  let counter = 0;
</script>
<script>
    export let label;
    export let value;
    export let opts;
    let style = $$props.style;
    function update_value(e) {
      value = e.target.value;
    }
    let uniq_id = 'datalist_' + counter++;
</script>

<div class="field" style={style}>
    <label class="label">{label}</label>
    <input class="input" list={uniq_id} on:input={update_value} bind:value={value}/>
    <datalist id={uniq_id}>
      {#each opts as opt}
        <option value={opt} />
      {/each}
    </datalist>
</div>
<style>
</style>
