import HelloWorld from './HelloWorld.svelte';
import CruelWorld from './CruelWorld.svelte';
// import AnalysisPage from './AnalysisPage.svelte';
import ScatterPlot from './ScatterPlot.svelte';

import AnalysisPage from './AnalysisPage.svelte';
import CrossSampleAnalysisPage from './CrossSampleAnalysisPage.svelte';

import ClusterPage from './ClusterPage.svelte';
import ClusterSummaryPage from './ClusterSummaryPage.svelte';
import ABTitration from './ABTitration.svelte';
import InstrumentPage from './InstrumentPage.svelte';
import InstrumentQC8PeakPage from './InstrumentQC8PeakPage.svelte';

import QPlotContainer from './QPlotContainer.svelte';

window.HelloWorld = function(options) {
    return new HelloWorld(options);
};

window.CruelWorld = function(options) {
    return new CruelWorld(options);
};

window.ScatterPlot = function(options) {
    return new ScatterPlot(options);
};

window.AnalysisPage = function(options) {
  return new AnalysisPage(options);
}

window.CrossSampleAnalysisPage = function(options) {
  return new CrossSampleAnalysisPage(options);
}

window.ClusterPage = function(options){
  return new ClusterPage(options);
}


window.ClusterSummaryPage = function(options){
  return new ClusterSummaryPage(options);
}

window.ABTitration = function(options){
  return new ABTitration(options);
}

window.InstrumentPage = function(options){
  return new InstrumentPage(options);
}

window.InstrumentQC8PeakPage = function(options){
  return new InstrumentQC8PeakPage(options);
}


window.QPlotContainer = function(options){
  return new QPlotContainer(options);
}