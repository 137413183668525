<script>
  import Scatter from "./Scatter.svelte";
  // import Annotations from "./Annotations.svelte";
  import XAxis from './XAxis.svelte';
  import YAxis from './YAxis.svelte';
  import { LayerCake, Svg, WebGL } from 'layercake';
  import Selection from "./Selection.svelte";

  export let points;
  export let xDomain = undefined;
  export let yDomain = undefined;
  export let xticks = undefined;
  export let yticks = undefined;
  export let scatter_id = "scatter";
  export let re_render = false;

  let gate = null;
  let default_point_size = points[0].r;

  function update_points(e){
    points = points.map((p)=>{
      if(gate && p.x >= gate.min_x && p.x <= gate.max_x && p.y >= gate.min_y && p.y <= gate.max_y){
        p.r = 6;
      }
      else{
        p.r = default_point_size;
      }
      return p;
    })
    re_render = true;
  }
</script>

<div class="plot">
  <LayerCake padding={ { top: 0, right: 0, bottom: 20, left: 10 } }
             data={points}
             x='x'
             y='y'>
    <Svg>
      <XAxis
        gridlines={false}
      />
      <YAxis
        gridlines={false}
      />
    </Svg>
    <WebGL>
      <Scatter bind:re_render={re_render} />
    </WebGL>
    <Svg>
      <Selection on:endSelection on:selection={update_points} bind:gate={gate}></Selection>
    </Svg>
  </LayerCake>
</div>

<style>
  .plot{
    height: 740px;
    width: 740px;
  }
</style>
