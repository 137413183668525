<script>
  import { createEventDispatcher } from 'svelte';
  import GatingPlot from "./GatingPlot.svelte";
  import Loading from "./Loading.svelte";
  import Modal from "./Modal.svelte";
  export let plot_id;
  export let well_id;
  export let well_ids;
  export let page;
  let title;
  let gating_plot;
  let dispatch = createEventDispatcher();
  let plots;
  let loading = false;
  let save_warning = false;
  let error_message;

  async function get_plots(){
    loading = true;
    let url = "/lab-ops/gating-plots/"+well_id;
    if (plot_id){
      url += "/"+plot_id;
    }
    var response = await fetch(url);
    var json = await response.json();
    plots = json.plots;
    title = json.title;
    loading = false;
  }

  async function save_gating(save_to_all=false){
    if(save_to_all==true){
      var well_list = well_ids;
    }
    else{
      var well_list = [well_id];
    }
    var formData = new FormData();
    var save_plots = get_save_plots();
    formData.append("plots", JSON.stringify(save_plots));
    formData.append("well_ids", JSON.stringify(well_list));
    loading = true
    var response = await fetch("/lab-ops/save-gating", {
      method: "POST",
      body: formData
    });
    loading = false;
    save_warning = false;
    if(page == "instrument_qc"){
      get_plots();
    }
    dispatch("save-gating", []);
  }


  function get_save_plots(){
    return plots.map((p)=>{
        return {
          id: p.id,
          bounds: [p.xDomain, p.yDomain],
          regions: p.gates.map((g)=>{
            if (g.type === "Quad"){
              var rg = {
                  region_type: g.type,
                  name: g.region.map(r=>r.name).join(","),
                  display_name: g.region.map(r=>r.display_name),
                  vertices: [
                    [g.points[4].x, g.points[4].y],
                    [g.points[0].x, g.points[0].y],
                    [g.points[1].x, g.points[1].y],
                    [g.points[2].x, g.points[2].y],
                    [g.points[3].x, g.points[3].y]
                  ],
                  label_coord: g.region.map(r=>r.position)
              };
            }
            else if (g.type === "RectQuad"){
              var rg = {
                  region_type: g.type,
                  name: g.region.map(r=>r.name).join(","),
                  display_name: g.region.map(r=>r.display_name),
                  vertices: [[g.points[0].x, g.points[0].y], [0, g.points[0].y], [g.points[0].x, 1], [1, g.points[0].y], [g.points[0].x, 0]],
                  label_coord: g.region.map(r=>r.position)
              };
            }
            else{
              var rg = {
                  region_type: g.type,
                  name: g.region[0].name,
                  display_name: g.region[0].display_name,
                  vertices: g.points.map((p)=>{
                    return [p.x, p.y]
                  }),
                  label_coords: g.region.position
              }
            }

            return rg;
          })
        }
      });
  }

  function handle_zoom_reset(evt, plot){
    plot.image = plot.original_image;
    plot.xDomain = [0,1];
    plot.yDomain = [0,1];
    plots = plots;
  }


  async function handle_zoom(evt, plot){
    if(!plot.hasOwnProperty("original_image")){
      plot.original_image = plot.image;
      plot.original_xdomain = plot.xDomain;
      plot.original_ydomain = plot.yDomain;
    }
    if(evt.detail.hasOwnProperty("xDomain") && evt.detail.hasOwnProperty("yDomain")){
      var formData = new FormData();
      var bounds = [evt.detail.xDomain];
      if(plot.yaxis){
        bounds.push(evt.detail.yDomain);
      }
      else{
        bounds.push([0,1]);
      }
      formData.append("bounds", JSON.stringify(bounds));
      loading = true;
      var response = await fetch("/lab-ops/gating-plots/"+well_id+"/"+plot.id, {
        method: "POST",
        body: formData
      });
      var json = await response.json();
      plot.image = json.image;
      plot.xDomain = bounds[0];
      plot.yDomain = bounds[1];
      plots = plots;
      loading = false;
    }
  }


  $: well_id, get_plots();

</script>

<style>
  .right{
    text-align: right;
  }

  .gating-plot{
    width: 400px;
    height: 400px;
  }

  .column{
    padding: 35px;
    position: relative;
  }
</style>
{#if error_message}
    <div class="notification is-danger">
        <button on:click={(e)=>{error_message=null;}} class="delete"></button>
        {error_message}
    </div>
{/if}


{#if plots}
  <div class="subtitle">{title}</div>
  <div class="right">
    <button title="Save to All" on:click={()=>{save_warning=true;}} class="button qog-button">Save to All Wells</button>
    <button title="Save" on:click={()=>{save_gating(false);}} class="button qog-button"><i class="fas fa-save"></i></button>
  </div>
  <div class="columns is-desktop is-multiline">
  {#each plots as plot}
    <div class="column is-narrow">
      <div class="gating-plot">
        <GatingPlot on:zoom-end={(e)=>{handle_zoom(e,plot)}} on:reset={(e)=>{handle_zoom_reset(e,plot)}} bind:plot={plot} />
      </div>
    </div>
  {/each}
  </div>
{/if}

{#if save_warning}
  <Modal bind:show_modal={save_warning} >
      <div class="notification is-warning">
        <div class="title is-4">Apply this gating to {well_ids.length} well{well_ids.length === 1? '': 's'}?</div>
        <div class="warning-actions">
            <button on:click="{() => {save_warning=false;}}" class="button qog-button">Don't Save</button>
            <button on:click={()=>{save_gating(true);}} class="button qog-button">Save</button>
        </div>
      </div>
  </Modal>
{/if}

{#if loading}
  <Modal bind:show_modal={loading}>
      <Loading></Loading>
  </Modal>
{/if}
