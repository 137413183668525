<script>
  import { LayerCake, Svg, WebGL, Html } from 'layercake';
  import XAxis from './XAxis.svelte';
  import YAxis from './YAxis.svelte';
  import Annotations from './Annotations.svelte';
  import Selection from './Selection.svelte';
  import PolygonSelection from './PolygonSelection.svelte';
  import ScatterWebGL from "./Scatter.webgl.svelte";
  import ScatterSVG from "./Scatter.svg.svelte";
  import ToolTip from "./ToolTip.svelte";
  import LinePlot from "./LinePlot.svelte";
  import PlotLegend from "./PlotLegend.svelte";
  export let padding = { top: 0, right: 0, bottom: 20, left: 10 }
  export let data;
  export let x = "x";
  export let y = "y";
  export let xDomain = null;
  export let yDomain = null;
  export let xgridlines = false;
  export let ygridlines = false;
  export let xticks = null;
  export let xlabel = null;
  export let yticks = null;
  export let ylabel = null;
  export let xbaseline = false;
  export let ybaseline = false;
  export let annotations = null;
  export let selection = false;
  export let xformatTick = d => d;
  export let yformatTick = d => d;
  export let gates = [];
  export let plot_type = "canvas";
  export let labelField;
  export let num_gates = 1;
  export let lines = null;
  export let legend = null;

  let tooltipcomponet;
  let e = {}

  function handle_mousemove(evt){
    e = evt.detail[0];
  }
</script>

<div class="chart-container">
  <LayerCake {padding} bind:data {x} {y} {xDomain} {yDomain}>
    <Svg>
      <XAxis {xgridlines} {xticks} {xlabel} {xformatTick} {xbaseline} />
      <YAxis {ygridlines} {yticks} {ylabel} {yformatTick} {ybaseline} />
    </Svg>
    {#if legend}
      <Svg>
        <PlotLegend {...legend} />
      </Svg>
    {/if}
    {#if plot_type == "canvas"}
      <WebGL>
        <ScatterWebGL />
      </WebGL>
      {#if labelField}
        <Html>
          <ToolTip {e} bind:this={tooltipcomponet} {labelField} />
        </Html>
      {/if}
      <Svg>
      {#if lines}
        <LinePlot {lines} />
      {/if}
      {#if annotations}
        <Annotations on:movetext bind:annotations />
      {/if}
      {#if selection}
        <PolygonSelection {num_gates} on:selectionMousemove={handle_mousemove} bind:gates on:endSelection on:selection  />
      {/if}
      </Svg>

    {:else}
      <Svg>
        {#if lines}
          <LinePlot {lines} />
        {/if}
        <ScatterSVG on:click />
        {#if annotations}
          <Annotations on:movetext bind:annotations />
        {/if}
        {#if selection}
          <PolygonSelection {num_gates} bind:gates on:endSelection on:selection  />
        {/if}
      </Svg>
    {/if}

  </LayerCake>
</div>

<style>
  .chart-container{
    width: 100%;
    height: 100%;
  }
</style>
