<script>
  import AJAXFetch from "./AJAXFetch.svelte";
  import InputField from "./InputField.svelte";
  import QTable from "./QTable.svelte";
  import QSheet from "./QSheet.svelte";
  import SelectField from "./SelectField.svelte";
  import GatingPlotContainer from "./GatingPlotContainer.svelte";
  import AbTitrModal from "./ABTitrModal.svelte";
  import Loading from "./Loading.svelte";

  let current_state = "save";
  export let titration_data;
  let well_ids;

  let loading = false;
  let celltypes;
  let antibodies;
  let fluorophores;
  let vendors = [];
  let lots = [];
  let gating_options;
  let assay_name = "";
  let assay_name_list = [];
  let compgating_specs = [];
  let compgating_spec;
  $: compgating_spec, update_example_plots("compgating_spec", compgating_spec);
  let forward_scatter;
  $: forward_scatter, update_table_values("fsc", forward_scatter);
  let side_scatter;
  $: side_scatter, update_table_values("ssc", side_scatter);
  let titration_param;
  $: titration_param, update_table_values("titration_param", titration_param);

  let example_plots = [];
  let hierarchy_img;
  let edit_titer_params;
  let titration_params = [];
  let running = false;
  let num_wells = 1;
  let wells_ok = 0;
  let wells_failed = 0;
  $: progress = 100 * (wells_ok + wells_failed) / num_wells;
  $: if(progress == 100) {
    running = false;
    if(wells_failed == 0) {
      switch (current_state) {
        // case "setup_gating":
        //   review_indx = 0;
        //   current_state = "review_gating";
        //   break;
        case "review_gating":
          current_state = "save";
          break;
        case "save":
          window.location = "/lab-ops/ab-titration";
          break;
      }
    }
  }

  let assay_url = "/lab-ops/ab-titration/get-ab-assay-names"
  function parse_assay_names(e){
    e.detail.forEach(function(o){
      assay_name_list.push(o.value);
    })
  }

  let fcs_files = [];

  function update_example_plots(type, val){
    update_table_values(type, val);
    if(gating_options !== undefined) {
      if(gating_options[compgating_spec].length > 0) {
        hierarchy_img = 'data:image/png;base64, '+gating_options[compgating_spec][0].plot;
        var curr_plots = gating_options[compgating_spec].map(p => 'data:image/png;base64, '+p.plot);
        curr_plots.shift();
        example_plots = curr_plots;
      }
    }
  }

  function update_table_values(type, val){
    var fcs_table_list = [];
    if (well_ids !== undefined && forward_scatter !== undefined){
      if (document.getElementById(well_ids[0]+'_'+type+'_select')){
        well_ids.map(wid => fcs_table_list.push(wid+'_'+type+'_select'));
        fcs_table_list.map(v => document.getElementById(v).value = val);
      }
    }
  }

  let paramlabels;
  let paramlabel;

  let plates_url = '/api/plates';
  let plate_options;
  let formatted_plate_options;
  let selected_plate;
  function get_plates(e) {
    if(titration_data !== null){
      selected_plate = e.detail.find(e => e.id == titration_data.plate_id).name;
      plate_options = e.detail;
      formatted_plate_options = plate_options.map(r => r.name);
      get_well_table("edit");
    }
    plate_options = e.detail;
    formatted_plate_options = plate_options.map(r => r.name);
  }

  let well_table = {};
  let show_table = false;
  let paramlabel_id_mapping;
  let celltype_id_mapping;
  let exc_message;
  async function get_well_table(type=null) {
    loading = true;
    let selected_plate_id = plate_options.find(e => e.name == selected_plate).id;
    if(type == "edit"){
      let abtiter_id = titration_data.id;
      let well_table_url = '/lab-ops/ab-titration/well-details/'+selected_plate_id+'/'+abtiter_id;
      const res = await fetch(well_table_url, {
        method: 'GET',
      });
      const json = await res.json();
      well_table.columns = json.table.columns;
      well_table.rows = json.table.rows;
      well_table.coloptions = json.table.coloptions;
      paramlabel_id_mapping = json.paramlabel_id_mapping;
      celltype_id_mapping = json.celltype_id_mapping;
      exc_message = json.message;
      show_table = true;
      loading = false;
    } else {
      let well_table_url = '/lab-ops/ab-titration/well-details/'+selected_plate_id;
      const res = await fetch(well_table_url, {
        method: 'GET',
      });
      const json = await res.json();
      well_table.columns = json.table.columns;
      well_table.rows = json.table.rows;
      well_table.coloptions = json.table.coloptions;
      paramlabel_id_mapping = json.paramlabel_id_mapping;
      celltype_id_mapping = json.celltype_id_mapping;
      exc_message = json.message;
      show_table = true;
      loading = false;
    }
  }

  let antibody;
  let fluorophore;
  let pos_celltype;
  let neg_celltype;
  let max_conc;
  let dilution;
  let lotnum;
  let vendor;
  let units;
  let max_conc_unit;
  let updated_well_order;
  let validation_msg;
  let validation;
  async function save_titration() {

    var lots = Array.from(new Set(well_table.rows.map(r => r.lotnum)));
    lots = lots.filter(v => v!='');

    let filtered_rows = well_table.rows.filter(r => r.lotnum != '')
    validation = filtered_rows.every(r => 
      !Object.values(r).some(v => v === "")
    );
    if(filtered_rows.length === 0){
      validation = false;
    }
    if(validation) {
      let save_url;
      if(titration_data){
        save_url = '/lab-ops/ab-titration/add-or-update-titer/'+titration_data.id;
      } else {
        save_url = '/lab-ops/ab-titration/add-or-update-titer';
      }
      running = true;
      wells_ok = 0;
      wells_failed = 0;
      num_wells = 1;

      updated_well_order = filtered_rows.map(function(r){ return r.fcs_file_name});
      lots.map(async function(lot){
        var lot_group = filtered_rows.filter(r => r.lotnum == lot);
        lot_group.map(function(r){
          r["paramlabel_id"] = paramlabel_id_mapping[r.paramlabel];
          r["pos_celltype_id"] = celltype_id_mapping[r.pos_celltype];
          r["neg_celltype_id"] = celltype_id_mapping[r.neg_celltype];
        })
        var data = new FormData();
        data.set("well_dicts", JSON.stringify(lot_group));
        const res = await fetch(save_url, {
          method: 'POST',
          body: data,
        });
        const json = await res.json();
        if(json.status == "error") {
          validation = false;
          validation_msg = json.data;
          wells_failed += 1;
        } else {
          wells_ok += 1;
        }
      })
    } else {
      validation_msg = "Rows with lot numbers specified must have all other values specified."
    }
  }

  $: select_str2var = {
    "pos_celltype": pos_celltype,
    "neg_celltype": neg_celltype,
    "antibody": antibody,
    "fluorophore": fluorophore,
    "paramlabel": paramlabel,
    "vendor": vendor,
    "lotnum": lotnum,
    "max_conc_formatted": max_conc,
    "dilution": dilution
  }

  let selected_fcs_files = [];
  let selected_fcs_col = [{id: "files", value: "Selected FCS Files"}];
  let edit_button;
  let edit_well_table = false;
  let selected_rows = [];
  function open_edit_titer(){
    selected_rows = well_table.rows.filter(r => r.selected);
    var temp_rows = [];
    selected_rows.map(function(r){
      temp_rows.push({"files": r.fcs_file_name});
    });
    selected_fcs_files = temp_rows;
    antibodies.indexOf(selected_rows[0].antibody) == -1 ? antibodies.push(selected_rows[0].antibody) : "";
    fluorophores.indexOf(selected_rows[0].fluorophore) == -1 ? fluorophores.push(selected_rows[0].fluorophore) : "";
    paramlabels.indexOf(selected_rows[0].paramlabel) == -1 ? paramlabels.push(selected_rows[0].paramlabel) : "";
    vendors.indexOf(selected_rows[0].vendor) == -1 ? vendors.push(selected_rows[0].vendor) : "";
    lots.indexOf(selected_rows[0].lotnum) == -1 ? lots.push(selected_rows[0].lotnum) : "";
    antibody = selected_rows[0].antibody;
    fluorophore = selected_rows[0].fluorophore;
    vendor = selected_rows[0].vendor;
    lotnum = selected_rows[0].lotnum;
    dilution = selected_rows[0].dilution;
    updated_well_order = well_table.rows;
    updated_well_order = updated_well_order.map(function(r){return r.fcs_file_name});
    well_table.rows.sort((a, b) => updated_well_order.indexOf(a.fcs_file_name) - updated_well_order.indexOf(b.fcs_file_name));
    edit_titer_params = true;
  }

  let fcs_table = {
    columns: [
      {id: "fcs_file_name", value: "Fcs File"},
      {id: "fsc", value: "Forward Scatter"},
      {id: "ssc", value: "Side Scatter"},
      {id: "parameter", value: "Titration Parameter"},
      {id: "gating_type", value: "Gating Hierarchy"},
    ],
    rows: []
  }

  let n_rep = 1;

  $: n_rep < 1? n_rep = 1: null ; 

  var expanded = false;
  function expand_qsheet(){
    document.getElementById("qsheet-container").style.maxHeight = "";
    expanded = true;
    var cells = document.querySelectorAll("table td");
    for(var i=0; i<cells.length; i++){
      cells[i].style.whiteSpace = "normal";
    }
  }
  function collapse_qsheet(){
    document.getElementById("qsheet-container").style.maxHeight = "800px";
    expanded = false;
    var cells = document.querySelectorAll("table td");
    for(var i=0; i<cells.length; i++){
      cells[i].style.whiteSpace = "nowrap";
    }
  }

</script>

<style>
  .container {
    background: white;
    padding: 1em;
    min-height: 700px;
    /* height: 640px; */
  }
  .hr{
    border-bottom: 1px solid gray;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .qcontainer {
    display: flex;
  }

  .qrow {
    display: flex;
  }
  .left-column {
    padding-right: 10px;
  }

  .middle-column {
    display: flex;
    flex-direction: column;
  }

  .right-column {
    display: flex;
    flex-direction: column;
  }

  .image-row::after {
    content: "";
    clear: both;
    display: table;
  }
  .image-column {
    float: left;
    /* width: 33.33%; */
    /* padding-right: 5px; */
  }

  .layout {
    margin: 1em;
  }

  .step-title {
    font-weight: 900;
    font-size: 1.5em;
  }

  .horizontal {
    display: flex;
    justify-content: space-between;
  }

  .unit-column{
    display: flex;
  }

  .qog-button {
    height: 2em;
    margin-top: 10px;
    margin-left: 0;
  }

  .search-button {
    margin-top: 12px;
    margin-left: 10px;
  }

  .file-name {
    max-width: 50em;
  }

  .cycle-gating{
    text-align: center;
    padding-bottom: 20px;
  }

  .popup-modal {
    background: white;
    border-radius: 6px;
  }
  .inner-modal {
    margin:20px;
  }
</style>

<div class="container">
  {#if current_state === "upload_fcs"}
    <div class="layout">
      <div class="step-title">Step 1: Upload FCS and Gate</div>
      <div class="qcontainer">
        <AJAXFetch bind:url={assay_url} method="GET" on:fetched={parse_assay_names}>
          <span slot="success">
            <InputField label="Name:" style="width:200px;margin-bottom:10px;" filter="true" type="text" dropdown="true" options={assay_name_list} bind:value={assay_name} />
          </span>
        </AJAXFetch>
      </div>
      <div style="font-weight:700;color:#363636;">Upload FCS</div>
      <div class="file file-container">
        <label class="file-label">
          <input type="file" class="file-input" bind:files={fcs_files} multiple>
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">
              Select FCS files:
            </span>
          </span>
          {#if fcs_files.length > 0}
            <span class="file-name">
              {[...fcs_files].map(f => f.name).join(", ")}
            </span>
          {/if}
        </label>
      </div>
      {#if fcs_files.length > 0}
        <button class="qog-button" on:click={upload_fcs_files}>Upload {fcs_files.length} files</button>
      {/if}
      {#if running}
        <progress class="progress" value={progress} max="100">{progress}%</progress>
      {/if}
      {#if wells_failed > 0}
        <div>FCS Upload failed on {wells_failed} files</div>
      {/if}
      {#if progress == 100}
        <div class="hr"></div>
          <div class="qcontainer">
            <div class="left-column">
              <div>Hierarchy and Example Plots (Autogating will adjust gates for each well's distribution)</div>
            </div>
            <div class="right-column" style="margin-left:auto;margin-right:0;">
              <SelectField label="Gating Hierarchy Options:" orientation="horizontal" opts={compgating_specs} bind:value={compgating_spec} />
            </div>
          </div>
          <div class="qcontainer">
            <div class="left-column">
              <img style="height:200px;width:200px;" src={hierarchy_img} alt="Hierarchy">
            </div>
            <div class="middle-column">
              <div class="image-row">
                {#each example_plots as example_plot}
                  <div class="image-column">
                    <img style="height:200px;width:200px;" src={example_plot} alt="Example">
                  </div>
                {/each}
              </div>
            </div>
            <div class="right-column" style="margin-left:auto;margin-top:20px;margin-right:0;">
              <SelectField label="Forward Scatter:" orientation="horizontal" opts={titration_params} bind:value={forward_scatter} />
              <SelectField label="Side Scatter:" orientation="horizontal" opts={titration_params} bind:value={side_scatter} />
              <SelectField label="Titration Parameter:" orientation="horizontal" opts={titration_params} bind:value={titration_param} />
            </div>
          </div>
          <div class="hr"></div>
          <div style="max-height:750px;overflow:scroll;">
            <QTable id="fcs_table" bind:rows={fcs_table.rows} bind:columns={fcs_table.columns} />
          </div>
          <button class="qog-button" on:click={run_gating} >Next</button>
        <!-- </AJAXFetch>> -->
      {/if}
    </div>
  {/if}

  {#if current_state === "setup_gating"}
    {#if well_ids.length > 0}
      <div class="layout">
        {#if running}
          <progress class="progress" value={progress} max="100">{progress}%</progress>
        {/if}
        {#if wells_failed > 0}
          <div>Gating failed on {wells_failed} wells</div>
        {/if}
      </div>
    {/if}
  {/if}

  {#if current_state === "review_gating"}
      <div>
        <div class="step-title">Step 2: Review Gating</div>
        <div style="width:1200px;padding:20px;">
          <GatingPlotContainer bind:well_id_list={well_ids} />
        </div>
        <button class="qog-button" on:click={() => {wells_failed=0; progress=0;  progress=100}}>Review Complete</button>
      </div>
  {/if}

  {#if current_state === "save"}
      <div class="layout">
        <AJAXFetch url={plates_url} method="GET" on:fetched={get_plates}>
          <span slot="success">
            <div class="qrow">
              <div class="qcolumn">
                <div class="step-title">Save Titration</div>
              </div>
              {#if show_table == true}
                <div class="qcolumn">
                  <button class="qog-button" style="position:absolute;right:0;" on:click={save_titration} >Save</button>
                </div>
              {/if}
            </div>
            <div>Select plates to edit parameters on.</div>
            <div class="qrow">
              <div class="qcolumn">
                <SelectField labeless="true" opts={formatted_plate_options} bind:value={selected_plate} />
              </div>
              <div class="qcolumn">
                <button class="qog-button search-button" on:click={get_well_table} >Search</button>
              </div>
            </div>
            {#if validation==false}
              <div style="color:red">{validation_msg}</div>
            {/if}
            {#if show_table == true}
              <div class="column">
                {#if exc_message.length > 0}
                  <div style="color:red">{exc_message}</div>
                {/if}
                <div id="qsheet-container" style="max-height:800px;overflow:scroll;">
                  <QSheet bind:rows={well_table.rows} columns={well_table.columns} coloptions={well_table.coloptions} caption="Wells without lot numbers specified will be ignored for titrations."/>
                </div>
                {#if expanded == false}
                  <button class="qog-button" on:click={expand_qsheet}>Expand</button>
                {:else}
                  <button class="qog-button" on:click={collapse_qsheet}>Collapse</button>
                {/if}
              </div>
            <br />
            {#if running}
              <progress class="progress" value={progress} max="100">{progress}%</progress>
            {/if}
            {/if}
          </span>
        </AJAXFetch>
      </div>
  {/if}
</div>

{#if loading}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <Loading></Loading>
    </div>
  </div>
{/if}

{#if edit_titer_params}
  <AbTitrModal
    bind:well_table={well_table}
    select_str2var={select_str2var}
    selected_rows={selected_rows}
    bind:edit_button={edit_button}
    bind:edit_titer_params={edit_titer_params}
    bind:selected_fcs_files={selected_fcs_files}
    selected_fcs_col={selected_fcs_col}
    bind:celltypes={celltypes}
    bind:pos_celltype={pos_celltype}
    bind:neg_celltype={neg_celltype}
    bind:antibodies={antibodies}
    bind:antibody={antibody}
    bind:fluorophores={fluorophores}
    bind:fluorophore={fluorophore}
    bind:paramlabels={paramlabels}
    bind:paramlabel={paramlabel}
    bind:vendors={vendors}
    bind:vendor={vendor}
    bind:lots={lots}
    bind:lotnum={lotnum}
    bind:max_conc={max_conc}
    bind:units={units}
    bind:max_conc_unit={max_conc_unit}
    bind:dilution={dilution}
    bind:updated_well_order={updated_well_order}
  />
  <!-- <div class="modal is-active">
    <div class="modal-background"></div>
    <button on:click="{()=>{edit_titer_params = null;}}" class="modal-close is-large" aria-label="close"></button>
    <div class="modal-content">
      <div class="popup-modal">
        <div class="inner-modal">
          <div class="columns">
            <div class="column">
              <div class="horizontal">
                <SelectField label="Positive Celltype:" opts={celltypes} display_key="value" value_key="id" bind:value={pos_celltype} />
                <SelectField label="Negative Celltype:" opts={celltypes} display_key="value" value_key="id" bind:value={neg_celltype} />
              </div>
              <div class="horizontal">
                <SelectDataList style="width:150px" label="Target:" opts={antibodies} bind:value={antibody} />
                <SelectDataList style="width:150px" label="Fluorophore:" opts={fluorophores} bind:value={fluorophore} />
                <SelectField label="Detector" opts={paramlabels} display_key="parameter_name" bind:value={paramlabel} />
              </div>
              <div class="horizontal">
                <SelectDataList label="Vendor:" style="width:200px" opts={vendors} bind:value={vendor}/>
                <SelectDataList label="Lot Number" style="width:200px" opts={lots} bind:value={lotnum}/>
              </div>
              <div class="horizontal">
                <div class="unit-column">
                  <InputField label="Max Concentration:" style="width:200px" bind:value={max_conc}/>
                  <SelectField label="Unit" opts={units} bind:value={max_conc_unit} />  
                </div>
                <InputField label="Dilution" style="width:200px" bind:value={dilution}/>
              </div>
              <div class="horizontal">
                <button on:click={(e)=>{save_titer_params()}} class="button is-black">Save</button>
              </div>
            </div>
          </div>    
        </div>
      </div>
    </div>
  </div> -->
{/if}
