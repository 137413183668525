<script>
  import AJAXFetch from "./AJAXFetch.svelte";
  import InputField from "./InputField.svelte";
  import Modal from "./Modal.svelte";
  import QCRunAdd from "./QCRunAdd.svelte";
  import QCRunRegression from "./QCRunRegression.svelte";
  import QPlot from "./QPlot.svelte";
  import QTable from "./QTable.svelte";
  import SelectField from "./SelectField.svelte";
  import GatingController from "./GatingController.svelte";

  let qc_tbl_url = "/lab-ops/instrument-qc/get-instrument-qc-summary";
  let table;

  let instrument;
  let stats_tbl_url;
  let stats_table;

  let plots;
  let plot;
  let plots_url;
  $: if(instrument && instrument.id) {
    stats_tbl_url = "/lab-ops/instrument-qc/get-instrument-regr-stats/" + instrument.id;
    plots_url = "/lab-ops/instrument-qc/get-instrument-plots/" + instrument.id;
  }

  let selected_detector;
  $: if(selected_detector) {
    plot = plots[selected_detector];
  }

  let date_style="margin-left:1em; width:12em";

  async function download_qcrun_excel(qcrun_id) {
    var link = document.createElement('a');
    link.href = "/lab-ops/instrument-qc/export-qcrun-report/" + qcrun_id;
    link.click();
    link.remove();
  }

  async function delete_qcrun(qcrun_id) {
    let delete_url = "/lab-ops/instrument-qc/delete-instqcrun/" + qcrun_id;
    const res = await fetch(delete_url, {
      method: 'DELETE',
    });
    const json = await res.json();
    if(json.status == "success") {
      window.location.reload();
    }
  }

  let live_gating_wells;
  let gating_index;
  $: live_well_id = live_gating_wells && live_gating_wells.length > 0 ? live_gating_wells[gating_index] : null;
  let show_gating = false;
  async function get_gating_plots(index, well_ids){
    if(well_ids){
      live_gating_wells = well_ids;
    }
    gating_index = index % live_gating_wells.length;
    live_well_id = live_gating_wells[gating_index];
    show_gating = true;
  }

  $: if(!show_gating){live_gating_wells = null; gating_index= null; live_well_id=null;}

  let add_qcrun = false;
  let show_regression = false;
  let regression_qcrun_id;
</script>

<style>
  .table-container{
    max-height: 500px;
    overflow: scroll;
    margin-right: 5em;
    flex: 50%;
  }

  .container{
    display: flex;
    margin-top: 0.5em;
  }

  .plot-container{
    width: 95%;
    border: 1px solid grey;
    margin-left: 2em;
    margin-top: 3em;
  }

  .horizontal{
    display: flex;
    align-items: center;
    margin-left: 0.75em;
  }

  .qplot {
    height: 500px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .qog_banner_toolbar {
    display: flex;
    height: 46px;
  }

  .page-title {
    size: 1.5rem;
    margin-left:10em;
    margin-right: 10em;
    font-weight: 600;
  }

  .cycle-gating{
    text-align: center;
    padding-bottom: 20px;
  }

  .icon-grey{
    color: #7b7b7b;
  }
</style>

<div class="qog_banner_toolbar webix_toolbar webix_layout_toolbar">
  <div class="webix_list-x crumbs">
    <div class="webix_list_item"><a href='{window.location.pathname.split("/").splice(0, 2).join("/")}'>Lab Operations</a></div>
    <div class="webix_list_item"><a href='{window.location.pathname.split("/").splice(0, 2).join("/")+"/instrument-qc"}'>Instrument QC</a></div>
  </div>
  <div class="page-title">8-Peak Beads</div>
  <button class="qog-button" style="font-size:inherit">Baseline &amp; Limits</button>
  <button class="qog-button" style="font-size:inherit" on:click={() => add_qcrun=true}>Add QC Run</button>
</div>

<div class="container">
  <AJAXFetch bind:url={qc_tbl_url} method="GET" on:fetched={(e) => table = e.detail}>
    <span slot="success">
      <div class="table-container">
        <QTable
          rows={table.rows}
          columns={table.columns}
          span_rows={["Vendor", "Name", "Ser.Num."]}
          highlight_row
          highlight_on_hover
          >
          <span slot="actions" let:row>
            <span on:click={() => instrument=row}>
              <i class="fas fa-info-circle icon-grey" />
            </span>
          </span>
        </QTable>
      </div>
    </span>
  </AJAXFetch>

  {#if instrument}
    <AJAXFetch url={stats_tbl_url} method="GET" on:fetched={(e) => stats_table = e.detail}>
      <span slot="success">
        <div class="table-container">
          <QTable
            rows={stats_table.rows}
            columns={stats_table.columns}
            title={stats_table.title}
            sticky_headers=true
            highlight_row
            >
            <span slot="actions" let:row>
              <span title="Regression Details" on:click={() => {regression_qcrun_id = row.id; show_regression = true}}>
                <i class="fas fa-info-circle icon-grey" />
              </span>
              <span title="Gating Plots" on:click={() => {live_gating_wells = row.well_ids.split(","); gating_index=0; show_gating=true}}>
                <i class="fas fa-edit icon-grey" />
              </span>
              <span title="Export To Excel" on:click={() => download_qcrun_excel(row.id)}>
                <i class="fas fa-file-excel icon-grey" />
              </span>
              <span title="Delete" on:click={() => delete_qcrun(row.id)}>
                <i class="fas fa-trash icon-grey" />
              </span>
          </span>
          </QTable>
        </div>
      </span>
    </AJAXFetch>
  {/if}
</div>

{#if instrument}
  <AJAXFetch url={plots_url} method="GET" on:fetched={(e) => {plots = e.detail; selected_detector=Object.keys(plots)[0]}}>
    <span slot="success">
      <div class="plot-container">
        {#if stats_table}
          <div style="font-weight: 600; margin-left:0.75em; margin-bottom:1em">{stats_table.title}</div>
        {/if}
        <div class="horizontal">
          <SelectField label="Detector:" opts={Object.keys(plots)} bind:value={selected_detector} />
<!--           <InputField label="Start Date:" type="date" style={date_style} />
          <InputField label="End Date:" type="date" style={date_style} />
          <span title="Download Image" style="font-size:1.5em; margin-left:2em"><i class="fas fa-download" /></span> -->
        </div>
        {#if plot}
          <div class="qplot">
            <QPlot
              bind:data={plot.points}
              xDomain={plot.xDomain}
              yDomain={plot.yDomain}
              xticks={plot.xticks}
              yticks={plot.yticks}
              xformatTick={(d)=>{return plot.xticklabels[plot.xticks.indexOf(d)]}}
              yformatTick={(d)=>{return plot.yticklabels[plot.yticks.indexOf(d)]}}
              lines={plot.lines}
              xgridlines
              ygridlines
              plot_type="svg"
              on:click={(e)=>{live_gating_wells = [e.detail[0].well_id]; gating_index=0; show_gating=true}}
              />
          </div>
        {/if}
      </div>
    </span>
  </AJAXFetch>
{/if}

{#if live_gating_wells}
  <Modal style="background:white;width:1000px;padding-bottom:50px;padding-top:50px;" bind:show_modal={show_gating}>
    {#if live_gating_wells.length > 1}
      <div class="cycle-gating">
        <button title="Previous" disabled={gating_index == 0} on:click={() => gating_index -= 1} class="button qog-button"><i class="fas fa-chevron-left"></i></button>
        <button title="Next" disabled={gating_index == live_gating_wells.length-1} on:click={() => gating_index += 1} class="button qog-button"><i class="fas fa-chevron-right"></i></button>
      </div>
    {/if}
    <GatingController bind:well_id={live_well_id} />
  </Modal>
{/if}

{#if add_qcrun}
  <Modal bind:show_modal={add_qcrun} style="width:1000px">
    <QCRunAdd />
  </Modal>
{/if}

{#if show_regression}
  <Modal bind:show_modal={show_regression} style="width:1200px">
    <QCRunRegression qcrun_id={regression_qcrun_id}/>
  </Modal>
{/if}
