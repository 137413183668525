<script>
  import reglWrapper from 'regl';
  import { getContext } from 'svelte';
  import { scaleCanvas } from 'layercake';

  const { data, xGet, yGet, width, height } = getContext('LayerCake');

  export let diameter = 6;
  export let default_color = [0, 0, 1.0];


  const { gl } = getContext('gl');

  function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16) / 255,
      parseInt(result[2], 16) / 255,
      parseInt(result[3], 16) / 255
    ] : null;
  }

  function getFill(fill){
    if (typeof fill === "string") return hexToRgb(fill);
    return fill;
  }

  function resize () {
    if ($gl) {
      const canvas = $gl.canvas;
      // Lookup the size the browser is displaying the canvas.
      const displayWidth = canvas.clientWidth;
      const displayHeight = canvas.clientHeight;

      // Check if the canvas is not the same size.
      if (canvas.width !== displayWidth || canvas.height !== displayHeight) {
        // Make the canvas the same size
        canvas.width = displayWidth;
        canvas.height = displayHeight;
      }
      $gl.viewport(0, 0, canvas.width, canvas.height);
    }
  }

  let regl;

  function render () {
    if ($gl) {
      regl = reglWrapper({
        gl: $gl,
        extensions: ['oes_standard_derivatives']
      });

      // console.log('rendering', regl);
      regl.clear({
        color: [0, 0, 0, 0],
        depth: 1
      });

      const draw = regl({
        // circle code comes from:
        // https://www.desultoryquest.com/blog/drawing-anti-aliased-circular-points-using-opengl-slash-webgl/
        frag: `
        #extension GL_OES_standard_derivatives : enable
        precision mediump float;
        // uniform vec3 fill_color;
        // varying vec3 fill_color;
        // uniform vec3 stroke_color;
        varying float s_s;
        varying vec3 f_c;
        varying vec3 s_c;
        varying float t;
        void main () {

          vec2 cxy = 2.0 * gl_PointCoord - 1.0;

          float dist = dot(cxy, cxy);

          float delta = fwidth(dist);

          float alpha = 1.0 - smoothstep(1.0 - delta, 1.0 + delta, dist);

          float outer_edge_center = 1.0 - s_s;
          float stroke = 1.0 - smoothstep(outer_edge_center - delta, outer_edge_center + delta, dist);

          // gl_FragColor = vec4(f_c,1.0) * alpha;
          gl_FragColor = vec4( mix(s_c, f_c, stroke), t ) * alpha;
          // gl_FragColor = vec4( mix(stroke_color, gl_FillColor, stroke), 1.0 ) * alpha;
          gl_FragColor.rgb *= gl_FragColor.a;
        }`,
        vert: `
        precision mediump float;
        attribute vec2 position;
        attribute float r;
        attribute float stroke_size;
        attribute vec3 stroke_color;
        attribute vec3 fill_color;
        attribute float opacity;

        varying float s_s;
        varying vec3 f_c;
        varying vec3 s_c;
        varying float t;

        uniform float stage_width;
        uniform float stage_height;

        // http://peterbeshai.com/beautifully-animate-points-with-webgl-and-regl.html
        vec2 normalizeCoords(vec2 position) {
          // read in the positions into x and y vars
          float x = position[0];
          float y = position[1];
          return vec2(
            2.0 * ((x / stage_width) - 0.5),
            // invert y to treat [0,0] as bottom left in pixel space
            -(2.0 * ((y / stage_height) - 0.5))
          );
        }

        void main () {
          s_s = stroke_size;
          f_c = fill_color;
          s_c = stroke_color;
          t = opacity;
          gl_PointSize = r;
          gl_Position = vec4(normalizeCoords(position), 0.0, 1.0);
          // gl_FillColor = fill_color;
        }`,
        attributes: {
          // There will be a position value for each point
          // we pass in
          position: (context, props) => {
            return props.points.map(point => {
              return [$xGet(point), $yGet(point)];
            });
          },
          r: (context, props) => {
            // const m = window.devicePixelRatio > 1 ? 4.0 : 2.0
            // If using an r-scale, set width here
            return props.points.map(point => {
              if(point.hasOwnProperty("r")) return point.r;
              return props.pointWidth;
            });
          },
          stroke_color: (context, props) =>{
            return props.points.map(point => {
              if(point.hasOwnProperty("fill")) return getFill(point.fill);
              return [0, 0, 0];
            });
          },
          stroke_size: (context, props) => {
            // If using an r-scale, set width here
            return props.points.map(point => 0);
          },
          fill_color: (context, props) =>{
            return props.points.map(point => {
              if(point.hasOwnProperty("fill")) return getFill(point.fill);
              return default_color;
            });
          },
          opacity: (context, props) =>{
            return props.points.map(point=>{
              if(point.hasOwnProperty("opacity")) return point.opacity;
              return 1.0;
            })
          }
        },
        uniforms: {
          // fill_color: [44/255, 130/255, 201/255],
          // stroke_color: [0.6705882352941176, 0, 0.8392156862745098],
          // stroke_color: [0, 0, 0],
          // FYI: there is a helper method for grabbing
          // values out of the context as well.
          // These uniforms are used in our fragment shader to
          // convert our x / y values to WebGL coordinate space.
          stage_width: regl.context('drawingBufferWidth'),
          stage_height: regl.context('drawingBufferHeight')
        },
        count: (context, props) => {
          // set the count based on the number of points we have
          return props.points.length;
        },
        primitive: 'points',
        blend: {
          enable: true,
          func: {
            srcRGB: 'src alpha',
            srcAlpha: 'src alpha',
            dstRGB: 'one minus src alpha',
            dstAlpha: 'one minus src alpha'
          }
        },
        depth: { enable: false }
      });

      draw({
        pointWidth: diameter,
        points: $data
      });
    }
  }

  $: ($width, $height, $gl, $data, resize(), render());
</script>
