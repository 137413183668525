<script>
  import SortableList from 'svelte-sortable-list';
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  // fires events row-clicked, cell-clicked, selection-changed

  export let title = "";
  export let footnotes = "";
  export let columns;
  export let rows;
  export let max_rows = 1000;
  export let highlight_on_hover = false;


  export let highlight_row = null;

  export let sticky_headers = false;
  export let resize_headers = false;
  export let draggable_headers = true;
  export let draggable_rows = false;
  //Pass a custom header class if needed
  export let header_class = "";
  // Rotate Header -- its funky: use 180deg for vertical, 225deg for 45degree
  export let rotate_header_angle = "";
  $: rotate_header = rotate_header_angle != "";

  const SLOTS = $$props.$$slots;
  let show_actions = SLOTS && SLOTS.actions;
  let show_title_slot = SLOTS && SLOTS.title;

  // Global and per column search
  export let global_searchable_columns = [];
  export let searchable_columns = [];
  let gsearch_str = "";
  let colsearch_str;
  // let colsearch_str = Object.fromEntries(searchable_columns.map(sc => [sc, ""]));
  $: searchable_columns, update_colsearch();

  function update_colsearch(){
    colsearch_str = Object.fromEntries(searchable_columns.map((sc) => {
      if(Array.isArray(sc)){
        return sc;
      }
      else{
        return [sc, ""]
      }
    }));
  }
//  let timer;
//  const debounced_update_filtered_rows = () => {
//    clearTimeout(timer);
//    timer = setTimeout(() => {
//      update_filtered_rows();
//    }, 250);
//  }
  let filtered_rows;
  $: rows, update_filtered_rows();
  let displayed_rows;
  $: filtered_rows, sort_column, sort_asc, update_displayed_rows();

  function update_filtered_rows() {
    var res_rows = rows;
    const search_str = gsearch_str.trim().toLowerCase();
    if(search_str != "") {
      res_rows = res_rows.filter(row => global_searchable_columns.some(col => row[col].toLowerCase().indexOf(search_str) !== -1));
    }
    for (const [col, value] of Object.entries(colsearch_str)) {
      const search_str = value.trim().toLowerCase();
      if(search_str !== "") {
        res_rows = res_rows.filter(row => {
          if(row[col].hasOwnProperty("text")){
            return row[col].text.toLowerCase().indexOf(search_str) !== -1
          }
          else{
            return row[col].toLowerCase().indexOf(search_str) !== -1
          }

        });
      }
    }
    filtered_rows = res_rows;
  }

  function update_displayed_rows() {
    displayed_rows = filtered_rows.slice(0).sort(sorter);
    dispatch("rows-filtered", [displayed_rows, colsearch_str])
  }

  // Fire events on row & cell click
  function cell_clicked(e, row, column) {
    dispatch("row-clicked", row);
    dispatch("cell-clicked", [row, column]);
  }

  function header_clicked(e, column){
    dispatch("header-clicked", [column, e.target])
  }

  // sort by column
  // of the form ["column1", "column2"]
  export let sortable_columns = [];
  let sort_column = null;
  let sort_asc = null;
  $: show_sort = sortable_columns.length > 0;
  function set_sort_column(colname) {
    if(sort_column == colname) {
      sort_asc = sort_asc == null ? true : !sort_asc;
    } else {
      sort_column = colname;
      sort_asc = true;
    }
  }
  function sorter(a, b) {
    const mult = sort_asc ? 1 : -1;
    var aval = a[sort_column];
    var bval = b[sort_column];
    if(aval && aval.hasOwnProperty("text")){
      aval = aval.text
    }
    if(bval && bval.hasOwnProperty("text")){
      bval = bval.text
    }
    if(!isNaN(aval) && !isNaN(bval)){
      return (aval-bval)*mult;
    }
    if(aval < bval) {
      return -1 * mult;
    }
    if(aval > bval) {
      return 1 * mult;
    }
    return 0;
  }

  // Checkbox related
  export let show_checkboxes = false;
  $: select_all_checked = displayed_rows.every(row => row.selected);
  $: select_all_indeterminate = !select_all_checked && displayed_rows.some(row => row.selected);
  function select_all(e) {
    const checked = e.target.checked;
    displayed_rows.forEach(row => row.selected = checked);
    dispatch("selection-changed");
    displayed_rows = displayed_rows;
  }
  function select_row(row) {
    dispatch("selection-changed", row);
    displayed_rows = displayed_rows;
  }

  // Column selection
  // of the form {'column1': false, 'column2': true}
  export let togglable_columns = {};
  $: show_column_toggle = Object.keys(togglable_columns).length > 0;
  $: displayed_columns = columns.filter(c => !(togglable_columns.hasOwnProperty(c.id) && togglable_columns[c.id]));
  function toggle_column_display(colname) {
    if(togglable_columns.hasOwnProperty(colname)) {
      togglable_columns[colname] = !togglable_columns[colname];
      columns = columns;
    }
  }
  function rearrange_columns(e) {
    columns = e.detail;
    dispatch("coldragend", [columns])
  }

  // Create row spans automatically for specific columns
  export let span_rows = []; // Column names on which to row span
  function is_in_rowspan(r, c) {
    const colname = displayed_columns[c].id;
    if(!span_rows.includes(colname) || r == 0) return false;
    const prev = displayed_rows[r-1][colname];
    const curr = displayed_rows[r][colname];
    return cells_equal(prev, curr);
  }
  function cells_equal(c1, c2) {
    if(c1.hasOwnProperty("text") && c2.hasOwnProperty("text")) {
      return c1.text == c2.text;
    }
    return c1 == c2;
  }
  function rowspan_for(r, c) {
    const colname = displayed_columns[c].id;
    if(!span_rows.includes(colname)) return null;
    const curr = displayed_rows[r][colname];

    var j = r + 1;
    while(j <= rows.length-1) {
      const next = displayed_rows[j][colname];
      if(!cells_equal(curr, next)) break;
      j++;
    }
    const span = j - r;
    return span == 1 ? null : span;
  }

  $: caption = title != "" || show_title_slot || show_column_toggle || global_searchable_columns.length > 0;

  // Drag and drop columns
  let columns_placeholder;
  let col_being_dragged;
  function coldragstart(event, col) {
    columns_placeholder = columns.slice(0);
    col_being_dragged = col;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';
    // Not using set data -- because we need access during dragenter which setdata doesnt allow
    //const start_idx = columns.findIndex(c => c.id == col.id);
    //event.dataTransfer.setData('text/plain', start_idx);
  }

  function coldragend(event) {
    if(event.dataTransfer.dropEffect == "none") {
      columns = columns_placeholder;
    }
    dispatch("coldragend", [columns])
  }

  function coldrop(event, col) {
    event.dataTransfer.dropEffect = 'move';
    var new_columns = columns.slice(0); // Cannot use columns_placeholder here
    const start_idx = new_columns.findIndex(c => c.id == col_being_dragged.id);
    const end_idx = new_columns.findIndex(c => c.id == col.id);
    if(start_idx < end_idx) {
      new_columns.splice(end_idx+1, 0, new_columns[start_idx]);
      new_columns.splice(start_idx, 1);
    } else {
      new_columns.splice(end_idx, 0, new_columns[start_idx]);
      new_columns.splice(start_idx+1, 1);
    }
    columns = new_columns;
  }

  // drag and drop rows
  let rows_placeholder;
  let row_being_dragged;
  function rowdragstart(event, row_idx) {
    rows_placeholder = displayed_rows.slice(0);
    row_being_dragged = row_idx;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';
  }

  function rowdragend(event) {
    if(event.dataTransfer.dropEffect == "none") {
      displayed_rows = rows_placeholder;
    }
    dispatch("rowdragend", [displayed_rows])
  }

  function rowdrop(event, row_idx) {
    event.dataTransfer.dropEffect = 'move';
    var new_rows = rows_placeholder.slice(0);
    const start_idx = row_being_dragged;
    const end_idx = row_idx;
    if(start_idx < end_idx) {
      new_rows.splice(end_idx+1, 0, new_rows[start_idx]);
      new_rows.splice(start_idx, 1);
    } else {
      new_rows.splice(end_idx, 0, new_rows[start_idx]);
      new_rows.splice(start_idx+1, 1);
    }
    displayed_rows = new_rows;
  }

</script>

<table class:resize_headers class:sticky_headers class="table is-fullwidth">
  {#if caption}
    <caption>
      <span class="level">
        <span class="level-left">
          {#if show_column_toggle}
            <div class="level-item dropdown is-hoverable is-left">
              <div class="dropdown-trigger">
                &nbsp;
                &nbsp;
                <i class="fas fa-cog"></i>
                &nbsp;
                &nbsp;
              </div>
              <div class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <SortableList list={columns} key="id" on:sort={rearrange_columns} let:item>
                    <div style="cursor: move" class="dropdown-item">
                      {#if togglable_columns.hasOwnProperty(item.id)}
                        <i style="cursor: pointer" on:click|stopPropagation={toggle_column_display(item.id)} class={togglable_columns[item.id] ? "far fa-square": "fas fa-check-square"}></i>
                      {:else}
                        <i style="opacity: 0.3" class="fas fa-check-square"></i>
                      {/if}
                      &nbsp;
                      <span>{item.value.replace(/<\/?[^>]+(>|$)/g, "")}</span>
                    </div>
                  </SortableList>
                </div>
              </div>
            </div>
          {/if}
          {@html title}
          {#if show_title_slot}
            <slot name="title"></slot>
          {/if}
        </span>
        <span class="level-right">
          {#if global_searchable_columns.length > 0}
            <div class="level-item">
              <p class="control">
                <input class="input" placeholder="Search" bind:value={gsearch_str} on:input={update_filtered_rows} />
              </p>
            </div>
          {/if}

        </span>
      </span>
    </caption>
  {/if}
  <thead>
    <tr>
      {#if draggable_rows}
        <th>&nbsp;</th>
      {/if}
      {#if show_checkboxes}
        <th style="--rotate-header-angle: {rotate_header_angle}; vertical-align: bottom;" class="center">
          <input id="select-all" type=checkbox indeterminate={select_all_indeterminate} checked={select_all_checked} on:change={select_all} />
        </th>
      {/if}
      {#each displayed_columns as column}
      {#if column.style}
        <th style={column.style} class:rotate_header on:click={(e)=>{header_clicked(e, column)}} on:dragstart={e => coldragstart(e, column)} ondragover="return false" on:dragenter={e => coldrop(e, column)} on:dragend={coldragend} on:drop={e => coldrop(e, column)} class:draggable-ew={draggable_headers} draggable={draggable_headers} class={header_class}>
        <!-- <th style="--rotate-header-angle: {rotate_header_angle}" class:rotate_header on:click={(e)=>{header_clicked(e, column)}} on:dragstart={e => coldragstart(e, column)} ondragover="return false" on:dragenter={e => coldrop(e, column)} on:dragend={coldragend} on:drop={e => coldrop(e, column)} class:draggable-ew={draggable_headers} draggable={draggable_headers} class={header_class}> -->
            <span class:rotate_header>{@html column.value}</span>
          {#if show_sort && sortable_columns.includes(column.id)}
            <span on:click={set_sort_column(column.id)}>
              {#if sort_column == column.id}
                {#if sort_asc != null && !sort_asc}
                  <i class="i-sort active fas fa-sort-down"></i>
                {:else}
                  <i class="i-sort active fas fa-sort-up"></i>
                {/if}
              {:else}
                <i class="i-sort fas fa-sort"></i>
              {/if}
            </span>
          {/if}
          {#if colsearch_str.hasOwnProperty(column.id)}
            <br />
            <input class="colsearch" bind:value={colsearch_str[column.id]} on:input={update_filtered_rows} />
          {/if}
        </th>
        {:else}
          <th style="--rotate-header-angle: {rotate_header_angle}" class:rotate_header on:click={(e)=>{header_clicked(e, column)}} on:dragstart={e => coldragstart(e, column)} ondragover="return false" on:dragenter={e => coldrop(e, column)} on:dragend={coldragend} on:drop={e => coldrop(e, column)} class:draggable-ew={draggable_headers} draggable={draggable_headers} class={header_class}>
              <span class:rotate_header>{@html column.value}</span>
            {#if show_sort && sortable_columns.includes(column.id)}
              <span on:click={set_sort_column(column.id)}>
                {#if sort_column == column.id}
                  {#if sort_asc != null && !sort_asc}
                    <i class="i-sort active fas fa-sort-down"></i>
                  {:else}
                    <i class="i-sort active fas fa-sort-up"></i>
                  {/if}
                {:else}
                  <i class="i-sort fas fa-sort"></i>
                {/if}
              </span>
            {/if}
            {#if colsearch_str.hasOwnProperty(column.id)}
              <br />
              <input class="colsearch" bind:value={colsearch_str[column.id]} on:input={update_filtered_rows} />
            {/if}
          {/if}
      {/each}
      {#if show_actions}
        <th class="center">
        </th>
      {/if}
    </tr>
  </thead>
  <tbody>
    {#each displayed_rows as row, r}
      {#if r < max_rows}
        <tr class:highlight_on_hover class:highlight-row={highlight_row != null && row.id == highlight_row.id} class:selected={row.selected}>
          {#if draggable_rows}
            <td draggable=true on:dragstart={e => rowdragstart(e, r)} ondragover="return false" on:dragenter={e => rowdrop(e, r)} on:dragend={rowdragend} on:drop={e => rowdrop(e, r)} class:draggable-ns={draggable_rows} ><i class="fas fa-grip-vertical"></i></td>
          {/if}
          {#if show_checkboxes}
            <td class="center">
              <input type=checkbox bind:checked={row.selected} on:change={() => select_row(row)} />
            </td>
          {/if}
          {#each displayed_columns as column, c}
            {#if !is_in_rowspan(r, c)}
              <td title={row[column.id].hasOwnProperty("title")? row[column.id].title: ""}  rowspan={rowspan_for(r, c)} style={row[column.id].hasOwnProperty("style")? row[column.id].style:""} on:click={(e) => cell_clicked(e, row, column)}>
                {@html row[column.id].hasOwnProperty("text") ? row[column.id].text : row[column.id]}
              </td>
            {/if}
          {/each}
          {#if show_actions}
            <td style="cursor:pointer;">
              <slot name="actions" {row}></slot>
            </td>
          {/if}
        </tr>
      {/if}
    {/each}
  </tbody>
  {#if footnotes != ""}
    <tfoot>{@html footnotes}</tfoot>
  {/if}
</table>

<style>
  .center {
    text-align: center;
    vertical-align: middle;
  }

  .input {
    margin: 0;
  }

  tr.highlight_on_hover:hover {
    background-color: var(--ryvett-light-grey-blue-highlighter);
  }

  tr.highlight-row {
    background-color: var(--ryvett-light-grey-blue-highlighter);
  }

  .dropdown-item {
    text-align:left;
  }
  .dropdown-item:hover {
    color: white;
    background-color: var(--purple-b-table-lines);
  }
  .i-sort {
    opacity: 0.2;
    cursor: pointer;
  }
  .i-sort.active {
    opacity: 1.0;
  }

  .sticky_headers.table {
    text-align: left;
    position: relative;
  }

  .sticky_headers th {
    background: rgba(255, 255, 255, 0.9);
    position: sticky;
    top: 0;
  }

  th.rotate_header {
    vertical-align: bottom;
    padding-left: 2.5em;
  }

  span.rotate_header {
    writing-mode: vertical-rl;
    transform: rotate(var(--rotate-header-angle));
  }

  .resize_headers thead th {
    resize: horizontal;
    overflow: auto;
    vertical-align: baseline;
  }

  .draggable-ew {
    cursor: grab;
  }

  .draggable-ns {
    cursor: grab;
    opacity: 30%;
  }

  .draggable-ns:hover {
    opacity: 100%;
  }

  input.colsearch{
    width: 100%;
  }

</style>
