<script>
  import { getContext, onMount, createEventDispatcher } from 'svelte';
  const {xGet, yGet, xScale, yScale} = getContext('LayerCake');
  export let lines = [];
  let g;

  let r = 3;
  let default_fill = [0, 0, 1, 1];

  function componentToHex(c) {
    c = c*255;
    var hex = c.toString(16).split(".")[0];
    return hex.length == 1 ? "0" + hex : hex;
  }


  function get_fill(d){
    var fill = d.hasOwnProperty("fill")? d.fill: default_fill;
    if(typeof fill === "string") return fill;
    return "#" + componentToHex(d.fill[0]) + componentToHex(d.fill[1]) + componentToHex(d.fill[2]) + (d.hasOwnProperty("opacity")? componentToHex(d.opacity): "FF");
  }

  function get_stroke(d){
    return d.hasOwnProperty("width")? d.width: (d.hasOwnProperty("r")? d.r: r);
  }
</script>

<g bind:this={g}>
  {#each lines as l}
    {#if l.linetype == 'dashed'}
      <line stroke-dasharray="3,3" x1='{ $xScale(l.x1) }' y1='{ $yScale(l.y1) }' x2='{ $xScale(l.x2) }' y2='{ $yScale(l.y2) }' fill={get_fill(l)} stroke={get_fill(l)} stroke-width={get_stroke(l)} />
    {:else}
      <line x1='{ $xScale(l.x1) }' y1='{ $yScale(l.y1) }' x2='{ $xScale(l.x2) }' y2='{ $yScale(l.y2) }' fill={get_fill(l)} stroke={get_fill(l)} stroke-width={get_stroke(l)} />
    {/if}
  {/each}
</g>

<style>

</style>
