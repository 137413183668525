<script>
  import { getContext } from 'svelte';

  const { width, height, xScale, yScale, padding } = getContext('LayerCake');
  export let xgridlines = false;
  export let xformatTick = d => d;
  export let xbaseline = false;
  export let snapTicks = false;
  export let xticks = undefined;
  export let xlabel = undefined;

  $: tickVals = Array.isArray(xticks) ? xticks : $xScale.ticks(xticks);

  function textAnchor(i) {
    if (snapTicks === true) {
      if (i === 0) {
        return 'start';
      }
      if (i === tickVals.length - 1) {
        return 'end';
      }
    }
    return 'middle';
  }
</script>

<g class='axis x-axis'>
  {#each tickVals as tick, i}
    <g class='tick tick-{ tick }' transform='translate({$xScale(tick)},{$yScale.range()[0]})'>
      {#if xgridlines !== false}
        <line y1='{$height * -1}' y2='0' x1='0' x2='0'></line>
      {/if}
      <text y='16' text-anchor='{textAnchor(i)}'>{xformatTick(tick)}</text>
    </g>
  {/each}
  {#if xbaseline === true}
    <line class="baseline" y1='{$height + 0.5}' y2='{$height + 0.5}' x1='0' x2='{$width}'></line>
  {/if}
  {#if xlabel}
    <text y='{$height + $padding.bottom*3/4}' x='50%' text-anchor='middle'>{xlabel}</text>
  {/if}
</g>

<style>
  .tick {
    font-size: .725em;
    font-weight: 200;
  }

  line,
  .tick line {
    stroke: #aaa;
    stroke-dasharray: 2;
  }

  .tick text {
    fill: #666;
  }

  .baseline {
    stroke: #000;
    stroke-width: 2;
    stroke-dasharray: 0;
  }
</style>
