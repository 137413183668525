<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  // will fire opened and closed events

  export let label = "";
  export let showDropDown = false;
  $: showDropDown ? dispatch("opened") : dispatch("closed");

  function clickOutside(node, { enabled: initialEnabled, cb }) {
    const handleOutsideClick = ({ target }) => {
      if (!node.contains(target)) {
        cb();
      }
    };

    function update({enabled}) {
      if (enabled) {
        window.addEventListener('click', handleOutsideClick);
      } else {
        window.removeEventListener('click', handleOutsideClick);
      }
    }

    update({ enabled: initialEnabled });
    return {
      update,
      destroy() {
        window.removeEventListener('click', handleOutsideClick);
      }
    };
  }

</script>

<div class="dropdown" class:is-active={showDropDown} use:clickOutside={{enabled: showDropDown, cb: () => showDropDown=false}}>
  <div class="dropdown-trigger">
    <button on:click={() => showDropDown = !showDropDown} class="button" aria-haspopup="true" aria-controls="dropdown-menu">
      <span>{label}</span>
      <span class="icon is-small">
        <i class="fas fa-angle-down" aria-hidden="true"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu" id="dropdown-menu" role="menu">
    <div class="dropdown-content">
      <slot />
    </div>
  </div>
</div>

<style>
  .dropdown-content{
    padding: 10px;
    max-height: 500px;
    overflow:scroll;
  }
</style>
