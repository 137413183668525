<script>
  import { onMount, createEventDispatcher } from "svelte";
  export let width = 300;
  export let height = 300;
  export let margin = {
    left: 30,
    top: 0,
    right: 0,
    bottom: 30
  }
  export let plots;
  export let thresholds;
  export let param_name;
  export let celltypenames;
  let dispatch = createEventDispatcher();

  // let padding = {
  //   x: 5,
  //   y: 0
  // };
  // let svg_width = width + 2*padding.x;
  // let svg_height = height + 2*padding.y;
  let svg_width = width;
  let svg_height = height;
  let min_translate_x = 0;
  let min_translate_y = 0;
  let max_translate_x = width - margin.left - margin.right;
  let max_translate_y = height - margin.top - margin.bottom;
  let min_size = 1;
  let active_region;
  let target;
  let gatelist;
  let move_all = 1;
  let y_center = (min_translate_y+max_translate_y) / 2;

  function oMousePosSVG(e, i) {
    let svg = document.querySelector('#svg'+i.toString());
    var p = svg.createSVGPoint();
    p.x = e.clientX;
    p.y = e.clientY;
    var ctm = svg.getScreenCTM().inverse();
    var p =  p.matrixTransform(ctm);
    return p;
  }

  function move_region(e, i){
    var p = oMousePosSVG(e, i);
    var x = p.x;
    var y = p.y;
    e.preventDefault();
    var dragX = Math.max(
      Math.min(x-margin.left, max_translate_x),
      min_translate_x
    );
    var dragY = Math.max(
      Math.min(y-margin.top, max_translate_y),
      min_translate_y
    );
    if (active_region){
      if (move_all){
        for (var g of gatelist){
          for(var gate of g){
            if (target.classList.contains("min")){
              gate.min_x = Math.min(dragX, gate.cutpt_x-min_size);
            }
            else if (target.classList.contains("max")){
              gate.max_x = Math.max(dragX, gate.cutpt_x+min_size);
            }
            else if(target.classList.contains("cutpt")){
              gate.cutpt_x = Math.max(Math.min(dragX, gate.max_x-min_size), gate.min_x+min_size);
            }
          }
        }
      }
      else{
        if (target.classList.contains("min")){
          active_region.min_x = Math.min(dragX, active_region.max_x-min_size);
        }
        else if (target.classList.contains("max")){
          active_region.max_x = Math.max(dragX, active_region.min_x+min_size);
        }
        else{
          active_region.cutpt_x = Math.max(Math.min(dragX, active_region.max_x-min_size), active_region.min_x+min_size);
        }
      }

      gatelist = gatelist
    }
  }


  function end_action(){
    active_region = null;
    target = null;
    gatelist = gatelist;
  }

  function get_regions(){
    var regions = [];
    var g;
    for (g of gatelist){
      for (var gate of g){
        var minx = gate["min"]/ max_translate_x;
        var maxx = gate["max"] / max_translate_x;
        var y = (max_translate_y - gate["y"]) /  max_translate_y

        var vertices = [
            [minx, y],
            [minx, y],
            [maxx, y],
            [maxx, y],
        ];
        regions.push({"region_type": "Range", "name": gate.name, "display_name": gate.display_name, "vertices": vertices});
      }

    }
    return JSON.stringify(regions);
  }

  function mark_hover(g){
    for (var gl of gatelist){
      for(var gate of gl){
        gate.active = gate == g;
      }
    }
    gatelist = gatelist;
  }

  function perform_save(){
    var thresh = [];
    for (var i = 0; i < gatelist.length; i++){
      for(var j = 0; j < gatelist[i].length; j++){
        thresh.push({
          "min": (gatelist[i][j].min_x/max_translate_x) + min_translate_x,
          "max": (gatelist[i][j].max_x/max_translate_x) + min_translate_x,
          "cutpt": (gatelist[i][j].cutpt_x/max_translate_x) + min_translate_x,
          "gatedataset_id": gatelist[i][j].gatedataset_id
        })
      }
    }
    dispatch("save-plot", [param_name, thresh])
  }

  onMount(()=>{

    if(thresholds){
      thresholds = thresholds.map((t)=>{
        t.min_x = (max_translate_x-min_translate_x)*t.min + min_translate_x;
        t.max_x = (max_translate_x-min_translate_x)*t.max + min_translate_x;
        t.cutpt_x = (max_translate_x-min_translate_x)*t.cutpt + min_translate_x;
        return t;
      });
      gatelist = [];
      var i = 0;
      while(i < thresholds.length){
        gatelist.push(thresholds.slice(i, i+3));
        i+=3;
      }
    }
  })

</script>

<div>
  <div class="control">
    <label class="checkbox">
      <input bind:checked={move_all} type="checkbox">
      Move All Thresholds
    </label>
    <div class="save">
      <button on:click={(e)=>{perform_save()}} class="button is-black">Save</button>
    </div>
  </div>
  <div class="plot-label title is-4">{param_name}</div>
  {#if gatelist}
    {#each gatelist as gl, i}
      <div class="columns">
        {#each gl as g, j}
          <div class="column">
            <div style="width:{width}px;">
                <div class="center title is-6">{plots[(i*3)+j].well}</div>
            </div>
            <div class="wrapper">
              <div style="width:{width}px;height:{height}px;">
                <img style="width:{width}px;height:{height}px;" src="data:image/png;base64, {plots[(i*3)+j].image}" alt="plot" />
              </div>
              <div>
                <svg id="svg{(i*3)+j}" width={svg_width} height={svg_height} on:mouseup={end_action} on:mousemove={(e)=>{move_region(e, (i*3)+j)}}>
                  <clipPath id="plot-clip{(i*3)+j}">
                    <rect
                      x="0"
                      y="0"
                      height="{max_translate_y}"
                      width="{max_translate_x}"
                      transform="translate({margin.left}, {margin.top})">
                    </rect>
                  </clipPath>
                  <g clip-path="url(#plot-clip{i})>">
                    <g transform="translate({margin.left}, {margin.top})">
                      <g
                        class="container"
                        class:active={g.active}
                        on:mouseover={(e)=>{mark_hover(g)}}
                        on:mousedown={(e)=>{active_region=g; target=e.target}}
                        on:mouseout={(e)=>{g.active = false;}}>
                        <line class="min" x1={g.min_x} y1={y_center-30} x2={g.min_x} y2={y_center+30}></line>
                        <line x1={g.min_x} y1={y_center} x2={g.max_x} y2={y_center}></line>
                        <line class="cutpt" x1={g.cutpt_x} y1={y_center-30} x2={g.cutpt_x} y2={y_center+30}></line>
                        <line class="max" x1={g.max_x} y1={y_center-30} x2={g.max_x} y2={y_center+30}></line>
                        <line class="min invis" x1={g.min_x} y1={y_center-30} x2={g.min_x} y2={y_center+30}></line>
                        <line clas="invis" x1={g.min_x} y1={y_center} x2={g.max_x} y2={y_center}></line>
                        <line class="max invis" x1={g.max_x} y1={y_center-30} x2={g.max_x} y2={y_center+30}></line>
                        <line class="cutpt invis" x1={g.cutpt_x} y1={y_center-30} x2={g.cutpt_x} y2={y_center+30}></line>
                        <line style="opacity:{g.active? 1: 0};"  class="min dash" x1={g.min_x} y1={min_translate_y} x2={g.min_x} y2={max_translate_y}></line>
                        <line style="opacity:{g.active? 1: 0};"  class="max dash" x1={g.max_x} y1={min_translate_y} x2={g.max_x} y2={max_translate_y}></line>
                        <line style="opacity:{g.active? 1: 0};" class="cutpt dash" x1={g.cutpt_x} y1={min_translate_y} x2={g.cutpt_x} y2={max_translate_y}></line>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        {/each}
      </div>
    {/each}
  {/if}
</div>

<style>

  svg *{
  -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
       -ms-user-select: none; /* Internet Explorer/Edge */
           user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome, Opera and Firefox */
  }

  .wrapper{
    position: relative;
    padding: 10px;
  }

  .wrapper > div{
    position: absolute;
    top: 0;
  }

  .plot-row{
    display: flex;
    flex: 1 0 auto;
  }

  .column{
    height: 400px;
    width: 400px;
  }

  .save{
    float: right;
  }

  .control{
    height: 75px;
    padding: 10px;
  }

  .center{
    text-align: center;
  }

  .invis{
    stroke-width: 8;
    stroke: transparent;
  }

  .plot-label{
    text-align: center;
  }

  .dash{
    stroke-dasharray: 3,3;
  }

  input,
  .title{
    color: white;
  }

  line{
    fill: transparent;
    stroke-width: 3;
    stroke: purple;
    cursor: pointer;
  }
</style>
