<script>
  import { getContext } from 'svelte';
  import { quadtree } from 'd3-quadtree';

  const { data, xGet, yGet, width, height } = getContext('LayerCake');

  let visible = false;
  let found = {};
  export let e = {};
  export let label = "";

  export let dataset = undefined;
  export let x = 'x';
  export let y = 'y';
  export let searchRadius = undefined;

  $: xGetter = x === 'x' ? $xGet : $yGet;
  $: yGetter = y === 'y' ? $yGet : $xGet;

  function findItem () {
    const xLayerKey = `layer${x.toUpperCase()}`;
    const yLayerKey = `layer${y.toUpperCase()}`;

    found = finder.find(e[xLayerKey], e[yLayerKey], searchRadius) || {};
    visible = Object.keys(found).length > 0;
  }

  $: if(Object.keys(e).length) findItem();

  $: finder = quadtree()
    .extent([[-1, -1], [$width + 1, $height + 1]])
    .x(xGetter)
    .y(yGetter)
    .addAll(dataset || $data);

</script>

<div
  class="bg"
  on:mousemove="{(evt)=>{e = evt}}"
  on:mouseout="{() => visible = false}"
></div>
<slot
  x={xGetter(found) || 0}
  y={yGetter(found) || 0}
  {label}
  {found}
  {visible}
  {e}
></slot>


<style>

  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }


</style>
