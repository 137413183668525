<script>
  import { onMount, createEventDispatcher } from "svelte";
  import GatingController from "./GatingController.svelte";
  import Loading from "./Loading.svelte";
  import Modal from "./Modal.svelte";
  import SelectField from "./SelectField.svelte";
  import Radio from "./Radio.svelte";
  export let titr_id;
  export let well_id_list;
  export let size;
  let plots = {};
  let index = 0;
  let active_plot;
  let loading = false;
  let display_opts = ["Well", "Plot"];
  let display_type = "Well";
  let wid_index = []
  let well_ids = [];
  let browse_keys = [];

  const dispatch = createEventDispatcher();


  async function get_plots(){
    active_plot = null;
    if(titr_id){
      var response = await fetch("/lab-ops/ab-titration/gating-plots/"+titr_id);
    }
    else{
      var formData = new FormData();
      formData.append("well_ids", JSON.stringify(well_id_list));
      var response = await fetch("/lab-ops/ab-titration/gating-plots", {
        method: "POST",
        body: formData
      });
    }
    
    var json = await response.json();
    plots = json.plots;
    well_ids = plots.map(p => p[0].well_id);
  }


  $: wid_index = Object.keys(plots);

  $: browse_keys = display_type === 'Well'? well_ids: Array(plots[wid_index[0]].length).fill().map((element, index) => index);


  $: display_type, index = 0;

  $: titr_id, well_id_list, get_plots();

  // {
  //   plots: {
  //     well_id: []
  //   }
  // }



  onMount(() => {
    get_plots();
  })
</script>

{#if browse_keys.length}
  <div class="qcontainer">
    <div class="radio">
      <Radio label="View Plots By:" opts={display_opts} bind:value={display_type}/>
    </div>
    <div style="text-align:center;" class="plot-header">
      {#if browse_keys.length > 1}
        <div>
          <button title="Previous" disabled={index == 0} on:click={()=> index -= 1} class="button qog-button"><i class="fas fa-chevron-left"></i></button>
          <button title="Next" disabled={index == browse_keys.length-1} on:click={()=> index += 1} class="button qog-button"><i class="fas fa-chevron-right"></i></button>
        </div>
      {/if}
    </div>  
  </div>
  <br />
  {#if display_type === 'Well'}
    <div class="subtitle center smaller-subtitle" title={plots[wid_index[index]][0].filename}>{plots[wid_index[index]][0].plate} | {plots[wid_index[index]][0].well} | {plots[wid_index[index]][0].concentration} {"("+(index+1)} of {Object.keys(plots).length+")"}</div>
  {/if}
  <div class="columns is-multiline" style="width:1400px;">
    {#if display_type === 'Well'}    
      {#each plots[wid_index[index]] as plot}
        <div class="column is-flex is-narrow">
          <div style="--size:{size}" class="image" on:click="{() => {active_plot = plot}}">
            <img src="data:image/png;base64, {plot.image}" />
          </div>
        </div>
      {/each}
    {:else}
      {#each Object.values(plots) as plot}
        <div class="column is-narrow">
          <div class="subtitle center smaller-subtitle" title={plot[index].filename}>{plot[index].well} | {plot[index].concentration}</div>
          <div style="--size:{size}" class="image" on:click="{() => {active_plot = plot[index]}}">
            <img src="data:image/png;base64, {plot[index].image}" />
          </div>
        </div>
      {/each}
    {/if}
  </div>
{/if}
{#if active_plot}
  <Modal style="background:white;width:600px;height:600px;padding:20px;" bind:show_modal={active_plot}>
    <button on:click="{()=>{active_plot = null;}}" class="modal-close is-large" aria-label="close"></button>
    <GatingController on:save-gating={get_plots} bind:well_ids bind:plot_id={active_plot.id} bind:well_id={active_plot.well_id} />
  </Modal>
{/if}


<style>
  .qcontainer{
    text-align: center;
    position: relative;
  }
  .qcontainer > .radio{
    position: absolute;
    top: 0;
    left: 0;
  }
  .qcontainer > .plot-header{
    margin-left: 0%;
    font-size: 2em;
    display: inline-block;
  }

  .plot-header > div{
    margin-right: 20px;
  }

  .plot-header-text{
    width: 500px;
  }

  .center{
    text-align: center;
  }

  .image{
    width: var(--size);
    height: var(--size);
  }

  .smaller-subtitle{
    font-size: 1rem;
  }

  i,
  img{
    cursor: pointer;
  }


</style>
