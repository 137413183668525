<script>
  import { createEventDispatcher } from "svelte";
  export let node = {};
  export let search_str = "";
  export let search_min = 0;
  export let search_max = Infinity;
  export let depth = 0;
  export let open_depth = 3; // Set to 0 if default open to a depth is not desired
  export let first_item = false;
  export let last_item = false;
  export let show_search_context = true;
  export let show_hover_menu = true;
  export let select = false;
  let hovering = false;
  let dispatch = createEventDispatcher();
  function handle_node_click(){
      if (select){
        node.selected = !node.selected;
      }
      dispatch("toggle", node);
  }

  function handle_tree_click(){
      node.open = !node.open;
  }

  function hover() {
      dispatch("hover", node);
  }

  function hover_menu_on() {
      hovering = true;
  }

  function hover_menu_off() {
      hovering = false;
  }

  function node_matches(pnode) {
      if((!search_str || search_str.trim() === '') && search_min == 0 && search_max == Infinity) {
          return(false);
      }
      const str_match = pnode.celltype.toLowerCase().includes(search_str.toLowerCase());
      const min_match = pnode.count >= search_min ? true : false;
      const max_match = pnode.count <= search_max ? true : false;

      return(str_match && min_match && max_match);
  }

  function recurse_open_if_match_in_subtree(curr_node, curr_depth) {
      curr_node.match = node_matches(curr_node);
      var child_match = true;
      if(curr_node.data) {
          child_match = false;
          curr_node.data.forEach(function(sub_node) {
              child_match = recurse_open_if_match_in_subtree(sub_node, curr_depth + 1) || child_match;
          });
          curr_node.open = child_match? true : false;
      }
      return(curr_node.match || (curr_node.data && child_match));
  }

  function recurse_open_to_default_depth(curr_node, curr_depth) {
      curr_node.match = false;
      if(curr_node.data) {
        // Open if not already open
        curr_node.open = curr_node.open || (curr_depth <= open_depth);
          curr_node.data.forEach(function(sub_node) {
              recurse_open_to_default_depth(sub_node, curr_depth + 1);
          });
      }
  }

  $: fa_image = node.data ? (node.open ? 'fa-caret-down':'fa-caret-right') : (node.poptype === 'cluster' ? 'fa-circle':'fa-square');

  $: if(depth == 0) {
      // Cannot use no_search here because the code doesn't always get triggered when search_str is modified
    if((!search_str || search_str.trim() === '') && search_min == 0 && search_max == Infinity && open_depth >= 0) {
          recurse_open_to_default_depth(node, 0);
      }
      else {
          recurse_open_if_match_in_subtree(node, 0);
      }
      node = node;
  }

  $: not_root = depth == 0 ? false : true;
  $: color = node.color ? node.color : "#727276";
  $: match = node.match;
  $: no_search = (!search_str || search_str.trim() === '') && search_min == 0 && search_max == Infinity;
  $: content = `${node.celltype.length > 30 ? node.celltype.substr(0, 28) + '...' : node.celltype} ${node.hasOwnProperty('count')? '(' + node.count + ')': ''}`;
  $: title = `${node.celltype} (${node.count})`;
  $: style = node.style ? node.style : '';
  $: hidden = node.hidden;
</script>

<div class="tree-node" class:not_root class:last_item>
    {#if (show_search_context || (node.data && node.open) || no_search || (!no_search && node.match)) }
        <div>
            <span class="spacer" class:first_item class:last_item></span>
            <i on:click={handle_tree_click} class="fas {fa_image}" style="vertical-align: middle; color:{color}"></i>
            <div class="dropdown is-hoverable">
                <div on:mouseenter={hover} on:click={handle_node_click} class="dropdown-trigger">
                    <span class:match class="highlight" class:hovering class:selected={node.selected} style={style} title={title}>
                      {content}
                      {#if hidden}
                        <i style="font-size: 0.9em;" class="fas fa-eye-slash"></i>
                      {/if}
                    </span>
                </div>
                {#if show_hover_menu}
                    <div on:mouseenter={hover_menu_on} on:mouseleave={hover_menu_off} class="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <slot name="hover-menu-items"></slot>
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    {/if}
    {#if node.data && node.open}
        <div class="sub-tree">
          {#each node.data as n, i}
              <svelte:self on:toggle on:hover node={n} {select} {search_str} {show_search_context} {show_hover_menu} depth={depth+1} first_item={i == 0} last_item={i == node.data.length-1}>
                  <span slot="hover-menu-items"><slot name="hover-menu-items"></slot></span>
              </svelte:self>
          {/each}
        </div>
    {/if}
</div>


<style>
  .tree-node {
    margin-left: 0px;
    margin-top: 0px;
  }

  .tree-node.not_root {
      border-left: 1px solid;
      margin-left: 0.9em;
  }

  .fa-square {
      font-size: 0.5em;
  }

  .fa-circle {
      font-size: 0.5em;
  }

  .spacer {
    width: 1em;
    height: 0.95em;
    margin-right: -0.1em;
    margin-left: -0.1em;
    margin-top: -0.2em;
    vertical-align: top;
    border-bottom: 1px solid;
    content: "";
    display: inline-block;
  }

  .spacer.last_item {
      border-left: 1px solid;
  }

  .spacer.first_item.last_item {
    margin-top: -0.75em;
    height: 1.5em;
  }


  .tree-node.last_item {
      border-left: none;
  }

  .tree-node > div:first-of-type {
      margin-left: 0.1em;
      margin-top: -0.5em;
      padding-top: 0.75em;
  }

  .sub-tree {
      margin-left: 0.5em;
  }

  span.highlight.selected,
  span.highlight:hover,
  span.highlight.hovering {
    color: white;
    cursor: pointer;
    background-color: var(--purple-b-highlight);
  }

  .match {
    color: #541EC3;
  }

</style>
