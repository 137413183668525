<script>
    export let icon;
    export let menuitem_tooltip;
</script>

<div on:click title={menuitem_tooltip} class="dropdown-item">
    <span class="icon is-small"><i class="{icon}"></i></span>
    <span><slot /></span>
</div>

<style>
  .dropdown-item:hover {
    color: white;
    background-color: var(--purple-b-table-lines);
  }

  .dropdown-item > .icon {
      padding-right: 1em;
  }
</style>
