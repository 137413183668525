<script>
  import { getContext } from 'svelte';

  const { padding, yScale, height, width, xScale } = getContext('LayerCake');

  export let yticks = undefined;
  export let ygridlines = false;
  export let yformatTick = d => d;
  export let ylabel = undefined;
  export let ybaseline = false;

  $: tickVals = Array.isArray(yticks) ? yticks : $yScale.ticks(yticks);
</script>

<g class='axis y-axis' transform='translate(-{$padding.left/2}, 0)'>
  {#each tickVals as tick, i}
    <g class='tick tick-{tick}' transform='translate(0, {$yScale(tick)})'>
      {#if ygridlines !== false}
        <line x1='{$padding.left/2}' x2='100%'></line>
      {/if}
      <text>{yformatTick(tick)}</text>
    </g>
  {/each}
  {#if ybaseline === true}
    <line class="baseline" y1="0"  y2='{$height + 0.5}' x1='{$xScale(0)}' x2='{$xScale(0)}'></line>
  {/if}
  {#if ylabel}
    <text transform='translate(-{$padding.left * 0.75}, {$height/2})rotate(270)' text-anchor='middle'>{ylabel}</text>
  {/if}
</g>

<style>
  .tick {
    font-size: .725em;
    font-weight: 200;
  }

  line,
  .tick line {
    stroke: #aaa;
    stroke-dasharray: 2;
  }

  .tick text {
    fill: #666;
    text-anchor: start;
  }

  .tick.tick-0 line {
    stroke-dasharray: 0;
  }

  .baseline {
    stroke: #000;
    stroke-width: 2;
    stroke-dasharray: 0;
  }
</style>
