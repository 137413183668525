<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let result;
  export let url;
  export let post_data;
  export let method = 'POST';
  $: url, post_data, make_post_call();

  let status;
  let error = "Unexpected Error";
  let progress = 0.0;

  function update_progress() {
    if(status == "progress") {
      progress += Math.min(0.1, 99-progress);
      setTimeout(() => {
        update_progress();
      }, 50);
    }
  }
  async function make_post_call() {
    if(!url || (method == 'POST' && !post_data)) {
      status = "";
      return
    }
    status = "progress";
    progress = 0.0
    update_progress();
    const res = await fetch(url, {
      method: method,
      body: post_data,
    });
    if(res.ok) {
      result = await res.json();
      status = "success";
      dispatch("fetched", result);
    } else {
       // if (jqXHR.hasOwnProperty("responseJSON") && jqXHR.responseJSON.hasOwnProperty("error")){
       //   var message = jqXHR.responseJSON["error"];
       // }
      status = "error";
    }
  }
</script>

{#if status == "error"}
  <div>
    <slot name="refresh">
    <div class="refresh">
      <button on:click={make_post_call} class="button is-black">Refresh</button>
    </div>
    </slot>
    <div class="notification is-danger">
      {error}
    </div>
  </div>
{:else if status == "success"}
  <slot name="success" {result}></slot>
{:else if status == "progress"}
  <div>
    <progress class="progress" value={progress} max="100">{progress}%</progress>
  </div>
{:else}
  <slot name="init"></slot>
{/if}

<style>
</style>
