<script>
  import { getContext } from 'svelte';
  import { format } from 'd3-format';

  import QuadTree from './QuadTree.svelte';

  const { data, width, xScale, yScale, config } = getContext('LayerCake');

  export let labelField;
  export let tooltipOffset = 20;
  export let dataset = undefined;
  export let formatTitle = d => d[labelField];
  export let e = {};

  const w = 150;
  const searchRadius = 10;


</script>



<QuadTree
  dataset={dataset || $data}
  {e}
  {searchRadius}
  let:x
  let:y
  let:visible
  let:found
  let:e
>
  {#if visible === true && found.hasOwnProperty(labelField)}
    <div
      class="tooltip"
      style="
        display: { visible ? 'block' : 'none' };
        top:{y}px;
        left:{x}px;"
      >
        <div class="tooltip-label">{formatTitle(found)}</div>
    </div>
  {/if}
</QuadTree>


<style>
	.tooltip {
		position: absolute;
		font-size: 0.75em;
		pointer-events: none;
		border: 1px solid #ccc;
		background: rgba(255, 255, 255, 0.85);
		transform: translate(-50%, -100%);
    padding: 5px;
    z-index: 15;
    pointer-events: none;
  }
  .tooltip{
    transition: left 250ms ease-out, top 250ms ease-out;
  }
  .tooltip-label {
    text-align: center;
    font-weight: bold;
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none;
      -moz-user-select: none;
       -ms-user-select: none;
           user-select: none;
  }
  .key {
    color: #999;
  }
</style>
