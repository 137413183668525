<script>
  import {HsvPicker} from 'svelte-color-picker';
  import CellTypeTreeWithSearch from "./CellTypeTreeWithSearch.svelte";
  import ScatterPlot from './ScatterPlot.svelte';
  import UmapContainer from "./UmapContainer.svelte";
  import SelectField from "./SelectField.svelte";
  import DataTable from "./DataTable.svelte";
  import QTable from "./QTable.svelte";
  import QPlot from "./QPlot.svelte";
  import MenuItem from "./MenuItem.svelte";
  import DropDown from "./DropDown.svelte";
  import Loading from "./Loading.svelte";
  import MultiPlot from "./MultiPlot.svelte";
  import AJAXFetch from "./AJAXFetch.svelte";
  import Modal from "./Modal.svelte";
  import { onMount } from "svelte";
  import {default as robustPointInPolygon} from "robust-point-in-polygon";
  let points;
  let annotations;
  let celltype_tree;
  let plot_title = '';
  let hidden_celltypes_title = '';
  // export let wells;
  export let analysis_id;
  export let analysis_name;
  export let analysis_type;
  let flat_celltypes;
  let active_threshold;
  let sample_table;
  let default_point_size;
  let marker = '';
  let gates = [];
  let togglable_columns;
  let loading = false;
  let min_cell_count_threshold = 0;
  let min_cell_count_threshold_edit = 0;
  let options_open = false;
  let export_open = false;
  let num_gates = -1;
  let celltype_options = [];
  let celltypes1 = [];
  let celltypes2 = [];
  let show_plot = true;
  let stats;
  let table_types = [{id: "pieplot", label: "Pie Plot"}, {id: "heatmap", label: "Heatmap"}]
  let table_type = "pieplot";
  let searchable_columns = [];
  let sortable_columns = [];
  let color_list;
  let edit_color_table = {
    columns: [
      {id: "celltype",  value: "Celltype"},
      {id: "color", value: ""}
    ],
    rows: []
  };
  let active_color;
  let current_color;
  let edit_colors = false;
  // let well_opts = wells.map((w)=>{
  //   return w.value;
  // });
  // let active_well = well_opts[0];
  let show_menu = true;
  let hovered_celltype;
  let hover_type;
  let cluster_hover_type;
  let selected_well;
  let error = null;
  let selected_celltypes = new Set();
  let re_render = false;
  export let width = 740;
  export let height = 740;
  let reload_plots = false;
  let stats_selection = null;
  let compare_plot = null;
  let export_umap_coordinates = true;
  let export_norm_marker_expression = false;
  let export_original_marker_intensity = false;
  let status_message;
  let export_sample = {
    columns: [
      {"id": "plate", "value": "Plate", "type": "text"},
      {"id": "well", "value": "Well", "type": "text"},
    ],
    rows: []
  };

  let open_copy = false;
  $: copy_name = analysis_name + "_copy";
  let copy_error = null;
  async function save_copy() {
    console.log("launching");
    var formData = new FormData();
    formData.append("destination_analysis_name", copy_name);
    var response = await fetch("/analysis/clone/" + analysis_type + "/" + analysis_id, {
      method: "POST",
      body: formData
    });
    console.log("launched");
    var json = await response.json();
    console.log("response", json);
    if(json.status === "success") {
      window.location = "/analysis/new-analysis/" + json.analysis_type + "/" + json.analysis_id;
    } else {
      copy_error = json.message;
    }
  }

  function celltype_toggle(e){
    var toggle_celltype = e.detail.celltype;
    if(selected_celltypes.has(toggle_celltype)) {
      selected_celltypes.delete(toggle_celltype);
    }
    else {
      selected_celltypes.add(toggle_celltype);
    }
    // show_menu = selected_celltypes.length > 0;
    update_points();

  }

  async function get_compare_plot(){
    if(selected_well){
      var formData = new FormData();
      formData.append("well_id", selected_well.id)
      if (marker != ""){
        var dimension = marker;
        formData.append("dimensions", JSON.stringify([dimension]))
      }
         
      var response = await fetch("/analysis/"+analysis_id+"/compare-plot", {
        method: "POST",
        body: formData
      });
      var json = await response.json();
      json.dimensions_options = [""].concat(json.dimensions_options);
      compare_plot = json;
    }

  }

  async function save_plot(){
    var color_dict = {};
    var to_parse = [celltype_tree];
    while(to_parse.length){
      var node = to_parse.pop();
      color_dict[node.celltype] = node.color;
      if (node.data){
        to_parse = to_parse.concat(node.data)
      }
    }
    var formData = new FormData();
    formData.append("pop2color", JSON.stringify(color_dict));
    if(marker != ''){
      formData.append("plot_type", "Marker")
      formData.append("marker", marker)
    }
    var response = await fetch("/analysis/save-plot/"+analysis_id+"/"+selected_well.id, {
      method: "POST",
      body: formData
    });
    const json = await response.json();
    reload_plots = true;
  }

  function set_active_sample(e){
    if(e.detail[1].column_type === "overlay_parameter"){
      marker = e.detail[1].value.replace("<i class='fa fa-cog' />", "").replace("| ", "(")+")";
    }
    else{
      marker = '';
      compare_plot = null;
    }
    selected_well = e.detail[0];
    gates = [];
    update_points({})
    stats_selection = null;
    // get_plots(row.well_id)
  }


  async function open_threshold_window(e){
    var param_name = e.detail[0].param_name;
    var param_target = e.detail[1].className.indexOf("fa-cog") != -1;
    if(param_name && param_target){
      loading = true;
      var wells = sample_table.rows.map((r)=>{
        return r.id;
      });


      var params = {};
      params["param_name"] = param_name;
      // params["wells"] = wells.join(",");
      // params["celltype"] = input_celltype;
      var search_str = new URLSearchParams(params).toString();

      var response = await fetch("/analysis/adjust-analysis-histograms/"+analysis_id+"?"+search_str);
      const json = await response.json();
      loading = false;
      active_threshold = json;
    }

  }

  async function get_sample_table(){
    loading = true;
    var response = await fetch("/analysis/sample-table/"+analysis_id+"?table_type="+table_type);
    const json = await response.json();
    var data = json;
    data.columns = data.columns.map((c)=>{
      if (c.column_type === "overlay_parameter"){
        c.value = c.value + "<i class='fa fa-cog' />";
      }
      return c;
    })

    sample_table = data;
    if (!togglable_columns){
      togglable_columns = sample_table.columns.reduce((acc,c)=>{
        acc[c.id] = false;
        return acc;
      }, {})
    }

    if(!selected_well){
      selected_well = data.rows[0];
    }
    else{
      selected_well = selected_well;
    }
    searchable_columns = sample_table.columns.filter(c=>c.column_type==="metadata").map(c=>c.id);
    if(table_type == "pieplot"){
      sortable_columns = searchable_columns;
    }
    else{
      sortable_columns = sample_table.columns.map(c=>c.id);
    }
    var export_filter = {};
    export_sample.rows = data.rows.filter((r)=>{
      if(!export_filter.hasOwnProperty(r.id)){
        export_filter[r.id] = true;
        return true;
      }
      return false;
    }).map((r)=>{
      r.selected = true;
      return r;
    });

    export_sample = export_sample;
    loading = false;
  }

  async function get_analysis_params(){
    var response = await fetch("/analysis/"+analysis_id+"/analysis-params");
    const json = await response.json();
    var data = json;
    color_list = data.colors;
    min_cell_count_threshold = data.min_cell_count_threshold;
    min_cell_count_threshold_edit = data.min_cell_count_threshold;
  }

  async function get_plots(){
    if(selected_well){
      loading = true;
      gates = [];
      stats_selection = null;
      var formData = new FormData();
      formData.append("well_id", selected_well.id);
      if(marker != ""){
        formData.append("plot_type", "Marker")
        formData.append("marker", marker)
      }
      var response = await fetch("/analysis/new-analysis/" + analysis_type + "/"+analysis_id, {
              method: "POST",
              body: formData
          });
      const json = await response.json();
      plot_title = json.title
      points = json.points;
      default_point_size = points[0].r;
      annotations = json.annotations;
      celltype_tree = json.celltype_tree;
      celltype_options = json.celltype_options;
      re_render = true;
      loading = false;
      get_compare_plot();
      update_stats();
    }

  }

  async function perform_update_thresholds(e){
    var param_name = e.detail[0];
    var thresholds = e.detail[1];
    thresholds = thresholds.reduce((acc,v)=>{
      acc[v.gatedataset_id] = v;
      return acc;
    }, {})
    var formData = new FormData();
    formData.append("thresholds", JSON.stringify(thresholds));
    formData.append("param_name", param_name);
    active_threshold = null;
    loading = true
    var response = await fetch("/analysis/adjust-analysis-histograms/"+analysis_id, {
      method: "POST",
      body: formData
    });
    const json = await response.json();
    loading = false;
    get_sample_table();

  }

  function get_stats(e){
    if(e.detail.length){
      var polygon = JSON.parse(JSON.stringify(e.detail));
      // polygon.push(polygon[0])
      // polygon = polygon.map((p)=>{
      //   return p.concat([p[0]]);
      // })
      update_points(e)
      var formData = new FormData();
      formData.append("selection", JSON.stringify(polygon));
      // formData.append("dimredspec_id", selected_umap.spec_id);
      var response = fetch("/analysis/differential-analysis-table/" + analysis_id+"/"+selected_well.id, {
          method: "POST",
          body: formData
      }).then(response => response.json())
        .then(data => stats_selection=data);
    }
    else{
      stats_selection = null;
      update_points({})
    }

  }

  function debounce(func, wait=0, immediate) {
  	var timeout;
  	return function() {
  		var context = this, args = arguments;
  		var later = function() {
  			timeout = null;
  			if (!immediate) func.apply(context, args);
  		};
  		var callNow = immediate && !timeout;
  		clearTimeout(timeout);
  		timeout = setTimeout(later, wait);
  		if (callNow) func.apply(context, args);
  	};
  }

  function getHiddenCelltypes(){
    return new Set(flat_celltypes.filter((c)=>{
      return c.hidden;
    }).map((c)=>{return c.celltype; }));
  }


  function update_points(e){
    var hiddenCelltypes = getHiddenCelltypes();
    points = points.map((p)=>{
      var pointInPoly = gates.map((g) => {return robustPointInPolygon(g, [p.x, p.y]) < 1;}).some(x => x);
      if(hiddenCelltypes.has(p.celltype)){
        p.r = 0;
      }
      else{
        p.r = default_point_size;
      }
      if((gates.length && pointInPoly) || selected_celltypes.has(p.celltype)){
        p.opacity = 1;
      }
      else if(gates.length){
        p.opacity = 0.6;
      }
      else{
        p.opacity = 1;
      }
      return p;
    })
  }

  function celltype_hover(e){
      hovered_celltype = e.detail;
      var annotation = annotations.find((a)=>{
        return a.text === hovered_celltype.celltype;
      });
      hover_type = annotation && annotation.hidden? "show": (annotation && !annotation.hidden? "hide": null)
      cluster_hover_type = e.detail.hasOwnProperty("hidden") && e.detail.hidden===true? "show": "hide";
  }

  function toggle_cluster(e, show_all){
    if(show_all === 'show' || show_all === 'hide'){
      for (var node of flat_celltypes){
        node.hidden = show_all === 'hide';
        if (node.hidden){
          node.style = 'color:lightgrey;';
        }
        else{
          delete node.style;
        }
      }
    }
    else{
      hovered_celltype.hidden = !hovered_celltype.hidden;
      if(hovered_celltype.hidden){
        hovered_celltype.style = 'color:lightgrey;';
      }
      else{
        delete hovered_celltype.style;
      }
      cluster_hover_type = cluster_hover_type === "show" ? "hide": "show";
    }
    celltype_tree = celltype_tree;
    update_hidden_celltypes_title();
    update_points();
    
  }

  function raise_cluster(forward){
    var found_points = [];
    var filter_points = points.filter((p)=>{
      if (p.celltype === hovered_celltype.celltype){
        found_points.push(p);
        return false;
      }
      return true;
    });
    if(forward){
      points = filter_points.concat(found_points);
    }
    else{
      points = found_points.concat(filter_points);
    }
  }

  function toggle_annotation(e, toggle_type){
    if (toggle_type){
      annotations = annotations.map((a)=>{
        a.hidden = toggle_type === "hide";
        return a;
      });
    }
    else{
      var annotation = annotations.find((a)=>{
        return a.text === hovered_celltype.celltype;
      });
      annotation.hidden = !annotation.hidden;
      hover_type = hover_type === "show" ? "hide": "show";
      annotations = annotations;
    }
    save_annotations();
  }

  function save_annotations(){
    var sample_id = selected_well.id;
    var post_data = {analysis_id: analysis_id};
    post_data["annotations"] = JSON.stringify(annotations.reduce((acc, a)=>{
      acc[a.text] = [a.x, a.y, a.hidden]
      return acc;
    }, {}));
    window.$.ajax({
      url: "/analysis/save-annotations/"+sample_id,
      type: "POST",
      data: post_data,
      dataType: "json",
      success: function(data){

      },
      error: function(jqXHR, textStatus, errorThrown){
        if (jqXHR.hasOwnProperty("responseJSON") && jqXHR.responseJSON.hasOwnProperty("error")){
          var message = jqXHR.responseJSON["error"];
        }
        else{
          var message = "Unexpected Error."
        }
        error = message;
        setTimeout(()=>{
          error = null;
        }, 5000);
      }

    })
  }

  function save_order(e){
    var post_data = {};
    if (e.type === "coldragend"){
      var col_order = JSON.parse(JSON.stringify(e.detail[0]));
      post_data["col_order"] = JSON.stringify(col_order.map((c)=>{
        c.value = c.value.replace("<i class='fa fa-cog' />", "");
        return c;
      }));
      window.$.ajax({
        url: "/analysis/"+analysis_id+"/analysis-params",
        type: "POST",
        data: post_data,
        dataType: "json",
        success: function(data){

        },
        error: function(jqXHR, textStatus, errorThrown){
          if (jqXHR.hasOwnProperty("responseJSON") && jqXHR.responseJSON.hasOwnProperty("error")){
            var message = jqXHR.responseJSON["error"];
          }
          else{
            var message = "Unexpected Error."
          }
          error = message;
          setTimeout(()=>{
            error = null;
          }, 5000);
        }

      })
    }
    else if(e.type === "rowdragend"){
      post_data["row_order"] = JSON.stringify(e.detail[0].map((r)=> {
        return r.id
      }));
      window.$.ajax({
        url: "/analysis/"+analysis_id+"/analysis-params",
        type: "POST",
        data: post_data,
        dataType: "json",
        success: function(data){

        },
        error: function(jqXHR, textStatus, errorThrown){
          if (jqXHR.hasOwnProperty("responseJSON") && jqXHR.responseJSON.hasOwnProperty("error")){
            var message = jqXHR.responseJSON["error"];
          }
          else{
            var message = "Unexpected Error."
          }
          error = message;
          setTimeout(()=>{
            error = null;
          }, 5000);
        }

      })
    }
  }

  let stats_url;
  let stats_post_data = null;
  function update_stats() {
    if(selected_well){
      var url = "/analysis/diff-analysis-volcano/"+analysis_id+"/"+selected_well.id;
      var formData = new FormData();
      if(celltypes1.length){
        formData.append("celltypes1", JSON.stringify(celltypes1));
      }
      if(celltypes2.length){
        formData.append("celltypes2", JSON.stringify(celltypes2));
      }
      var selections = gates.reduce((acc,v,i)=>{
        acc["Lasso "+(i+1).toString()] = v;
        return acc;
      },{})
      formData.append("selections", JSON.stringify(selections));
      stats_url = url;
      stats_post_data = formData;

    }

  }


  function export_analysis(){
    var selected_export_wells = export_sample.rows.filter((r)=>{return r.selected}).map((r)=>{
      return r.id;
    });
    status_message = "Download started. May take a few minutes.";
    window.location.href = "/analysis/umap-export/"+analysis_type+"/"+analysis_id+"?umap_wells="+selected_export_wells+"&export_umap_coordinates="+export_umap_coordinates+"&export_norm_marker_expression="+export_norm_marker_expression+"&export_original_marker_intensity="+export_original_marker_intensity+"&table_type="+table_type;
    setTimeout(()=>{
      status_message = null;
    }, 5000);
  }

  function download_stats_selection(){
    var form = window.$('<form>', {'method': 'POST', 'action': '/api/export-table-toexcel'}).hide();

    //Add params
    var params = { 
      'json_table': JSON.stringify(stats_selection),
      'export_prefix': analysis_name
    };
    window.$.each(params, function (k, v) {
        form.append(window.$('<input>', {'type': 'hidden', 'name': k, 'value': v}));
    });

    //Make it part of the document and submit
    window.$('body').append(form);
    form.submit();

    //Clean up
    form.remove();
  }

  function download_stats_plot_table(){
    if(show_plot){
      var form = window.$('<form>', {'method': 'POST', 'action': '/api/export-jsonplot-image'}).hide();

      //Add params
      var params = { 
        'plot_dict': JSON.stringify(stats.plot),
        'export_prefix': analysis_name
      };
    }
    else{
      var form = window.$('<form>', {'method': 'POST', 'action': '/api/export-table-toexcel'}).hide();

    //Add params
      var params = { 
        'json_table': JSON.stringify(stats.table),
        'export_prefix': analysis_name
      };
    }
    
    window.$.each(params, function (k, v) {
        form.append(window.$('<input>', {'type': 'hidden', 'name': k, 'value': v}));
    });

    //Make it part of the document and submit
    window.$('body').append(form);
    form.submit();

    //Clean up
    form.remove();
  }

  function get_colors(){
    return color_list.reduce((acc, v)=>{
      acc[v.id] = [v.celltype, v.value];
      return acc;
    }, {});
  }

  function open_color_picker(){
    var color_picker_dict = get_colors();
    edit_color_table.rows = Object.keys(color_picker_dict).map((k)=>{
      return {id: k, celltype: color_picker_dict[k][0], color: {style: "background-color:"+color_picker_dict[k][1], text: ""}, value:color_picker_dict[k][1]}
    });
    edit_colors = true;
    update_set_all_colors();
  }


  async function save_colors(){
    loading = true;
    edit_colors = false;
    var formData = new FormData();
    var pop2color = edit_color_table.rows.reduce((acc, r) => {
      acc[r.id] = r.value;
      return acc;
    }, {});
    formData.append("ctype2color", JSON.stringify(pop2color));
    formData.append("min_cell_count_threshold", min_cell_count_threshold_edit);
    var response = await fetch("/analysis/"+analysis_id+"/analysis-params", {
      method: "POST",
      body: formData
    });
    const json = await response.json();
    var data = json;
    color_list = data.colors;
    console.log("color_list", color_list)
    min_cell_count_threshold = data.min_cell_count_threshold;
    min_cell_count_threshold_edit = data.min_cell_count_threshold;
    loading = false;
    get_sample_table()
    reload_plots = true;

  }

  function set_edit_color(e){
    if (e){
      current_color = e.detail[0].value;
      active_color = [e.detail[0]];
    }
    else{
      var selected_rows = edit_color_table.rows.filter(r=>r.selected);
      if(selected_rows.length){
        current_color = selected_rows[0].value;
        active_color = selected_rows;
      }

    }

  }

  function perform_color_update(){
    current_color = "#" + ((1 << 24) + (current_color.r << 16) + (current_color.g << 8) + current_color.b).toString(16).slice(1);
    for(var j = 0; j < active_color.length; j++){
      for(var i = 0; i < edit_color_table.rows.length; i++){
        if(edit_color_table.rows[i].celltype == active_color[j].celltype){
          edit_color_table.rows[i].value = current_color;
          edit_color_table.rows[i].color.style = "background:"+current_color;
        }
      }
    }

    edit_color_table = edit_color_table;
    active_color = null;

  }

  let set_all_colors = false;
  let set_all_title = "Select at least two celltypes to set color for all.";

  function update_set_all_colors(){
    set_all_colors = edit_color_table.rows.filter(r=>r.selected).length >= 2;
    set_all_title = set_all_colors? "Set color for all selected rows." : "Select at least two celltypes to set color for all.";
  }

  onMount(()=>{
    // get_sample_table();
    get_analysis_params();
    get_compare_plot();
  })

  let compare_header1;
  let compare_header2;
  $: table_type, get_sample_table();
  $: selected_well, marker, get_plots();
  $: celltypes1 = celltype_options.map((c,i) => ({"id": i, "lasso": false, "celltype": c.value, "selected": i == 0 ? true : false})).concat(gates.map((g,j)=>({"id": celltype_options.length+j, "lasso": true, "celltype": "Lasso "+(j+1).toString(), "selected":false})));
  $: selected_well, celltypes1, update_compare_header1();
  $: celltypes2 = celltype_options.map((c,i) => ({"id": i, "lasso": false, "celltype": c.value, "selected": i == 1 ? true : false})).concat(gates.map((g,j)=>({"id": celltype_options.length+j, "lasso": true, "celltype": "Lasso "+(j+1).toString(), "selected":false})));
  $: selected_well, celltypes2, update_compare_header2();
  function update_compare_header1() {
    var comp_header = celltypes1.filter(c => c.selected).map(c => c.celltype).join(",")
    if (comp_header !== compare_header1){
      update_stats();
    }
    compare_header1 = comp_header;

  }
  function update_compare_header2() {
    var comp_header = celltypes2.filter(c => c.selected).map(c => c.celltype).join(",")
    if (comp_header !== compare_header2){
      update_stats();
    }
    compare_header2 = comp_header;
  }

  function get_celltypes(){
    var ctype_list = [];
    var queue = [celltype_tree];
    while(queue.length){
      var node = queue.shift();
      if(node.hasOwnProperty("data")){
        queue = queue.concat(node.data);
      }
      ctype_list.push(node);
    }
    console.log(ctype_list)
    return ctype_list;

  }

  function update_hidden_celltypes_title(){
    var hidden_celltypes = [...getHiddenCelltypes()];
    if (hidden_celltypes.length){
      hidden_celltypes_title = hidden_celltypes.length + " of " + flat_celltypes.length + " celltypes hidden";
    }
    else {
      hidden_celltypes_title = '';
    }
  }

  $: if(celltype_tree) flat_celltypes = get_celltypes();
</script>

<div class="summary-page">
  <div class="qog_banner_toolbar webix_toolbar webix_layout_toolbar">
    <div class="webix_list-x crumbs">
      <div class="webix_list_item"><a href='{window.location.pathname.split("/").splice(0, 2).join("/")}'>Analysis</a></div>
      <div class="webix_list_item"><a href='{window.location.pathname.split("/").splice(0, 2).join("/")+"/analysis-table"}'>Analysis Table</a></div>
    </div>
    <div class="analysis-title">{analysis_name}</div>
    <div title="Copy Analysis" class="dropdown-trigger" style="cursor: pointer">
      <i class="fas fa-copy" on:click={() => {open_copy = true; copy_error=null;}}/>
    </div>
    <div id="options-dropdown" class="dropdown is-hoverable" class:is-active={options_open}>
      <div title="Change Celltype Colors" class="dropdown-trigger">
        <i class="fas fa-paint-brush" on:click={open_color_picker}/>
      </div>
    </div>
    <div id="export-button" class="export-button dropdown is-hoverable" class:is-active={export_open}>
      <div title="Export Analysis" class="dropdown-trigger">
        <i class="fa fa-download" on:click={(e)=>{export_open=!export_open}} />
      </div>
      <div id="dropdown-export" class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <div class="export-table">
            <QTable rows={export_sample.rows} columns={export_sample.columns} show_checkboxes={true} />
          </div>
          <div class="export-options">
            <label class="checkbox">
              <input bind:checked={export_umap_coordinates} type="checkbox">
              Export Umap Co-ordinates
            </label>
            <label class="checkbox">
              <input bind:checked={export_norm_marker_expression} type="checkbox">
              Export Normalized Marker Expression
            </label>
            <label class="checkbox">
              <input bind:checked={export_original_marker_intensity} type="checkbox">
              Export Original Marker Intensity
            </label>
          </div>
          <button title="Export" on:click={export_analysis} class="button qog-button">Export</button>
        </div>
      </div>
    </div>
  </div>
  {#if error}
    <div class="notification is-danger">
      <button on:click={(e)=>{error=null;}} class="delete"></button>
      {error}
    </div>
  {/if}
  {#if status_message}
    <div class="notification is-success">
      <button on:click={(e)=>{status_message=null;}} class="delete"></button>
      {status_message}
    </div>
  {/if}
  <div class="columns">
    {#if sample_table}
      <div class="column sample-column">
        <br />
        <div class="scroll-div">
          <QTable
            draggable_rows rows={sample_table.rows}
            columns={sample_table.columns}
            togglable_columns={togglable_columns}
            highlight_row={selected_well}
            on:cell-clicked={set_active_sample}
            on:header-clicked={open_threshold_window}
            on:coldragend={save_order}
            on:rowdragend={save_order}
            searchable_columns={searchable_columns}
            sortable_columns={sortable_columns}>
          <span slot="title">
            <div>
              {#each table_types as t_type}
                <label style="margin-left:0.3em">
                  <input type=radio bind:group={table_type} value={t_type.id}>
                  {t_type.label}
                </label>
              {/each}
            </div>
          </span>
          </QTable>
        </div>
      </div>
    {/if}
  </div>
  {#if points}
  <hr class="hr" />
  <div class="qcontainer">
    <div class="left-column">
      <div class="celltype-container">
        <CellTypeTreeWithSearch select={true} on:toggle={celltype_toggle} on:hover={celltype_hover} node={celltype_tree} {show_menu} open_depth=0 show_hover_menu={true} >
          <span slot="menu-items">
            <MenuItem icon="fa fa-copy" on:click="{() => {toggle_annotation({}, 'hide')}}">Hide All Annotations</MenuItem>
            <MenuItem icon="fa fa-copy" on:click="{() => {toggle_annotation({}, 'show')}}">Show All Annotations</MenuItem>
            <MenuItem icon="fa fa-copy" on:click="{(e)=>toggle_cluster(e, 'show')}">Show All Celltypes</MenuItem>
            <MenuItem icon="fa fa-copy" on:click="{(e)=>toggle_cluster(e, 'hide')}">Hide All Celltypes</MenuItem>
          </span>
          <span slot="hover-menu-items">
            {#if hover_type }
              {#if hover_type == "hide"}
                <MenuItem icon="fa fa-copy" on:click="{toggle_annotation}">Hide Annotation</MenuItem>
              {:else if hover_type =="show"}
                <MenuItem icon="fa fa-copy" on:click="{toggle_annotation}">Show Annotation</MenuItem>
              {/if}
            {/if}
            {#if cluster_hover_type == "show"}
              <MenuItem icon="fa fa-copy" on:click="{toggle_cluster}">Show Cluster</MenuItem>
            {:else}
              <MenuItem icon="fa fa-copy" on:click="{toggle_cluster}">Hide Cluster</MenuItem>
            {/if}
              <MenuItem icon="fa fa-copy" on:click="{()=>{raise_cluster(true)}}">Bring to Front</MenuItem>
              <MenuItem icon="fa fa-copy" on:click="{()=>{raise_cluster(false)}}">Send to Back</MenuItem>
          </span>
        </CellTypeTreeWithSearch>
      </div>
      <hr class="hr" />
      <div class="color-by-div">
        {#if compare_plot}
            <SelectField label="X Dimension" opts={compare_plot.dimensions_options} bind:value={marker} />
            <div style="height:250px;width:300px;">
              {#if marker != ''}
                <QPlot
                  bind:data={compare_plot.points}
                  xDomain={compare_plot.xDomain}
                  yDomain={compare_plot.yDomain}
                  xformatTick={d=>compare_plot.xticklabels[compare_plot.xticks.indexOf(d)]}
                  yformatTick={d=>""}
                  xticks={compare_plot.xticks}
                  yticks={compare_plot.yticks}
                />
              {/if}
            </div>
        {/if}
      </div>
    </div>
    <div class="middle-column">
        <div>{@html plot_title}&nbsp;{hidden_celltypes_title}</div>
        <div class="plot-with-options">
          <div style="height: {height}px;width:{width}px;" class="chart-container">
            <QPlot {num_gates} bind:data={points} selection={true} xticks={[]} yticks={[]} annotations={annotations} on:movetext={save_annotations} on:endSelection={get_stats} bind:gates labelField="celltype" />
          </div>
          <div>
            <button title="Save" on:click={save_plot} class="button qog-button"><i class="fas fa-save"></i></button>
          </div>
        </div>

    </div>
    <div class="right-column">
      <div style="overflow:auto; height:300px">
        {#if stats_selection}
            <button style="font-size:0.75em;float:right;" title="Download" on:click={download_stats_selection} class="button qog-button"><i class="fas fa-download"></i></button>
            <QTable title={stats_selection.title} rows={stats_selection.rows} columns={stats_selection.columns} />
        {:else}
        {/if}
      </div>
      <div style="overflow:auto;">
        {#if stats}
          <button style="font-size:0.75em;float:right;" title="Download" on:click={download_stats_plot_table} class="button qog-button"><i class="fas fa-download"></i></button>
        {/if}
        <div class="smaller-font">
          <DropDown class="smaller-font" label={compare_header1.length >= 40 ? compare_header1.substr(0, 37) + "..." : compare_header1}>
            <QTable rows={celltypes1} columns={[{"id": "celltype", "value": "Celltype"}]} on:selection-changed={update_compare_header1} show_checkboxes={true} />
          </DropDown>
        </div>
        <div style="align-self: center; font-weight: bold">vs</div>
        <div class="smaller-font">
          <DropDown label={compare_header2.length >= 40 ? compare_header2.substr(0, 37) + "..." : compare_header2}>
            <QTable rows={celltypes2} columns={[{"id": "celltype", "value": "Celltype"}]} on:selection-changed={update_compare_header2} show_checkboxes={true} />
          </DropDown>
        </div>
          <div>
            <i on:click={()=>{show_plot = true;}} class="fas fa-chart-area clickable {show_plot? 'selected': ''}"></i>
            <i on:click={()=>{show_plot = false;}} class="fas fa-table clickable {!show_plot? 'selected': ''}"></i>
          </div>
          <div class="volcano-plot">
            <AJAXFetch url={stats_url} post_data={stats_post_data} method="POST" on:fetched={(e) => stats=e.detail}>
              <span slot="success">
                {#if show_plot}
		              <div>{@html stats.plot.title}</div>
                  <QPlot
                    bind:data={stats.plot.points}
                    xDomain={stats.plot.xDomain}
                    yDomain={stats.plot.yDomain}
                    xticks={stats.plot.xticks}
                    yticks={stats.plot.yticks}
                    xgridlines={true}
                    ygridlines={true}
                    xbaseline={true}
                    ybaseline={true}
                    xlabel={stats.plot.hasOwnProperty("xlabel")? stats.plot.xlabel: null}
                    ylabel={stats.plot.hasOwnProperty("ylabel")? stats.plot.ylabel: null}
                    padding={{ top: 0, right: 0, bottom: 40, left: 25 }}
                    plot_type="svg"
                  />
                {:else}
                  <div class="smaller-font">
                    <QTable title={stats.table.title} rows={stats.table.rows} columns={stats.table.columns} global_searchable_columns={["Type", "Marker"]} />
                  </div>
                {/if}
              </span>
            </AJAXFetch>
          </div>
      </div>
    </div>
  </div>
  {/if}
  <hr class="hr" />
  <UmapContainer bind:reload={reload_plots} {analysis_name} {analysis_id} />
</div>


<Modal bind:show_modal={open_copy} bind:error_message={copy_error}>
  <span>Clone current analysis to</span>
  <div>
    <input class="input" type="text" bind:value={copy_name}/>
  </div>
  <br />
  <div>
    <button class="button qog-button" on:click={save_copy}>Clone</button>
  </div>
</Modal>

{#if edit_colors}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <button on:click="{()=>edit_colors=false}" class="modal-close is-large" aria-label="close"></button>
    <span><button on:click={()=>{set_edit_color()}} title={set_all_title}  disabled={!set_all_colors} class="button qog-button">Set For All Selected</button></span>
    <div style="width:800px;" class="modal-content">
      <div style="overflow:auto; max-height:800px;">
        <QTable widths={{'celltype': '750px', 'color': '50px'}} on:selection-changed={update_set_all_colors} show_checkboxes={true} rows={edit_color_table.rows} columns={edit_color_table.columns} on:cell-clicked={set_edit_color} />
      </div>
      <span><button on:click={save_colors} class="button qog-button">Save</button></span>
    </div>
  </div>
{/if}

{#if active_color}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="color-modal modal-content">
      <div class="title is-4">Setting Color for {active_color.length > 1? active_color.length + " celltypes": active_color[0].celltype}</div>
      <button on:click="{()=>{active_color=null}}" class="modal-close is-large" aria-label="close"></button>
      <HsvPicker on:colorChange={rgba=>{current_color=rgba.detail;}} startColor={current_color}/>
      <br />
      <button on:click={perform_color_update} class="button qog-button">Update</button>
    </div>
  </div>
{/if}

{#if loading}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <Loading></Loading>
    </div>
  </div>
{/if}

{#if active_threshold}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content threshold-modal">
      <button on:click="{()=>{active_threshold = null;}}" class="modal-close is-large" aria-label="close"></button>
      <MultiPlot bind:plots={active_threshold.plots} param_name={active_threshold.param_name} bind:thresholds={active_threshold.thresholds} on:save-plot={perform_update_thresholds} />
    </div>
  </div>
{/if}

<style>
  .summary-page{
    margin-left: 40px;
    margin-right: 40px;
    width: 100%;
  }

  .export-options{
    color: black;
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
    row-gap: 0.2em;
    margin-bottom: 0.75em;
  }

  .export-table{
    width: 600px;
    overflow: scroll;
  }


  .plot-with-options{
    flex: 1 0 auto;
    display: flex;
  }

  .analysis-title {
    size: 1.5rem;
    margin-left:10em;
    margin-right: 10em;
    font-weight: 600;
  }

  .title-container{
    margin-top: 20px;
    margin-right: 200px;
    flex: 1 0 auto;
    display: flex;
  }

  .celltype-container{
    margin-right: auto;
    padding-left: 15px;
    height: 22em;
    max-height: 22em;
    overflow: auto;
  }

  .color-by-div {
    height: 350px;
    max-height: 350px;
    overflow: auto;
  }

  .plot-column{
    width: 800px;
    max-width: 800px;
    min-width: 800px;
    padding-right: 5px;
    border-right: solid whitesmoke;
  }

  .table-column{
    width: 20em;
    max-width: 25em;
    min-width: 20em;
    overflow: auto;
    padding-right: 5px;
    border-right: solid whitesmoke;
  }

  .stats-column{
    width: 20em;
    max-width: 25em;
    min-width: 20em;
    padding-left: 20px;
  }

  .second-row{
    height: 50em;
    min-height: 30em;
    max-height: 50em;
  }

  .scroll-div{
    overflow: scroll;
    max-height: 650px;
  }



  .dropdown-content{
    padding: 10px;
  }

  .sample-column{
    max-width: 1550px;
    padding-right: 96px;
  }


  .threshold-modal{
    /* width: 1300px; */
    width: fit-content;
  }

  .color-modal > .title{
    color: white;
  }

  .selected{
    color: var(--ryvett-light-grey-blue-highlighter);
  }

  .volcano-plot{
    height: 25em;
    max-height: 40em;
    overflow: auto;
  }

  .diff-exp-area{
    height: 25em;
    max-height: 30em;
    overflow: auto;
  }

  .qcontainer {
    display: flex;
  }

  .left-column {
    flex: 0 0 22em;
    border-right: solid whitesmoke;
    padding-right: 10px;
  }

  .middle-column {
    flex: 1 0 auto;
    display: flex;
    max-width: 800px;
    max-height: 800px;
    flex-direction: column;
    border-right: solid whitesmoke;
    padding-left: 10px;
    padding-right: 10px;
  }

  .right-column {
    flex: 0 0 25em;
    display: flex;
    max-height: 55em;
    height: 55em;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
  }

  .dropdown-trigger {
    margin-top: 0.5em;
    margin-left: 0.75em;
  }

  .qog_banner_toolbar {
    height: 46px;
    display: flex;
  }

  .analysis-title,
  .dropdown-trigger{
    color: var(--ryvett-submenu-color);
  }
</style>
