<script>
  import CellTypeTree from "./CellTypeTree.svelte";
  export let node = {};
  let search_str = null;
  export let show_search_context = true;
  export let select = false;
  export let show_menu = false;
  export let show_hover_menu = false;
  export let open_depth=3;

  function toggle_show_context(e) {
      show_search_context = !show_search_context;
  }
</script>

<div class="level">
    <div class="level-left">
        <div class="level-item control has-icons-right">
            <input class="input" type="text" placeholder="Search Celltypes" bind:value={search_str}>
            <span class="icon is-small is-right" on:click={toggle_show_context}>
                <i class:red={!show_search_context} class="fas fa-filter"></i>
            </span>
        </div>
        {#if show_menu}
            <div class="level-item dropdown is-hoverable is-right">
                <div class="dropdown-trigger">
                    <i class="fas fa-bars" aria-hidden="true"></i>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                        <slot name="menu-items"></slot>
                    </div>
                </div>
            </div>
        {/if}
    </div>
</div>
<CellTypeTree on:toggle on:hover {select} {node} {search_str} {show_search_context} {show_hover_menu} {open_depth}>
    <span slot="hover-menu-items">
        <slot name="hover-menu-items"></slot>
    </span>
</CellTypeTree>

<style>
    .control .icon {
        pointer-events: initial;
    }
    .red {
        color: #541EC3;
    }
</style>
