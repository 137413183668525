<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  // will fire opened and closed events

  export let label = "";
  export let showPanel = true;
  $: showPanel ? dispatch("opened") : dispatch("closed");


</script>

<nav class="panel">
  <p class="panel-heading" on:click={() => showPanel = !showPanel}>
    <span>{label}</span>
    <span class="icon is-small">
      <i class="fas fa-angle-down" aria-hidden="true"></i>
    </span>
  </p>
  {#if showPanel}
    <div class="panel-block">
      <slot />
    </div>
  {/if}
</nav>

<style>

  .icon{
    float: right;
  }

  .panel-block{
    display: block;
  }
</style>
