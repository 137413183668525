<script>
	import QPlot from "./QPlot.svelte";
	export let plot;
	export let width;
	export let height;
	export let movetext_func;
	export let endSelection_func;
	export let selection_func;
	export let click_func;
	plot.xformatTick = plot.hasOwnProperty("xticklabels")? d => plot.xticklabels[plot.xticks.indexOf(d)] : d => d;
	plot.yformatTick = plot.hasOwnProperty("yticklabels")? d => plot.yticklabels[plot.yticks.indexOf(d)] : d => d;
</script>

<div style="height:{height}px;width:{width}px;">
	<QPlot 
		{...plot} 
		on:movetext={movetext_func}
		on:endSelection={endSelection_func}
		on:selection={selection_func}
		on:click={click_func}
	/>
</div>