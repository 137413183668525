<script>
  // Import the getContext function from svelte
  import { getContext, onMount, createEventDispatcher } from 'svelte';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { data, xGet, yGet, xScale, yScale, padding } = getContext('LayerCake');

  export let gate;
  // Customizable defaults
  let g;
  let width;
  let dispatch = createEventDispatcher();
  let points;
  let line;
  let fill;
  export let active = false;
  export let selected = false;
  let live = null;


  function oMousePosSVG(e) {
    let svg = g.closest("svg");
    var p = svg.createSVGPoint();
    p.x = e.x;
    p.y = e.y;
    var ctm = svg.getScreenCTM().inverse();
    var p =  p.matrixTransform(ctm);
    return p;
  }

  function move_gate(e){
    if(live !== null){
      if(live === "all"){
        var x = e.movementX;
        var y = e.movementY;
        for(var i = 0; i < gate.length; i++){
          gate[i].x = $xScale.invert($xScale(gate[i].x) + x);
          gate[i].y = $yScale.invert($yScale(gate[i].y) + y);
        }

      }
      else{
        var p = oMousePosSVG({x: e.clientX-$padding.left, y: e.clientY-$padding.top});
        if(live == 0 || live == 1){
          gate[0].x = $xScale.invert(p.x);
        }
        else{
          gate[1].x = $xScale.invert(p.x);
        }
        if(live == 0 || live == 3){
          gate[0].y = $yScale.invert(p.y);
        }
        else{
          gate[1].y = $yScale.invert(p.y);
        }



      }

      gate = gate;
    }
  }

  function keypress(e){
    if(selected){
      switch(e.which){
        case 37:
          gate = gate.map((g)=>{
            g.x = g.x - 0.01;
            return g;
          })
          break;
        case 38:
          gate = gate.map((g)=>{
            g.y = g.y + 0.01;
            return g;
          })
          break;
        case 39:
          gate = gate.map((g)=>{
            g.x = g.x + 0.01;
            return g;
          })
          break;
        case 40:
          gate = gate.map((g)=>{
            g.y = g.y - 0.01;
            return g;
          })
          break;
        case 107:
        case 187:
          gate[0].x -= 0.01;
          gate[1].x += 0.01;
          gate[0].y -= 0.01;
          gate[1].y += 0.01;
          break;
        case 109:
        case 189:
          gate[0].x += 0.01;
          gate[1].x -= 0.01;
          gate[0].y += 0.01;
          gate[1].y -= 0.01;
          break;
      }
    }
  }


  function mousedown(e){
    if (!g.contains(e.target)) return;
    if(e.target.nodeName === "circle"){
      live = parseInt(e.target.getAttribute("index"))
    }
    else if(e.target.nodeName === "polygon"){
      live = "all";
    }
  }

  function mouseup(e){
    live=null;
    if (!g.contains(e.target)) return;
    if(e.target.nodeName === "circle" || e.target.nodeName === "polygon"){
      selected = !selected;
    }
  }

  $: points = [
    {x: $xScale(gate[0].x), y: $yScale(gate[0].y)},
    {x: $xScale(gate[0].x), y: $yScale(gate[1].y)},
    {x: $xScale(gate[1].x), y: $yScale(gate[1].y)},
    {x: $xScale(gate[1].x), y: $yScale(gate[0].y)}
  ];

  $: line = points.map((p)=>{
    return [p.x, p.y].join(",");
  }).join(" ");

  $: width = active? 3: 1;
  $: fill = active || selected? "#b15928": "#ff7f00";

  onMount(()=>{
    let svg = g.closest("svg");
    // svg.addEventListener("mousedown", start_draw);
    svg.addEventListener("mousemove", move_gate);
    svg.addEventListener("mouseup", mouseup);
    window.addEventListener("keydown", keypress)
  })

</script>

<g on:mousedown={mousedown} on:mouseover={()=>active=true} on:mouseout={()=>active=false} bind:this={g}>
  <polygon  points="{line}" stroke={fill} class="gate"></polygon>
  {#each points as p, i}
    <circle index={i} cx='{ p.x }' cy='{ p.y }' fill={fill} r={width} ></circle>
    <circle class="handle" index={i} cx='{ p.x }' cy='{ p.y }' r={8} ></circle>
  {/each}
</g>

<style>
  .gate{
    fill: transparent;
    stroke-width: 2;
  }

  .handle{
    stroke: transparent;
    fill: transparent;
    cursor: move;
  }

</style>
