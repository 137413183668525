<script>
  import { onMount, createEventDispatcher } from "svelte";
  import Loading from "./Loading.svelte";
  import DataTable from "./DataTable.svelte";
  export let analysis_id;
  export let analysis_name;
  let plots = [];
  let loading = false;
  let delete_modal = false;
  let delete_id = null;
  let delete_error = null;
  let delete_message = null;

  export let reload = false;
  $:{
    if(reload){
      get_plots();
    }
  }
  const dispatch = createEventDispatcher();

  async function get_plots(){
    var url = "/analysis/plots/"+analysis_id.toString();
    const response = await fetch(url);
    const json = await response.json();
    plots = json;
    reload = false;
    // var plot_set = [];
    // while (json.length) plot_set.push(json.splice(0,row_length));
    // plots = plot_set;
  }

  function open_delete_dialog(id){
    delete_id = id;
    delete_modal = true;
  }

  function delete_plot(){
    loading = true;
    window.$.ajax({
      url: "/analysis/"+analysis_id+"/delete-plot/"+delete_id,
      type: "DELETE",
      dataType: "json",
      success: function(data){
        delete_id = null;
        delete_modal = false;
        loading = false;
        get_plots();
      },
      error: function(jqXHR, textStatus, errorThrown){
        if (jqXHR.hasOwnProperty("responseJSON") && jqXHR.responseJSON.hasOwnProperty("error")){
          var message = jqXHR.responseJSON["error"];
        }
        else{
          var message = "Unexpected Error."
        }
        delete_message = message;

      }
    })
  }

  onMount(() => {
    get_plots();
  })
</script>
<div class="columns is-desktop is-multiline">
  {#each plots as plot}
  <div class="column is-narrow">
    <div class="plot-image">
    {#if plot.type == "image"}
      <i on:click={open_delete_dialog(plot.id)} class="fas fa-times"></i>
      <img src="data:image/png;base64, {plot.image}" alt="plot"/>
    {:else}
      <DataTable table={plot.table}></DataTable>
    {/if}
    </div>
  </div>
  {/each}
</div>
{#if delete_modal}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      {#if delete_error}
        <div class="notification is-danger">
          <button on:click={(e)=>{delete_error=null;}} class="delete"></button>
          {delete_error}
        </div>
      {/if}
      <div class="notification is-warning">
        <div class="title is-4">Are you sure you want to delete this plot?</div>
        <div class="warning-actions">
          <span><button on:click={()=>{delete_modal=false;delete_id=null;}} class="button qog-button">Don't Delete</button></span>
          <span><button on:click={delete_plot} class="button qog-button">Delete</button></span>
        </div>
      </div>
    </div>
</div>
{/if}

{#if loading}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <Loading></Loading>
    </div>
  </div>
{/if}
<style>
  .plot-header,
  .plot-row{
    flex: 1 0 auto;
    display: flex;
  }

  .plot-header{
    margin-left: 25%;
    font-size: 2em;
  }

  .plot-header > div{
    margin-right: 20px;
  }

  .plot-header-text{
    width: 500px;
  }

  .plot-column{
    flex-direction: column;
    display: flex;
  }

  .back{
    font-size: 2em;
  }

  .gating-modal{
    height: 800px;
    width: 1100px;
    background: white;
  }

  .plot-row > div{
    height: 450px;
    width: 400px;
  }

  .new-plot{
    border: 2px dashed black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .new-plot i{
    font-size: 5em;
  }

  .field-label,
  .control{
    text-align: center;
  }

  .label,
  .control{
    color: white;
  }

  label.radio:hover{
    color: #ddd;
  }

  .plot-image{
    width: 400px;
  }


  .plot-image i{
    float: right;
    cursor: pointer;
  }

  .show-all{
    float: right;
  }

</style>
