<script>
  import {HsvPicker} from 'svelte-color-picker';
  import {createEventDispatcher } from "svelte";
  import { onMount } from "svelte";
  import CellTypeTreeWithSearch from "./CellTypeTreeWithSearch.svelte";
  import Loading from "./Loading.svelte";
  import DataTable from "./DataTable.svelte";
  import QTable from './QTable.svelte';
  import Modal from "./Modal.svelte";
  import ExpandableCard from "./ExpandableCard.svelte";
  import InputField from "./InputField.svelte";
  import SelectField from "./SelectField.svelte";
  import MenuItem from "./MenuItem.svelte";
  import DropDown from "./DropDown.svelte";
  import BreadCrumbs from "./BreadCrumbs.svelte";
  import QPlot from "./QPlot.svelte";
  import {default as robustPointInPolygon} from "robust-point-in-polygon";
  import defaultScales from 'layercake/src/settings/defaultScales';
  export let width = 740;
  export let height = 740;
  export let assay_id;
  // export let spec_id;
  let disabled_text = "This is existing clustering save and preview disabled. If you want to overwrite first delete celltypes under gating and create new";
  let error_message = null;
  let default_point_size = 6;
  let save_disabled = false;
  let description;
  let color_list;
  let edit_color_table = {
    columns: [
      {id: "celltype",  value: "Celltype"},
      {id: "color", value: ""}
    ],
    rows: []
  };
  let edit_rename_table = {
    columns: [
      {id: "old_ctype", value: "Current Celltype Name"},
      {id: "new_ctype", value: "New Celltype Name"}
    ],
    rows: []
  }
  let active_color;
  let current_color;
  let edit_colors = false;
  let edit_celltype_name = false;
  let selected_celltype_row;
  let new_celltype_name;
  let show_menu = true;
  let selected_celltype;
  let celltypes = []
  let selected_method;
  let methods = [];
  let show_clust_params = false;
  let all_params = {};
  // let cluster_params = {'num_neigh': 4, 'num_neigh_predict': 4, 'de_thres': 0.1, 'max_dim': 30, 'resolution': 1.0};
  let selected_dist_metric;
  let dist_metrics = [];
  let selected_scale;
  let scales = [];
  let thres_top_mdn;
  let loading = false;
  let cluster_plot;
  let image;
  let xaxis;
  let yaxis;
  let ctree;
  let loaded = false;
  let gates = [];
  let open_rename = false;
  let open_rename_single = false;
  let rename_value = "";
  let hovered_celltype = null;
  let open_delete = false;
  let delete_celltype = null;
  let rename;
  export let clusterspec_id = null;
  export let samples;
  let compare_plot = null;
  let view_embed;
  let view_embed_opts;
  let selected_well_ids = [];
  let histoplots = [];
  let padding = {
    x: 10,
    y: 50
  };
  $: cluster_params = all_params[selected_method];
  let dispatch = createEventDispatcher();
  let stats_table = null;
  let sample_table = {
    columns: [
      {"id": "plate", "value": "Plate", "type": "text"},
      {"id": "well", "value": "Well", "type": "text"},
    ],
    rows: samples
  };

  let adt_table = {
    columns: [
      // {"id": "antibody", "value": "Antibody", "type": "text"},
      {"id": "value", "value": "Parameter", "type": "text"},
      // {"id": "count", "value": "# Positive Cells", "type": "number"},
    ],
    rows: []
  };

  let embed_table = {
    columns: [
      {"id": "value", "value": "Method", "type": "text"},
    ],
    rows: [
      {"value": "graph", "selected": true},
      {"value": "umap", "selected": true},
      {"value": "mds"},
      {"value": "tsne"}
    ]
  };

  let merge_celltypes_tooltip = "Select two or more celltypes in the tree below to merge";
  let rename_celltypes_tooltip = "Rename one or more celltypes";
  let change_celltype_colors_tooltip = "Change colors for one or more celltypes";

  let timestr;

  function set_timestr(time){
    if(time >= 5 && loading){
      var minutes = Math.floor(time / 60).toString();
      var seconds = (time % 60).toString();
      timestr =  minutes + ":" + (seconds < 10? "0": "") + seconds;
      time -= 1;
      setTimeout(()=>{
        set_timestr(time)
      }, 1000) 
    }
  }

  async function params(){
      loading = true;
      var paramFormData = new FormData();
      paramFormData.append("well_ids", selected_well_ids.join(","));
      var response = await fetch("/workflow/" + assay_id + "/gating/clustering/assay-cluster-params", {
        method: "POST",
        body: paramFormData
      });
      var json = await response.json();
      description = '';
      celltypes = json.celltypenames;
      selected_celltype = celltypes[0];
      // samples = json.samples;
      dist_metrics = json.dist_metrics;
      selected_dist_metric = dist_metrics[0];
      methods = json.methods.map(m => m.name);
      all_params = Object.assign({}, ...json.methods.map(m => ({[m.name]: m.params})));
      selected_method = methods[0];
      scales = json.scales;
      selected_scale = scales[0];
      cluster_params = json.cluster_params;
      adt_table.rows = json.parameters.map((r)=>{
        r.selected = true;
        return r;
      });
      if(clusterspec_id) {
        var response = await fetch("/workflow/" + assay_id + "/gating/clustering/" + clusterspec_id + "/load-cluster-def");
        if(!response.ok){
          error_message = "Unexpected Error."
          loading = false;
          setTimeout(clearErrorMsg, 3000);
          return;
        }
        var json = await response.json();
        description = json.description;
        selected_celltype = json.celltypenames[0];
        selected_well_ids = json.wells.map((s)=>{return s.well_id});
        selected_dist_metric = json.dist_metrics;
        selected_method = json.methods;
        selected_scale = json.scales;
        cluster_params = json.cluster_params;
        view_embed_opts = cluster_params["embedding_methods"];
        view_embed = cluster_params["embedding_methods"][0];
        color_list = json.colors;
        var loaded_params = Object.keys(json.cluster_params.col2name).map((k)=>{
          return json.cluster_params.col2name[k] + " | " + k;
        });
        adt_table.rows = adt_table.rows.map((r)=>{
          r.selected = loaded_params.indexOf(r.value) != -1;
          return r;
        })
        embed_table.rows = embed_table.rows.map((r)=>{
          r.selected = view_embed_opts.indexOf(r.value) != -1;
          return r;
        })
        cluster_plot = json.plot;
        xaxis = {
          "ticks": json.plot.xticks,
          "domain": json.plot.xDomain
        }
        yaxis = {
          "ticks": json.plot.yticks,
          "domain": json.plot.yDomain
        }
        default_point_size = json.plot.default_point_size;
        image = json.plot.points.map((p)=>{
          p.original_r = p.r;
          return p;
        });
        ctree = json.tree;
        get_compare_plot();
      }
      // response = await fetch("/workflow/" + assay_id + "/filter");
      // json = await response.json();
      // sample_table.rows = json.map((d) => {
      //   var is_checked = selected_sample_ids? selected_sample_ids.indexOf(d.id) != -1 : true;
      //   d.selected = is_checked;
      //   return d;
      // });


      // var formData = new FormData();
      // selected_sample_ids = sample_table.rows.filter(r => r.selected).map(r => r.id);
      // formData.append("sample_ids", selected_sample_ids.join(","));
      // response = await fetch("/adt/ab-stats", {
      //     method: "POST",
      //     body: formData
      // });
      // json = await response.json();
      //
      // console.log(json);
      // adt_table.rows = json.rows.map((r) => {
      //     return {'antibody': r.Antibody, 'adt': r.ADT, 'count': r['# Pos'], 'selected': true};
      // });
      loading = false;
  }

  async function get_compare_plot(){
    var formData = new FormData();
    if(compare_plot){
      formData.append("dimensions", JSON.stringify([compare_plot.xdim, compare_plot.ydim]))
    }
    if (gates.length){
      formData.append("celltypenames", JSON.stringify([...merge_celltypes]))
    }
    var response = await fetch("/workflow/"+assay_id+"/gating/clustering/"+clusterspec_id+"/compare-plot", {
      method: "POST",
      body: formData
    });
    var json = await response.json();
    compare_plot = json;
  }

  async function save_plot(){
    var color_dict = {};
    var to_parse = [ctree];
    while(to_parse.length){
      var node = to_parse.pop();
      color_dict[node.celltype] = node.color;
      if (node.data){
        to_parse = to_parse.concat(node.data)
      }
    }
    var formData = new FormData();
    formData.append("pop2color", JSON.stringify(color_dict));
    var response = await fetch('/api/export-jsonplot-image', {
      method: "POST",
      body: formData
    });
    const json = await response.json();
  }

  async function save(persist){
      if(description.trim() === '') {
          error_message = "Descrption is mandatory";
          setTimeout(clearErrorMsg, 3000);
          return;
      }
      loading = true;
      var formData = new FormData();
      var selected_adts = adt_table.rows.filter(r => r.selected).map(r => r.value).join(", ");
      var selected_embeds = embed_table.rows.filter(r => r.selected).map(r => r.value);
      if (selected_embeds.length === 0){
        error_message = "Embedding method is mandatory";
        setTimeout(clearErrorMsg, 3000);
        return;
      }
      formData.append("persist", persist);
      formData.append("description", description);
      // selected_sample_ids = sample_table.rows.filter(r => r.selected).map(r => r.id);
      formData.append("well_ids", selected_well_ids.join(","));
      formData.append("param_names", selected_adts);
      formData.append("celltypename", selected_celltype);
      formData.append("dist_metric", selected_dist_metric);
      formData.append("method", selected_method);
      formData.append("scale", selected_scale);
      formData.append("cluster_params", JSON.stringify(cluster_params));
      formData.append("embed_methods", JSON.stringify(selected_embeds));
      set_timestr(300);
      if(persist) {
          var formData = new FormData();
          formData.append("clusterspec_id", clusterspec_id);
          formData.append("parent_name", selected_celltype);
          formData.append("tree", JSON.stringify(ctree));
          formData.append("well_ids", selected_well_ids.join(","))
          const response = await fetch("/workflow/"+assay_id+"/gating/clustering/apply", {
            method: "POST",
            body: formData
          });
          if(!response.ok){
            loading = false;
            error_message = "Unexpected error during apply."
            setTimeout(clearErrorMsg, 3000);
            return ;
          }
          dispatch("savePlot");
          save_disabled = true;
      }
      else{
        var response = await fetch("/workflow/"+assay_id+"/gating/clustering/define", {
            method: "POST",
            body: formData
        });
        if(!response.ok){
          error_message = "Unexpected Error."
          loading = false;
          setTimeout(clearErrorMsg, 3000);
          return;
        }
        const json = await response.json();
        view_embed_opts = selected_embeds;
        view_embed = selected_embeds[0];
        clusterspec_id = json.clusterspec_id;
        var clusterresponse = await fetch("/workflow/"+assay_id+"/gating/clustering/"+clusterspec_id+"/load-cluster-def?embed_method="+selected_embeds[0])
        const clusterjson = await clusterresponse.json();
        cluster_plot = clusterjson.plot;
        color_list = clusterjson.colors;
        xaxis = {
          "ticks": clusterjson.plot.xticks,
          "domain": clusterjson.plot.xDomain
        }
        yaxis = {
          "ticks": clusterjson.plot.yticks,
          "domain": clusterjson.plot.yDomain
        }
        default_point_size = clusterjson.plot.default_point_size;
        image = clusterjson.plot.points.map((p)=>{
          p.original_r = p.r;
          return p;
        });
        ctree = clusterjson.tree;
        get_compare_plot();
      }
      loading = false;
  }

  let merge_celltypes = new Set();
  let merged_name;
  let get_merged_name = false;
  function celltype_toggle(e){
      const toggle_celltype = e.detail.celltype;
      if(merge_celltypes.has(toggle_celltype)) {
          merge_celltypes.delete(toggle_celltype);
      }
      else {
          merge_celltypes.add(toggle_celltype);
      }
      merge_celltypes = merge_celltypes;
      var highlight_points = [];
      image = image.filter(point => {
        if(merge_celltypes.has(point.celltype)){
          point.r = 2*point.original_r;
          point.opacity = 1.0;
          highlight_points.push(point);
          return false;
        }
        else{
          point.r = point.original_r;
          point.opacity = 0.5;
          return true;
        }
        // return point;
      })
      image = image.concat(highlight_points)
      gates = [];
  }

  function clearErrorMsg(){
    error_message = null;
  }

  function celltype_hover(e){
    hovered_celltype = e.detail;
  }

  function rename_celltype(){
    rename = hovered_celltype;
    open_rename_single = true;
    rename_value = rename.celltype;
  }

  function save_rename(){
    merge_celltypes = new Set();
    merge_celltypes.add(rename.celltype);
    merged_name = rename_value;
    open_rename_single = false;
    do_merge_celltypes();

  }

  function delete_cluster(){
    delete_celltype = hovered_celltype;
    open_delete = true;
  }

  async function perform_delete(){
    var formData = new FormData();
    formData.append("tree", JSON.stringify(ctree));
    formData.append("selection", JSON.stringify(delete_celltype));
    var response = fetch("/workflow/"+assay_id+"/gating/clustering/delete-cluster/" + clusterspec_id+"?embed_method="+view_embed, {
        method: "POST",
        body: formData
    }).then(response => response.json())
      .then(data => {
        delete_celltype = null;
        open_delete = false;
        if(data.hasOwnProperty("status") && data.status === "error"){
          error_message = data.data;
          setTimeout(clearErrorMsg, 3000);
          return;
        }
        ctree = data.tree;
        image = data.plot.points.map((p)=>{
          p.original_r = p.r;
          return p;
        });
        xaxis = {
          "ticks": data.plot.xticks,
          "domain": data.plot.xDomain
        }
        yaxis = {
          "ticks": data.plot.yticks,
          "domain": data.plot.yDomain
        }
        color_list = data.colors;
        stats_table = null;
        gates = [];
        merge_celltypes = new Set();
      });

  }

  async function do_merge_celltypes() {
      get_merged_name = false;
      loading = true;
      var formData = new FormData();
      formData.append("tree", JSON.stringify(ctree));
      formData.append("merge_cluster_list", JSON.stringify(Array.from(merge_celltypes)));
      formData.append("merged_name", merged_name);
      var response = await fetch("/workflow/"+assay_id+"/gating/clustering/"+clusterspec_id+"/merge-clusters?embed_method="+view_embed, {
          method: "POST",
          body: formData
      });
      if(!response.ok){
        error_message = "Unexpected Error."
        loading = false;
        setTimeout(clearErrorMsg, 3000);
        return;
      }
      const json = await response.json();
      clusterspec_id = json.clusterspec_id;
      xaxis = {
        "ticks": json.plot.xticks,
        "domain": json.plot.xDomain
      }
      yaxis = {
        "ticks": json.plot.yticks,
        "domain": json.plot.yDomain
      }
      image = json.plot.points.map((p)=>{
        p.original_r = p.r;
        return p;
      });
      ctree = json.tree;
      color_list = json.colors;
      loading = false;
      merge_celltypes = new Set();
      merged_name = "";
      stats_table = null;
      gates = [];
      get_compare_plot();
  }

  function set_selected_celltypes(node, selected_celltypes){
    node.selected = selected_celltypes.has(node.celltype);
    if(node.hasOwnProperty("data")){
      var new_data = [];
      for (var d of node.data){
        new_data.push(set_selected_celltypes(d, selected_celltypes))
      }
      node.data = new_data;
    }
    return node;
  }

  function debounce(func, wait=0, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  async function update_points(e){
    var new_merge_celltypes = new Set();
    image = image.map((p)=>{
      var pointInPoly = gates.map((g) => {return robustPointInPolygon(g, [p.x, p.y]) < 1;}).some(x => x);
      if(gates.length && pointInPoly){
        p.r = 1.5*p.original_r;
        p.opacity = 1.0;
        new_merge_celltypes.add(p.celltype);
      }
      else{
        p.r = p.original_r;
        if(gates.length){
          p.opacity = 0.5;
        }
        else{
          p.opacity = 1.0;
        }

      }

      return p;
    })
    ctree = set_selected_celltypes(ctree, new_merge_celltypes);
    merge_celltypes = new_merge_celltypes;
    stats_table=null;
  }

  function get_stats(){
    update_points();
    if(gates.length){
      var formData = new FormData();
      formData.append("celltypes", JSON.stringify([...merge_celltypes]))
      formData.append("embed_method", view_embed);
      // formData.append("selection", JSON.stringify(gate));
      var response = fetch("/workflow/"+assay_id+"/gating/clustering/"+clusterspec_id+"/cluster-stats", {
          method: "POST",
          body: formData
      }).then(response => response.json())
        .then(data => {
          data.rows = data.rows.map(r =>{
            r.selected = false;
            return r;
          })
          stats_table=data
        });
    }
    get_compare_plot()

  }

  function open_help(e){
    var help = document.getElementById("help-text");

    help.style.left = e.layerX + 15 +"px";
    // help.style.top = e.y +"px";
    help.style.display = "block"
  }

  function open_stats_help(e){
    console.log(e)
    var help = document.getElementById("stats-help-text");
    help.style.left = e.pageX + 15 +"px";
    help.style.display = "block"
  }

  function hide_help(e){
    var container = window.$("#help-text");
    var help_icon = window.$("#help-icon");
    if (!container.is(e.target) && container.has(e.target).length === 0 && !help_icon.is(e.target) && help_icon.has(e.target).length === 0){
      document.getElementById("help-text").style.display = "none";
    }


    if(stats_table){
      container = window.$("#stats-help-text");
      help_icon = window.$("#stats-help");
      if (!container.is(e.target) && container.has(e.target).length === 0 && !help_icon.is(e.target) && help_icon.has(e.target).length === 0){
        document.getElementById("stats-help-text").style.display = "none";
      }
    }

  }

  function  update_after_change(){
    clusterspec_id = null;
    stats_table = null;
    gates = [];
    image = null;
  }

  onMount(async ()=>{
    if(clusterspec_id){
      save_disabled = true;
    }
    sample_table.rows = samples;
    // params();
    loaded = true;
  })

  async function get_embedding(){
    if (view_embed){
      loading = true;
      var clusterresponse = await fetch("/workflow/"+assay_id+"/gating/clustering/"+clusterspec_id+"/load-cluster-def?embed_method="+view_embed)
      const clusterjson = await clusterresponse.json();
      cluster_plot = clusterjson.plot
      color_list = clusterjson.colors;
      xaxis = {
        "ticks": clusterjson.plot.xticks,
        "domain": clusterjson.plot.xDomain
      }
      yaxis = {
        "ticks": clusterjson.plot.yticks,
        "domain": clusterjson.plot.yDomain
      }
      default_point_size = clusterjson.plot.default_point_size;
      image = clusterjson.plot.points.map((p)=>{
        p.original_r = p.r;
        return p;
      });
      ctree = clusterjson.tree;
      get_compare_plot();
      loading = false;
      stats_table = null;
      gates = [];
    }

  }

  function download_stats_selection(){
    var form = window.$('<form>', {'method': 'POST', 'action': '/api/export-table-toexcel'}).hide();

    //Add params
    var params = { 
      'json_table': JSON.stringify(stats_table),
      'export_prefix': description
    };
    window.$.each(params, function (k, v) {
        form.append(window.$('<input>', {'type': 'hidden', 'name': k, 'value': v}));
    });

    //Make it part of the document and submit
    window.$('body').append(form);
    form.submit();

    //Clean up
    form.remove();
  }

  function download_stats_plot_table(){
    // if(show_plot){
      var form = window.$('<form>', {'method': 'POST', 'action': '/api/export-jsonplot-image'}).hide();
      //Add params
      cluster_plot["title"] = description;
      var params = { 
        'plot_dict': JSON.stringify(cluster_plot),
        'export_prefix': description
      };
    // }
    // else{
    //   var form = window.$('<form>', {'method': 'POST', 'action': '/api/export-table-toexcel'}).hide();

    // //Add params
    //   var params = { 
    //     'json_table': JSON.stringify(stats.table),
    //     'export_prefix': description
    //   };
    // }
    
    window.$.each(params, function (k, v) {
        form.append(window.$('<input>', {'type': 'hidden', 'name': k, 'value': v}));
    });

    //Make it part of the document and submit
    window.$('body').append(form);
    form.submit();

    //Clean up
    form.remove();
  }


  function rename_celltypes(){
    edit_rename_table.rows = Object.keys(ctree.data).map((k)=>{
      return {id: k, old_ctype: ctree.data[k]["celltype"], new_ctype: ctree.data[k]["celltype"]}
    });
    open_rename = true;
  }

  function open_edit_celltype(e){
    new_celltype_name = e.detail[0].new_ctype;
    selected_celltype_row = e.detail[0];
    edit_celltype_name = true;
  }

  function save_celltype_name(){
    selected_celltype_row["new_ctype"] = new_celltype_name;
    var i = edit_rename_table.rows.findIndex(o => o.id === selected_celltype_row.id);
    if (edit_rename_table.rows[i]) { edit_rename_table.rows[i] = selected_celltype_row } else { edit_rename_table.rows.push(selected_celltype_row) };
    edit_celltype_name = false;
  }

  async function save_rename_celltypes(){
    loading = true;
    open_rename = false;
    var formData = new FormData();
    formData.append("rename_celltype_map", JSON.stringify(edit_rename_table));
    var response = await fetch("/workflow/"+assay_id+"/gating/clustering/"+clusterspec_id+"/rename-clusters", {

      method: "POST",
      body: formData
    });
    const json = await response.json();
    clusterspec_id = json.clusterspec_id;
    xaxis = {
      "ticks": json.plot.xticks,
      "domain": json.plot.xDomain
    }
    yaxis = {
      "ticks": json.plot.yticks,
      "domain": json.plot.yDomain
    }
    image = json.plot.points.map((p)=>{
      p.original_r = p.r;
      return p;
    });
    ctree = json.tree;
    color_list = json.colors;
    loading = false;
    stats_table = null;
    gates = [];
    get_compare_plot();
  }

  function get_colors(){
    return color_list.reduce((acc, v)=>{
      acc[v.id] = [v.celltype, v.value];
      return acc;
    }, {});
  }

  function open_color_picker(){
    var color_picker_dict = get_colors();
    edit_color_table.rows = Object.keys(color_picker_dict).map((k)=>{
      return {id: k, celltype: color_picker_dict[k][0], color: {style: "background-color:"+color_picker_dict[k][1], text: ""}, value:color_picker_dict[k][1]}
    });
    edit_colors = true;
    update_set_all_colors();
  }


  async function save_colors(){
    loading = true;
    edit_colors = false;
    var formData = new FormData();
    var pop2color = edit_color_table.rows.reduce((acc, r) => {
      acc[r.celltype] = r.value;
      return acc;
    }, {});
    formData.append("colormap", JSON.stringify(pop2color));
    var response = await fetch("/workflow/"+assay_id+"/gating/clustering/"+clusterspec_id+"/update-cluster-colors", {

      method: "POST",
      body: formData
    });
    const json = await response.json();
    var data = json;
    ctree = data.tree;
    image = data.plot.points.map((p)=>{
      p.original_r = p.r;
      return p;
    });
    color_list = data.colors;
    loading = false;
    // save(true);

  }

  function set_edit_color(e){
    if (e){
      current_color = e.detail[0].value;
      active_color = [e.detail[0]];
    }
    else{
      var selected_rows = edit_color_table.rows.filter(r=>r.selected);
      if(selected_rows.length){
        current_color = selected_rows[0].value;
        active_color = selected_rows;
      }

    }

  }

  function perform_color_update(){
    current_color = "#" + ((1 << 24) + (current_color.r << 16) + (current_color.g << 8) + current_color.b).toString(16).slice(1);
    for(var j = 0; j < active_color.length; j++){
      for(var i = 0; i < edit_color_table.rows.length; i++){
        if(edit_color_table.rows[i].celltype == active_color[j].celltype){
          edit_color_table.rows[i].value = current_color;
          edit_color_table.rows[i].color.style = "background:"+current_color;
        }
      }
    }

    edit_color_table = edit_color_table;
    active_color = null;

  }

  let set_all_colors = false;
  let set_all_title = "Select at least two celltypes to set color for all.";

  function update_set_all_colors(){
    set_all_colors = edit_color_table.rows.filter(r=>r.selected).length >= 2;
    set_all_title = set_all_colors? "Set color for all selected rows." : "Select at least two celltypes to set color for all.";
  }

  async function update_histograms(){
    var antibodies = stats_table.rows.filter(r => r.selected).map(r => r.Antibody);
    var filtered_columns = stats_table.columns.filter(item => item.id !== "Antibody");
    let ordered_columns = filtered_columns.map(i => i.celltype);
    var formData = new FormData();
    formData.append("celltypes", JSON.stringify(ordered_columns))
    formData.append("antibodies", JSON.stringify(antibodies));
    var response = await fetch("/workflow/"+assay_id+"/gating/clustering/"+clusterspec_id+"/histograms", {
      method: "POST",
      body: formData
    })
    const json = await response.json();
    histoplots = json;
  }

  let sample_info;
  function update_sample_info() {
    sample_info = sample_table.rows.filter(r => r.selected).map(r => r.plate+ "-" + r.well).slice(0,2).join(", ") +
      (sample_table.rows.filter(r => r.selected).length > 2? "...": "");
    selected_well_ids =  sample_table.rows.filter((r) => {return r.selected}).map((r)=>{return r.well_id});
    params();
  }
  $: sample_table.rows, update_sample_info()

  let antibodies_info;
  function update_adt_info() {
    antibodies_info = adt_table.rows.filter(r => r.selected).map(r => r.value).slice(0,2).join(", ") +
      (adt_table.rows.filter(r => r.selected).length > 2? "...": "");
  }

  let embed_info;
  function update_embed_info(){
    embed_info = embed_table.rows.filter(r => r.selected).map(r => r.value).slice(0,2).join(", ") +
      (embed_table.rows.filter(r => r.selected).length > 2? "...": "");
  }
  $: view_embed, get_embedding();
  $: embed_table.rows, update_embed_info();
  $: adt_table.rows, update_adt_info()
  $: algorithm_info = [selected_method, selected_scale, selected_dist_metric].join(", ");
  $: if((sample_info || selected_celltype || algorithm_info || cluster_params || antibodies_info || embed_info) && !save_disabled && loaded) update_after_change();

  $: if(!loading) timestr = null;
</script>

<svelte:window on:click={hide_help} />
{#if error_message }
  <div class="notification is-danger">
    <button on:click={(e)=>{error_message=null;}} class="delete"></button>
    {error_message}
  </div>
{/if}
<BreadCrumbs></BreadCrumbs>
<br />
<div class="columns">
  <div class="column">
    <InputField label="Description:" bind:value={description} />
  </div>
  <div class="column">
    <label class="label">Embed Method:</label>
    <DropDown label={embed_info}>
      <QTable rows={embed_table.rows} columns={embed_table.columns} on:selection-changed={update_embed_info}
              searchable_columns={["method"]} show_checkboxes={true} />
    </DropDown>
  </div>
  <div class="column"></div>
</div>
<div class="columns is-desktop is-multiline">
  <div class="column">
    {#if samples.length > 0}
        <div>
            <label class="label">Samples:</label>
            <DropDown label={sample_info}>
              <QTable rows={sample_table.rows} columns={sample_table.columns} on:selection-changed={update_sample_info} show_checkboxes={true} />
            </DropDown>
        </div>
    {/if}
  </div>
  <div class="column">
    <div>
      <label class="label">Parameters:</label>
      <DropDown label={antibodies_info}>
        <QTable rows={adt_table.rows} columns={adt_table.columns} on:selection-changed={update_adt_info}
                searchable_columns={["value"]} show_checkboxes={true} />
      </DropDown>
    </div>
  </div>
  <div class="column"></div>
</div>
<div class="columns is-desktop is-multiline">
  <div class="column">
    <SelectField label="Input Celltype:" opts={celltypes} bind:value={selected_celltype} />
  </div>
  <div class="column">
    <div>
      <label class="label">Algorithm:</label>
      <DropDown label={algorithm_info}>
          <span id="help-icon" on:click={open_help} class="help-info"><i class="fas fa-question-circle"></i></span>
          <div id="help-text" class="help-text">
            <div>
              <span class="ref-link" on:click={()=>{window.open("https://www.nature.com/articles/s41598-019-41695-z", '_blank')}}>Leiden:</span>
              <span>From Louvain to Leiden: guaranteeing well-connected communities, V. A. Traag, L. Waltman & N. J. van Eck</span>
            </div>
            <br />
            <div>
              <span class="ref-link" on:click={()=>{window.open("https://academic.oup.com/bioinformatics/article/36/9/2778/5714737", '_blank')}}>PARC:</span>
              <span>ultrafast and accurate clustering of phenotypic data of millions of single cells, Shobana V Stassen, Dickson M D Siu, Kelvin C M Lee, Joshua W K Ho, Hayden K H So, Kevin K Tsia</span>
            </div>
          </div>
          <SelectField label="Method:" opts={methods} bind:value={selected_method} />
          <SelectField label="Scale:" opts={scales} bind:value={selected_scale} />
          <SelectField label="Distance Metric:" opts={dist_metrics} bind:value={selected_dist_metric} />
            {#if selected_method == "parc"}
              <InputField label="Num Neighbors:" type="number" bind:value={cluster_params.num_neigh} />
              <InputField label="Resolution:" type="number" step="0.1" bind:value={cluster_params.resolution} />
              <InputField label="Small Pop:" type="number" step="1" bind:value={cluster_params.small_pop} />
            {:else if selected_method == "leiden"}
              <InputField label="Num Neighbors:" type="number" bind:value={cluster_params.num_neigh} />
            {:else if selected_method == "flowsom"}
              <InputField label="Grid Size:" type="number" step="1" bind:value={cluster_params.grid_size} />
              <InputField label="Num Clusters:" type="number" step="1" bind:value={cluster_params.n_clusters} />
            {:else if selected_method == "agglomerative"}
              <InputField label="Min Distance:" type="number" step="0.1" bind:value={cluster_params.min_dist} />
              <SelectField label="Linkage:" opts={cluster_params.linkage_opts} bind:value={cluster_params.linkage} />
            {/if}
      </DropDown>
    </div>
  </div>
  <div class="column"></div>
</div>
<br />
<div class="control">
    {#if clusterspec_id }
      <button title={save_disabled? disabled_text: 'Apply to Wells'}  disabled={save_disabled} on:click={(e)=>{save(true)}} class="button qog-button">Apply to Wells</button>
    {:else}
      <button title={save_disabled? disabled_text: 'Preview'} disabled={save_disabled} on:click={(e)=>{save(false)}} class="button qog-button">Run Clustering</button>
    {/if}
</div>
<br />
<hr />
<div class="qcontainer">
  {#if image}
    <div class="left-column">
      {#if ctree}
        <div class="celltype-container">
          <CellTypeTreeWithSearch on:hover={celltype_hover} show_hover_menu={true} show_search_context={false} select={true} on:toggle={celltype_toggle} node={ctree} {show_menu}>
              <span slot="menu-items">
                  <MenuItem menuitem_tooltip={merge_celltypes_tooltip} icon="fa fa-copy" on:click="{() => {get_merged_name=true}}">Merge Celltypes</MenuItem>
                  <MenuItem menuitem_tooltip={rename_celltypes_tooltip} icon="fas fa-edit" on:click={rename_celltypes}>Rename Celltypes</MenuItem>
                  <MenuItem menuitem_tooltip={change_celltype_colors_tooltip} icon="fas fa-paint-brush" on:click={open_color_picker}>Change Celltype Colors</MenuItem>
              </span>
              <span slot="hover-menu-items">
                  <MenuItem icon="fa fa-edit" on:click="{rename_celltype}">Rename</MenuItem>
                  <MenuItem icon="fa fa-trash" on:click="{delete_cluster}">Delete Cluster</MenuItem>
              </span>
          </CellTypeTreeWithSearch>
        </div>
      {/if}
    </div>
    <div class="middle-column">
      <div class="plot-header-options">
        <div>
          <SelectField label="Embedding:" opts={view_embed_opts} bind:value={view_embed} />
        </div>
        <div>
          <button title="Save" on:click={download_stats_plot_table} class="button"><i class="fas fa-save"></i></button>
        </div>
      </div>
      <div class="wrapper">
        <div class="plot-wrapper" style="width:{width}px;height:{height}px;left:{padding.x}px;top:{padding.y}px;">
          <QPlot
            bind:data={image}
            xDomain={xaxis.domain}
            yDomain={yaxis.domain}
            xformatTick={d=>""}
            yformatTick={d=>""}
            xticks={xaxis.ticks}
            yticks={yaxis.ticks}
            bind:gates
            on:endSelection={get_stats}
            on:selection={debounce(update_points, 5)}
            selection={true}
            plot_type={view_embed === 'umap' || view_embed === 'tsne'?"canvas": "svg"}
          />
        </div>    
      </div>
    </div>
    <div class="right-column">
      {#if stats_table}
        <span id="stats-help" on:click={open_stats_help} class="stats-help"><i class="fas fa-question-circle"></i></span>
        <div id="stats-help-text" class="help-text">
          <div>
            <span>The table displays MFI values for every input marker in the clusters selected using lasso. Click on checkbox to display histogram for the selected marker</span>
          </div>
        </div>
        <button style="font-size:0.75em;float:right;" title="Download" on:click={download_stats_selection} class="button"><i class="fas fa-download"></i></button>
        <div class="stats-table">
          <QTable show_checkboxes={true} title={stats_table.title} rows={stats_table.rows} bind:columns={stats_table.columns} on:selection-changed={update_histograms} rotate_header_angle="180deg" />
        </div>
      {/if}
    </div>
  {/if}

</div>

<div class="histogram-container">
  <div class="columns is-desktop is-multiline">
    <div class="column is-narrow">
      <div class="compare-plot">
      {#if compare_plot}
          <DropDown label={compare_plot.title}>
            <SelectField label="X Dimension" opts={compare_plot.dimensions_options} bind:value={compare_plot.xdim} />
            <SelectField label="Y Dimension" opts={compare_plot.dimensions_options} bind:value={compare_plot.ydim} />
            <button on:click={get_compare_plot} class="button qog-button">Update</button>
          </DropDown>
          <div style="height:350px;width:350px;">
            <QPlot
              bind:data={compare_plot.data}
              xDomain={compare_plot.xDomain}
              yDomain={compare_plot.yDomain}
              xformatTick={d=>compare_plot.xticklabels[compare_plot.xticks.indexOf(d)]}
              yformatTick={d=>compare_plot.yticklabels[compare_plot.yticks.indexOf(d)]}
              xticks={compare_plot.xticks}
              yticks={compare_plot.yticks}
            />
          </div>
      {/if}
      </div>
    </div>
    {#each histoplots as plot}
      <div class="column is-narrow">
        <div class="compare-plot">
          <img src="data:image/png;base64, {plot}" alt="plot"/>
        </div>
      </div>
    {/each}
  </div>
</div>

<div class:is-active={loading} class="modal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <Loading></Loading>
    {#if timestr}
      <div style="color:white;text-align: center;">Approximately {timestr} remaining.</div>
    {/if}
  </div>
</div>

<Modal bind:show_modal={get_merged_name}>
    <div class="modal-label">Merge {Array.from(merge_celltypes).join(", ")} To:</div>
    <input class="input" type="text" bind:value={merged_name}/>
    <button on:click={do_merge_celltypes} class="button qog-button is-fullwidth">Merge</button>
</Modal>

<Modal bind:show_modal={open_rename_single}>
  <div>
    <input class="input" type="text" bind:value={rename_value}/>
  </div>
  <div>
    <button class="button qog-button" on:click={save_rename}>Save</button>
  </div>
</Modal>

<div class="modal" class:is-active={open_delete}>
  <div class="modal-background"></div>
  <div class="gating-modal modal-content">
    <div class="notification is-warning">
      <div class="title is-4">Deleting {delete_celltype? delete_celltype.celltype: ""}?</div>
      <div class="warning-actions">
        <span><button on:click={()=>{open_delete=false;delete_celltype=null;}} class="button qog-button">Don't Delete</button></span>
        <span><button on:click={perform_delete} class="button qog-button">Delete</button></span>
      </div>
    </div>

  </div>
</div>

{#if open_rename}
<div class="modal is-active">
  <div class="modal-background"></div>
  <button on:click="{()=>open_rename=false}" class="modal-close is-large" aria-label="close"></button>
  <div style="width:800px;" class="modal-content">
    <div style="overflow:auto; max-height:800px;">
      <QTable widths={{'old_ctype': '400px', 'new_ctype': '400px'}} on:selection-changed={update_set_all_colors} rows={edit_rename_table.rows} columns={edit_rename_table.columns} on:cell-clicked={open_edit_celltype} />
    </div>
    <span><button on:click={save_rename_celltypes} class="button qog-button">Save</button></span>
  </div>
</div>
{/if}

{#if edit_celltype_name}
<div class="modal is-active">
  <div class="modal-background"></div>
  <button on:click="{()=>edit_celltype_name=false}" class="modal-close is-large" aria-label="close"></button>
  <div style="width:800px;" class="modal-content">
    <input id="celltype_input" class="input" type="text" bind:value={new_celltype_name}/>
    <button on:click={save_celltype_name} class="button qog-button">Save</button>
  </div>
</div>
{/if}

{#if edit_colors}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <button on:click="{()=>edit_colors=false}" class="modal-close is-large" aria-label="close"></button>
    <span><button on:click={()=>{set_edit_color()}} title={set_all_title}  disabled={!set_all_colors} class="button qog-button" style="color:#000">Set For All Selected</button></span>
    <div style="width:800px;" class="modal-content">
      <div style="overflow:auto; max-height:800px;">
        <QTable widths={{'celltype': '750px', 'color': '50px'}} on:selection-changed={update_set_all_colors} show_checkboxes={true} rows={edit_color_table.rows} columns={edit_color_table.columns} on:cell-clicked={set_edit_color} />
      </div>
      <span><button on:click={save_colors} class="button qog-button">Save</button></span>
    </div>
  </div>
{/if}

{#if active_color}
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="color-modal modal-content">
      <div class="title is-4">Setting Color for {active_color.length > 1? active_color.length + " celltypes": active_color[0].celltype}</div>
      <button on:click="{()=>{active_color=null}}" class="modal-close is-large" aria-label="close"></button>
      <HsvPicker on:colorChange={rgba=>{current_color=rgba.detail;}} startColor={current_color}/>
      <br />
      <button on:click={perform_color_update} class="button qog-button">Update</button>
    </div>
  </div>
{/if}

<style>

  .container{
    display: flex;
    flex: 1 0 auto;
  }

  .controls{
    width: 400px;
    margin-left: 10px;
    margin-top: 50px;
  }

  .controls > div{
    margin-bottom: 20px;
  }

  .save{
    position: absolute;
    right: 50px;
    width: 50px;
    margin-top: 5px;
  }

  .modal-label {
      color: white;
  }

  .back{
    float: right;
    font-size: 2em;
    cursor: pointer;
  }

  .celltype-container{
    min-width: 300px;
  }

  .controls-column{
    min-width: 430px;
  }

  .actions-container{
    margin-bottom: 20px;
  }

  .stats-help{
    cursor: pointer;
  }

  .help-info{
    float: right;
    cursor: pointer;
  }

  .help-text{
    position: absolute;
    display: none;
    z-index: 9999;
    border: 1px solid black;
    width: 350px;
    background-color: white;
    padding: 5px;
  }

  .ref-link{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }


  .top-level{
    padding-right: 20px;
  }

  .qcontainer {
    display: flex;
    padding-bottom: 40px;
  }

  .left-column {
    flex: 0 0 auto;
    border-right: solid whitesmoke;
    padding-right: 10px;
    min-width: 300px;
    max-width: 330px;
  }

  .middle-column {
    flex: 0 0 auto;
    border-right: solid whitesmoke;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 760px;
    max-width: 760px;
  }

  .right-column {
    flex: 0 0 auto;
    padding-left: 10px;
  }

  .plot-header-options{
    flex: 1 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .histogram-container{
    min-width: 1700px;
    max-width: 1700px;
  }

  .qog-button{
    background: var(--ryvett-grey-blue);
    color: white;
  }

  .right-column,
  .left-column,
  .middle-column{
    /* height: 800px; */
    max-height: 800px;
    /* min-height: 800px; */
    overflow: auto;
  }

  .qcontainer{
    min-height: 740px;
  }

  .compare-plot{
    height: 400px;
    max-height: 400px;
    overflow: auto;
  }

  .stats-table{
    /* height: 25em; */
    /* max-height: 40em; */
    overflow: visible;
  }
</style>
