<script>
  // Import the getContext function from svelte
  import { getContext, onMount, createEventDispatcher } from 'svelte';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { data, xGet, yGet, xScale, yScale } = getContext('LayerCake');
  // Customizable defaults
  let g;
  let r = 5;
  let fill = [0, 0, 0, 1];
  let dispatch = createEventDispatcher();


  function componentToHex(c) {
    c = c*255;
    var hex = c.toString(16).split(".")[0];
    return hex.length == 1 ? "0" + hex : hex;
  }


  function get_fill(d){
    var fill = d.hasOwnProperty("fill")? d.fill: fill;
    if(typeof fill === "string") return fill;
    return "#" + componentToHex(fill[0]) + componentToHex(fill[1]) + componentToHex(fill[2]) + (d.hasOwnProperty("opacity")? componentToHex(d.opacity): "FF");
  }

  function get_r(d){
    var radius = d.hasOwnProperty("r")? d.r: r;
    return d.hover? 1.5*radius: radius;
  }


</script>

<g bind:this={g}>
  {#each $data as d}
    {#if !d.hasOwnProperty("type") || d.type == 'o'}
      <circle on:click={(e)=>{dispatch("click", [d])}} cx='{ $xGet(d) }' cy='{ $yGet(d) }' fill={get_fill(d)} r={get_r(d)} >
        {#if d.metadata}
          <title>{d.metadata}</title>
        {/if}
      </circle>
    {:else if d.type == 'v'}
      <polygon on:click={(e)=>{dispatch("click", [d])}} points='{ $xGet(d) - get_r(d) },{ $yGet(d) - get_r(d) } { $xGet(d) + get_r(d) },{ $yGet(d) - get_r(d) } { $xGet(d) },{ $yGet(d) + get_r(d) }'  fill={get_fill(d)}>
        {#if d.metadata}
          <title>{d.metadata}</title>
        {/if}
      </polygon>
    {:else if d.type == '^'}
      <polygon on:click={(e)=>{dispatch("click", [d])}} points='{ $xGet(d) - get_r(d) },{ $yGet(d) + get_r(d) } { $xGet(d) + get_r(d) },{ $yGet(d) + get_r(d) } { $xGet(d) },{ $yGet(d) - get_r(d) }'  fill={get_fill(d)}>
        {#if d.metadata}
          <title>{d.metadata}</title>
        {/if}
      </polygon>
    {:else if d.type == 's'}
      <rect on:click={(e)=>{dispatch("click", [d])}} x='{ $xGet(d) - get_r(d) }' y='{ $yGet(d) - get_r(d) }' fill={get_fill(d)} width={get_r(d)*2} height={get_r(d)*2} >
        {#if d.metadata}
          <title>{d.metadata}</title>
        {/if}
      </rect>
    {:else if d.type == 'D' || d.type == 'd'}
      <rect on:click={(e)=>{dispatch("click", [d])}} x='{ $xGet(d) - get_r(d) }' y='{ $yGet(d) - get_r(d) }' fill={get_fill(d)} width={get_r(d)*2} height={get_r(d)*2} transform="rotate(45,{ $xGet(d) }, { $yGet(d) })" >
        {#if d.metadata}
          <title>{d.metadata}</title>
        {/if}
      </rect>
    {:else if d.type == '+'}
      <polygon on:click={(e)=>{dispatch("click", [d])}} stroke-width='{get_r(d)/2}' stroke={get_fill(d)} points='{ $xGet(d) },{ $yGet(d) } { $xGet(d) - get_r(d) },{ $yGet(d) } { $xGet(d) + get_r(d) },{ $yGet(d) } { $xGet(d) },{ $yGet(d) } { $xGet(d) },{ $yGet(d) + get_r(d) } { $xGet(d) },{ $yGet(d) - get_r(d) }'  fill={get_fill(d)}>
        {#if d.metadata}
          <title>{d.metadata}</title>
        {/if}
      </polygon>
    {:else if d.type == 'X' || d.type == 'x'}
      <polygon on:click={(e)=>{dispatch("click", [d])}} transform="rotate(45,{ $xGet(d) }, { $yGet(d) })" stroke-width='{get_r(d)/2}' stroke={get_fill(d)} points='{ $xGet(d) },{ $yGet(d) } { $xGet(d) - get_r(d) },{ $yGet(d) } { $xGet(d) + get_r(d) },{ $yGet(d) } { $xGet(d) },{ $yGet(d) } { $xGet(d) },{ $yGet(d) + get_r(d) } { $xGet(d) },{ $yGet(d) - get_r(d) }'  fill={get_fill(d)}>
        {#if d.metadata}
          <title>{d.metadata}</title>
        {/if}
      </polygon>
    {/if}
  {/each}
</g>

<style></style>
