<script>
  import { onMount } from "svelte";
  import QTable from './QTable.svelte';
  import QPlot from './QPlot.svelte';
  import AJAXFetch from "./AJAXFetch.svelte";
  import ABTitrAdd from "./ABTitrAdd.svelte";
  import Modal from "./Modal.svelte";
  import InputField from "./InputField.svelte";
  import Loading from "./Loading.svelte";
  import GatingPlotContainer from "./GatingPlotContainer.svelte";
  import AbTitrModal from "./ABTitrModal.svelte";

  export let wells;

  let add_abtitr = false;
  let modal_style = "background:white;width:600px;padding:1em";
  let loading = false;
  let table;
  let table_url = "/lab-ops/ab-titration/get-all-ab-titers";
  let plot_size = "325px";

  let plot_url = "/lab-ops/ab-titration/get-ab-titer-data";
  let plot1;
  let plot1_url;
  let plot1_data;

  let plot2;
  let plot2_url;
  let plot2_data;

  let titer_table_url;
  let titer_table;


  let select_titer_id;

  let status_message = null;

  let edit_titer_params = false;
  $: select_str2var = {
    "pos_celltype": pos_celltype,
    "neg_celltype": neg_celltype,
    "antibody": antibody,
    "fluorophore": fluorophore,
    "paramlabel": paramlabel,
    "vendor": vendor,
    "lotnum": lotnum,
    "max_conc_formatted": max_conc,
    "dilution": dilution
  }

  let well_table = {
    columns: [
      {"id": "plate", "value": "Plate", "type": "text"},
      {"id": "well", "value": "Well", "type": "text"},
    ],
    rows: wells
  };

  let live_well_id;

  let active_well_ids;

  $: active_well_ids =(titer_table && titer_table.hasOwnProperty("rows"))? titer_table.rows.map(r=>r.well_id) : [];

  function get_plots(e){
    if(e){
      table = e.detail;
      if(table.rows.length > 0) {
        var row = select_titer_id ? table.rows.find(r => r.id == select_titer_id) : table.rows[0];
        row.selected = true;
      }
    }
    const selected_ab_titer_ids = table.rows.filter(r => r.selected).map(r => r.id);
    if(selected_ab_titer_ids.length == 0) {
       plot1_url = "";
       plot2_url = "";
       titer_table_url = "";
       return;
    }

    const ids_string = selected_ab_titer_ids.join(",");
    var data1 = new FormData();
    data1.append("plot_type", "MFI");
    data1.append("ab_titer_ids", ids_string);
    plot1_data = data1
    plot1_url = plot_url;

    var data2 = new FormData();
    data2.append("plot_type", "StainIndex");
    data2.append("ab_titer_ids", ids_string);
    plot2_data = data2;
    plot2_url = plot_url;

    titer_table_url = "/lab-ops/ab-titration/get-ab-titer-metric-data/" + ids_string;
  }

  async function get_params(){
    var gd_ids = well_table.rows.filter(r=>r.selected).map(r=>r.gatedataset_id);
    if(gd_ids.length){
      var formData = new FormData();
      formData.append("gd_ids", JSON.stringify(gd_ids))
      var response = await fetch("/lab-ops/ab-titration/params", {
        method: "POST",
        body: formData
      });
      var json = await response.json();
      celltypes = json.celltypes;
      pos_celltype = json.celltypes[0].value;
      neg_celltype = json.celltypes[0].value;
      paramlabels = json.paramlabels;
      selected_paramlabel = json.paramlabels[0].value;
    }

  }

  let titration_data;
  async function add_new_titration() {
      // var response = await fetch("/lab-ops/add-or-edit-titer-expt", {method: "GET"});
      titration_data = null;
      add_abtitr = true;
  }


  function handle_table_click(e){
    if (e.detail[1].id === "OptimalConc"){
      opt_conc = parseFloat(e.detail[0]["OptimalConc"]);
      edit_conc_id = e.detail[0].id;
      enter_conc = true;
    }
  }

  let enter_conc = false;
  let opt_conc;
  let edit_conc_id;
  let show_gating = false;

  async function update_conc(){
    loading = true;
    table_url = null;
    var formData = new FormData();
    formData.append("optimal_conc", opt_conc);
    var response = await fetch("/lab-ops/ab-titration/set-ab-optim-conc/"+edit_conc_id, {
      method: "POST",
      body: formData
    });
    var json = await response.json();
    table_url = "/lab-ops/ab-titration/get-all-ab-titers";
    select_titer_id = edit_conc_id;
    enter_conc = false;
    loading = false;
  }

  let assay_id;
  let selected_well_ids;
  let paramlabels = [];
  let celltypes = [];
  let paramlabels_opts = [];
  let selected_paramlabel;
  let max_conc = 0;
  let dilution = 1;
  let pos_celltype;
  let neg_celltype;
  let antibodies = [];
  let antibody;
  let fluorophores = [];
  let fluorophore;
  let lotnum;
  let vendors = [];
  let vendor;
  let lots = [];
  let units = [];
  let max_conc_unit;
  let paramlabel;
  let celltypes_opts = [];
  let well_info;
  let well_order;
  function update_well_info() {
    well_info = well_table.rows.filter(r => r.selected).map(r => r.plate+ "-" + r.well).slice(0,2).join(", ") +
      (well_table.rows.filter(r => r.selected).length > 2? "...": "");

    get_params();
  }
  $: well_table.rows, update_well_info();
  $: celltypes_opts = celltypes.map(c=>c.value);
  $: paramlabels_opts = paramlabels.map(pl=>pl.value);

  async function download_abrun_excel() {
    var selected_rows = [];
    table.rows.map(function(r){
      if(r.selected == true){
        selected_rows.push(r.id)
      }
    });
    selected_rows = selected_rows.join(",");
    var link = document.createElement('a');
    link.href = "/lab-ops/ab-titration/export-abrun-report/" + selected_rows;
    link.click();
    link.remove();
  }

  function edit_titration(row) {
    titration_data = row;
    add_abtitr = true;
    }

  function delete_abtiter_popup() {
    var selected_rows = [];
    table.rows.map(function(r){
      if(r.selected == true){
        selected_rows.push(r)
      }
    });
    delete_selected = selected_rows;
  }

  function format_delete_message(type){
    var typelist = delete_selected.map(r => r[type]);
    return typelist.join(", ")
  }

  let delete_selected = false;
  let error_message;
  async function delete_abtitration(e) {
      e.target.disabled = true;
      var delete_ids = delete_selected.map(r => r.id);
      delete_ids = delete_ids.join(",");
      fetch("/lab-ops/ab-titration/delete-abtiter/" + delete_ids, {
          method: "DELETE"
      })
      .then((response) => {
          if(!response.ok){
              error_message = "Error deleting Antibody Titration";
              e.target.disabled = false;
          }
          else{
              window.location.reload();
          }
      });
  }

  function update_select_titer(e){
    if(e && e.detail.hasOwnProperty("id")){
      select_titer_id = e.detail.id;
    }
    else if(e && e.detail.length){
      select_titer_id = e.detail[0].id;
    }
    if(plot1){
      plot1.points = plot1.points.map((p)=>{
        if(!p.hasOwnProperty("original_r")){
          p.original_r = p.r;
        }
        p.r = p.id==select_titer_id?p.original_r*1.5:p.original_r;
        return p;
      });
    }
    if(plot2){
      plot2.points = plot2.points.map((p)=>{
        if(!p.hasOwnProperty("original_r")){
          p.original_r = p.r;
        }
        p.r = p.id==select_titer_id?p.original_r*1.5:p.original_r;
        return p;
      });
    }
    
    
  }

  function update_titer_table(e){
    titer_table = e.detail;
    if(!select_titer_id){
      update_select_titer({detail: {id: titer_table.rows[0].id}})
    }
  }

  async function download_abtiter(){
    status_message = "Starting Download";
    var selected_rows = [];
    table.rows.map(function(r){
      if(r.selected == true){
        selected_rows.push(r.id)
      }
    });
    selected_rows = selected_rows.join(",");
    var formData = new FormData();
    formData.append("ab_titer_ids", selected_rows);
    var response = await fetch("/lab-ops/ab-titration/create-ab-report", {
      method: "POST",
      body: formData
    });
    const theBlob = await response.blob(); 
    const blob = new Blob([theBlob], {type: response.headers.content_type});
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
    document.body.appendChild(a);
    a.click();
    status_message = null;
  }

</script>

<div class="qog_banner_toolbar webix_toolbar webix_layout_toolbar">
  <div class="webix_list-x crumbs">
    <div class="webix_list_item"><a href='{window.location.pathname.split("/").splice(0, 2).join("/")}'>Lab Operations</a></div>
    <div class="webix_list_item"><a href='{window.location}'>AntiBody Titrations</a></div>
    {#if add_abtitr}
      <div class="webix_list_item"><a href='#'>Add New AntiBody Titrations</a></div>
    {/if}
  </div>
  {#if !add_abtitr}
    <button class="qog-button" style="font-size:inherit; margin-left:auto" on:click={add_new_titration}>Add Titration</button>
  {:else}
    <button class="qog-button" style="font-size:inherit; margin-left:auto" on:click={()=>{add_abtitr = false;}}>Back</button>
  {/if}
</div>

{#if status_message}
<div class="notification is-success">
  <button on:click={(e)=>{status_message=null;}} class="delete"></button>
  {status_message}
</div>
{/if}


{#if add_abtitr}
  <ABTitrAdd  titration_data={titration_data}/>
{:else}
  <div>
    <div class="columns">
      <div class="column">
        <AJAXFetch bind:url={table_url} method="GET" on:fetched={get_plots}>
          <span slot="success">
            <div class="download-button">
              <span title="Download gating report for selected Antibody Titers" on:click={download_abtiter}>
                <i class="fas fa-download" />
              </span>
              <span title="Export To Excel" on:click={() => download_abrun_excel()}>
                <i class="fas fa-file-excel" />
              </span>
              <span title="Delete Titration" on:click={() => {delete_abtiter_popup()}}>
                <i class="fas fa-trash" />
              </span>
            </div>
            <div class="table-container">
              <QTable
                rows={table.rows}
                columns={table.columns}
                title={table.title}
                on:cell-clicked={handle_table_click}
                searchable_columns={["Antibody", "Fluorophore", "Lot", "Vendor"]}
                show_checkboxes={table.rows.length > 0}
                on:selection-changed={(e) => {get_plots()}}
              >
              <span slot="actions" let:row>
                <span title="Edit Titration" on:click={() => edit_titration(row)}>
                  <i class="fas fa-edit" />
                </span>
              </span>
              </QTable>
            </div>
          </span>
        </AJAXFetch>
      </div>
    </div>
    <hr style="margin:0;">
    <div class="columns is-1">
      <div class="column is-narrow">
        <AJAXFetch bind:url={titer_table_url} method="GET" on:fetched={update_titer_table}>
          <span slot="success">
            <div class="table-container" style="width:690px;">
              <QTable
                rows={titer_table.rows}
                columns={titer_table.columns}
                title={titer_table.title}
                on:row-clicked={update_select_titer}
                highlight_row={{id:select_titer_id}}
                />
            </div>
          </span>
        </AJAXFetch>
      </div>
      <div class="column is-narrow">
        <AJAXFetch bind:url={plot1_url} bind:post_data={plot1_data} method="POST" on:fetched={(e)=>{plot1=e.detail; update_select_titer();}}>
          <span slot="success">
            <div class="plot">
              <QPlot
                bind:data={plot1.points}
                xDomain={plot1.xDomain}
                yDomain={plot1.yDomain}
                xticks={plot1.xticks}
                yticks={plot1.yticks}
                xlabel={plot1.xlabel}
                ylabel={plot1.ylabel}
                lines={plot1.lines}
                xformatTick={(d)=>{return plot1.xticklabels[plot1.xticks.indexOf(d)]}}
                yformatTick={(d)=>{return plot1.yticklabels[plot1.yticks.indexOf(d)]}}
                xgridlines={true}
                ygridlines={true}
                padding={{ top: 0, right: 0, bottom: 40, left: 25 }}
                plot_type="svg"
                on:click={update_select_titer}
              />
            </div>
          </span>
        </AJAXFetch>
      </div>
      <div class="column is-narrow">
        <AJAXFetch bind:url={plot2_url} bind:post_data={plot2_data} method="POST" on:fetched={(e)=>{plot2=e.detail; update_select_titer();}}>
          <span slot="success">
            <div class="plot">
              <QPlot
                bind:data={plot2.points}
                xDomain={plot2.xDomain}
                yDomain={plot2.yDomain}
                xticks={plot2.xticks}
                yticks={plot2.yticks}
                xlabel={plot2.xlabel}
                ylabel={plot2.ylabel}
                xformatTick={(d)=>{return plot2.xticklabels[plot2.xticks.indexOf(d)]}}
                yformatTick={(d)=>{return plot2.yticklabels[plot2.yticks.indexOf(d)]}}
                lines={plot2.lines}
                xgridlines={true}
                ygridlines={true}
                padding={{ top: 0, right: 0, bottom: 40, left: 25 }}
                plot_type="svg"
                on:click={update_select_titer}
              />
            </div>
          </span>
        </AJAXFetch>
      </div>
    </div>
    {#if select_titer_id}
      <hr style="margin:0;">
      <div style="margin-top:12px;">
        <GatingPlotContainer bind:titr_id={select_titer_id}  size={plot_size}/>
      </div>
    {/if}
  </div>
{/if}


<Modal style={modal_style} bind:show_modal={enter_conc}>
  <button on:click="{()=>{enter_conc=false;}}" class="modal-close is-large" aria-label="close"></button>
  <InputField label="Optimal Concentration:" bind:value={opt_conc} type="number" />
  <button title="Update" on:click={update_conc} class="button qog-button">Update</button>
</Modal>

{#if loading}
  <Modal style={modal_style} bind:show_modal={loading}>
    <Loading></Loading>
  </Modal>
{/if}

{#if delete_selected}
<Modal bind:show_modal={delete_selected} {error_message}>
    <div class="notification is-warning">
      <div class="title is-4">Delete Titration for antibody/antibodies: {format_delete_message("Antibody")} with lot number(s): {format_delete_message("Lot")} run on {format_delete_message("Date")} ?</div>
      <div class="warning-actions">
          <button on:click="{() => {delete_selected=false;}}" class="button qog-button">Don't Delete</button>
          <button on:click={delete_abtitration} class="button qog-button">Delete</button>
      </div>
    </div>
</Modal>
{/if}

{#if edit_titer_params}
  <AbTitrModal
    abtiter_id={select_titer_id}
    well_table={well_table}
    well_ids={selected_well_ids}
    select_str2var={select_str2var}
    bind:edit_titer_params={edit_titer_params}
    celltypes={celltypes}
    pos_celltype={pos_celltype}
    neg_celltype={neg_celltype}
    antibodies={antibodies}
    antibody={antibody}
    fluorophores={fluorophores}
    fluorophore={fluorophore}
    paramlabels={paramlabels}
    paramlabel={paramlabel}
    vendors={vendors}
    vendor={vendor}
    lots={lots}
    lotnum={lotnum}
    max_conc={max_conc}
    units={units}
    max_conc_unit={max_conc_unit}
    dilution={dilution}
    well_order={well_order}
  />
{/if}

<style>
  .right{
    float: right;
  }

  .plot{
    width: 325px;
    height: 325px;
    border: 1px solid grey;
  }

  .table-container{
    max-height: 500px;
    overflow: scroll;
  }

  .column {
    margin: 1em 0em 1em 0em; 
  }

  .download-button{
    text-align: right;
    left: 1350px;
    position: absolute;
    cursor: pointer;
  }

  .gating-plot{
    width: 400px;
    height: 400px;
  }

  .gating-window{
    background-color: white;
    width: 1100px;
    max-height: 900px;
    overflow: scroll;
  }

  .qog_banner_toolbar {
    display: flex;
    height: 46px;
  }

  .page-title {
    size: 1.5rem;
    margin-left:10em;
    margin-right: 10em;
    font-weight: 600;
  }
</style>
