<script>
    export let error_message = null;
    export let show_modal;

    let style = $$props.style;
</script>

<div class:is-active={show_modal} class="modal">
    <div class="modal-background"></div>
    <div class="modal-content" style={style}>
        {#if error_message}
            <div class="notification is-danger">
                <button on:click={(e)=>{error_message=null;}} class="delete"></button>
                {error_message}
            </div>
        {/if}
        <slot />
    </div>
    <button on:click="{()=>{show_modal=false;}}" class="modal-close is-large" aria-label="close"></button>
</div>

<style>
</style>
